import React, { useState } from "react";
import { Button } from "@mui/material";
import PropTypes from 'prop-types'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
const CustomFileButton = (props) => {

    return (
        <Button variant="variant" onClick={props.onClick}
            style={{ backgroundColor: 'rgba(212, 179, 196, 0.6)', height: '50px' }}>
            <FileUploadOutlinedIcon style={{ color: '#650E3D', fontSize: '28px' }} />
        </Button>
    )
}
export default CustomFileButton

CustomFileButton.propTypes = {
    onClick: PropTypes.any.isRequired,
}
