import { InsertDriveFile, Folder, MoreVert } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { Grid } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CustomButton from 'src/components/Custom/CustomButton'
import CustomToast from 'src/components/Custom/CustomToast'
import { getDateFormat, getFileSize } from 'src/components/helpers/Functions'
import handelErrors from 'src/components/helpers/handelErrors'
import Swal from 'sweetalert2'
import RenameFile from './RenameFile'
import RenameFolder from './RenameFolder'
import EditSVG from 'src/images/edit_svg.svg'
import DownloadSvg from 'src/images/download_svg.svg';
import DeleteSVG from 'src/images/carbon_delete.svg'
import { t } from 'i18next'
import i18n from 'src/i18n'

const FilePopover = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <div>
            <MoreVert style={{ color: '#650E3D', fontSize: "1.2em", cursor: "pointer" }}
                onClick={handleClick}
            />
            <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="p-3"
                    style={{
                        borderRadius: "15px"
                    }}
                >
                    {props.creationSource === "user" &&
                        <>
                            {/* <CustomButton
                                className="popover-menu-options-button"
                                variant={"contained"}
                                title={"Rename"}
                                onClick={() => {
                                    props.rename(props.nature, props.id, props.name)
                                }}
                            /> */}
                            <div className='w-100 d-flex' style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    props.rename(props.nature, props.id, props.name)
                                }}>
                                <div className='w-30 d-flex justify-content-center'>
                                    <img src={EditSVG} width={17} />
                                </div>
                                <div className='w-70 d-flex justify-content-center'
                                    style={{
                                        marginLeft: '10px'
                                        , color: '#650E3D'
                                        , fontWeight: '700'
                                        , fontSize: '14px'
                                    }}
                                >
                                    {t('Rename')}
                                </div>
                            </div>
                            <br />
                        </>
                    }
                    {props.creationSource === "user" &&
                        <>
                            {/* <CustomButton
                                className="popover-menu-options-button"
                                variant={"contained"}
                                title={"Delete"}
                                onClick={() => {
                                    props.delete(props.nature, props.id, props.name)
                                }}

                            /> */}
                            <div className='w-100 d-flex ' style={{ cursor: 'pointer' }} onClick={() => {
                                props.download(props.nature, props.id, props.name)
                            }}>
                                <div className='w-30 d-flex justify-content-center'>
                                    <img src={DownloadSvg} width={17} />
                                </div>
                                <div className='w-70 d-flex justify-content-center'
                                    style={{
                                        marginLeft: '10px'
                                        , color: '#650E3D'
                                        , fontWeight: '700'
                                        , fontSize: '14px'
                                    }}
                                >
                                    {t('Download')}
                                </div>
                            </div>
                            <br />
                        </>
                    }

                    {/* <CustomButton
                        className="popover-menu-options-button"
                        variant={"contained"}
                        title={"Download"}
                        onClick={() => {
                            props.download(props.nature, props.id, props.name)
                        }}
                    /> */}
                    {
                        props.creationSource === "user" &&
                        <>
                            <div className='w-100 d-flex' style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    props.delete(props.nature, props.id, props.name)
                                }}>
                                <div className='w-30 d-flex justify-content-center'>
                                    <img src={DeleteSVG} width={18} />
                                </div>
                                <div className='w-70 d-flex justify-content-center'
                                    style={{
                                        marginLeft: '10px'
                                        , color: '#650E3D'
                                        , fontWeight: '700'
                                        , fontSize: '14px'
                                    }}
                                >
                                    {t('Delete')}
                                </div>
                            </div>
                        </>
                    }
                </div>

            </Popover>
        </div>
    )
}

const FolderView = (props) => {
    const [renameFolderModalOpen, setRenameFolderModalOpen] = useState(false)
    const [renameFileModalOpen, setRenameFileModalOpen] = useState(false)
    const [selectedData, setSelectedData] = useState({
        id: null,
        name: "",
        nature: "",
    })
    const resetSelectedData = () => {
        setSelectedData({
            id: null,
            name: "",
            nature: "",
        })
    }

    const deleteFolder = (id, name) => {
        Swal.fire({
            title: `${t('folder_delete_mssg')} ${name} ?`,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/files/deleteFolder`,
                    data: {
                        id: id
                    },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        props.refresh()
                    })
                    .catch(error => {
                        handelErrors(error);
                    });
            }
        });
    }

    const deleteFile = (id, name) => {
        Swal.fire({
            title: `${t('file_delete_mssg')} ${name} ?`,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/files/deleteFile`,
                    data: {
                        id: id
                    },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        props.refresh()
                    })
                    .catch(error => {
                        handelErrors(error);
                    });
            }
        });
    }

    return (
        <div className="mt-3">
            <Grid container spacing={"10"}>
                {props.data.map((v, i) => {
                    if (v.nature === "folder") {
                        return <Grid item xs={3} key={i}>
                            <div className="p-3 shadow-on-hover" style={{ backgroundColor: "#FFFFFF", borderRadius: "15px" }}
                                onDoubleClick={() => {
                                    props.updateParentFolderId(v.id)
                                }}>
                                <div className="w-100" style={{ position: "relative" }}>
                                    <Folder
                                        style={{ color: '#650E3D', fontSize: "2.5em" }}
                                    />
                                    <div style={
                                        i18n.language =="ar" ? 
                                        { position: "absolute", left: "1px", top: "1px" }
                                        :
                                        { position: "absolute", right: "1px", top: "1px" }
                                    }>
                                        <FilePopover
                                            nature={v.nature}
                                            id={v.id}
                                            name={v.name}
                                            creationSource={v.creation_source}
                                            rename={(nature, id, name) => {
                                                setRenameFolderModalOpen(true)
                                                setSelectedData({
                                                    id: id,
                                                    name: name,
                                                    nature: nature,
                                                })
                                            }}
                                            delete={(nature, id, name) => {
                                                deleteFolder(id, name);
                                            }}
                                            download={(nature, id, name) => {
                                                // props.download(nature, id, name)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="w-100 fw-bolder mt-3" style={{ color: "#575757", fontSize: "0.7em" }}>
                                    <span>{getFileSize(v.total_size)}</span><span> - </span><span>{v.total_files_count} {t('Files')}</span>
                                </div>
                                <div className="w-100 fw-bold">
                                    <span style={{ color: "#650E3D" }}>{v.name.substring(0, 20)}{v.name.length > 20 ? "..." : ""}</span>
                                    {props.viewPath ?
                                        <div style={{ overflowX: "auto" }}>
                                            <span style={{
                                                color: v.path.length === 0 ? "#650E3D" : "#575757",
                                                cursor: "pointer",
                                                fontSize: "0.5em"
                                            }}
                                                onClick={() => {
                                                    props.updateParentFolderId("root")
                                                }}
                                            >{`Root`}{v.path.length === 0 ? "" : "/"}</span>
                                            {v.path.map((v2, i) => {
                                                return <span
                                                    style={{
                                                        color: v.path.length === i + 1 ? "#650E3D" : "#575757",
                                                        cursor: "pointer",
                                                        fontSize: "0.5em"
                                                    }}
                                                    onClick={() => {
                                                        props.updateParentFolderId(v2.id)
                                                    }}
                                                >{`${v2.name}`}{v.path.length === i + 1 ? "" : "/"}</span>
                                            })
                                            }
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </Grid>
                    }
                    else {
                        return <Grid item xs={3}>
                            <div className="p-3 shadow-on-hover" style={{ backgroundColor: "#FFFFFF", borderRadius: "15px" }}>
                                <div className="w-100" style={{ position: "relative" }}>
                                    <InsertDriveFile style={{ color: '#650E3D', fontSize: "2.5em" }} />
                                    <div style={{ position: "absolute", right: "1px", top: "1px" }}>
                                        <FilePopover
                                            nature={v.nature}
                                            id={v.id}
                                            name={v.name}
                                            creationSource={v.creation_source}
                                            rename={(nature, id, name) => {
                                                setRenameFileModalOpen(true)
                                                setSelectedData({
                                                    id: id,
                                                    name: name,
                                                    nature: nature,
                                                })
                                            }}
                                            delete={(nature, id, name) => {
                                                deleteFile(id, name)
                                            }}
                                            download={(nature, id, name) => {
                                                window.open(`${process.env.REACT_APP_API_BASE_URL_FILE}/CompanyFiles/${v.path_name}`, '_blank').focus();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="w-100 fw-bolder mt-3" style={{ color: "#575757", fontSize: "0.7em" }}>
                                    <span>{getFileSize(v.total_size)}</span><span> - </span><span>{getDateFormat(v.created_at)}</span>
                                </div>
                                <div className="w-100 fw-bold">
                                    <span style={{ color: "#650E3D" }}>{v.name.substring(0, 20)}{v.name.length > 20 ? "..." : ""}</span>
                                    {props.viewPath ?
                                        <div style={{ overflowX: "auto" }}>
                                            <span style={{
                                                color: v.path.length === 0 ? "#650E3D" : "#575757",
                                                cursor: "pointer",
                                                fontSize: "0.5em"
                                            }}
                                                onClick={() => {
                                                    props.updateParentFolderId("root")
                                                }}
                                            >{`Root`}{v.path.length === 0 ? "" : "/"}</span>
                                            {v.path.map((v2, i) => {
                                                return <span
                                                    style={{
                                                        color: v.path.length === i + 1 ? "#650E3D" : "#575757",
                                                        cursor: "pointer",
                                                        fontSize: "0.5em"
                                                    }}
                                                    onClick={() => {
                                                        props.updateParentFolderId(v2.id)
                                                    }}
                                                >{`${v2.name}`}{v.path.length === i + 1 ? "" : "/"}</span>
                                            })
                                            }
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </Grid>
                    }
                })}
            </Grid>
            <RenameFolder
                open={renameFolderModalOpen}
                selectedData={selectedData}
                onClose={() => {
                    setRenameFolderModalOpen(false)
                    resetSelectedData();
                    props.refresh()
                }}
            />
            <RenameFile
                open={renameFileModalOpen}
                selectedData={selectedData}
                onClose={() => {
                    setRenameFileModalOpen(false)
                    resetSelectedData();
                    props.refresh()
                }}
            />
        </div >
    )
}
export default FolderView