import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import CustomDialog from '../../components/Custom/CustomDialog';
import styles from '../../styles/login.module.css';
import { CustomInput, SingleDate } from '../../components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SingleCustomAutocomplete from '../../components/Custom/SingleCustomAutocomplete';
import CustomAutocompleteServerSide from '../../components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { statuses } from 'src/components/helpers/FixedData';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';
import { getDescription } from 'src/components/helpers/Functions';


const UpdateStatus = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [courtsList, setCourtsList] = useState([]);
    const [statuses, setStatuses] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        id: -1,
        status: '',
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            status: '',
        });
    }


    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    status: response.data.status,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open && statuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/HearingStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/updateStatus`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('updated_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };

    const handleChangeFile = (e) => {
        e.preventDefault()
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                setFormData({
                    ...formData,
                    attachement: file
                });
            }
        } else {
            setFormData({
                ...formData,
                attachement: null
            });
        }
    }
    return (
        <Dialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-3' style={{ color: '#650E3D' }}>{t('Update')} {t('Status')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="status"
                            label={t('Status')}
                            list={statuses}
                            value={formData.status}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"}>
                        <CustomButton variant="contained" title={t('Update')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default UpdateStatus