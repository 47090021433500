import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import handelErrors from 'src/components/helpers/handelErrors'
import CustomHeader from 'src/components/Header/CustomHeader';
import { Grid, IconButton, Tooltip } from '@mui/material';
import ClientSVG from 'src/images/contact_svg.svg'
import EditSVG from 'src/images/edit_svg.svg'
import CustomAddButton from 'src/components/Custom/CustomAddButton';

const UserSettings = (props) => {
    const { t, i18n } = useTranslation();
    let { id, caseID } = useParams();

    return (
        <div style={{ backgroundColor: '#F4F4F4', minHeight: '100vh' }}>
            <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                <div style={{ width: '100%' }}><CustomHeader /></div>
                <div style={{
                    width: '100%', minHeight: '600px'
                    , marginTop: '40px', backgroundColor: '#ffffff'
                    , borderRadius: '23px'
                    , display: 'flex'
                    , justifyContent: 'center'
                    , alignItems: 'center'
                    , position: 'relative'
                }}>
                    <div style={{
                        width: '94%'
                        , height: '94%'
                        , position: 'absolute'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div style={{
                                    display: 'flex'
                                    , width: '100%'
                                    , height: '80px'
                                }}>
                                    <div style={{ width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={ClientSVG} width={60} height={60} />
                                    </div>
                                    <div style={{
                                        width: '80%'
                                        , display: 'flex'
                                        , flexDirection: 'column'
                                        , justifyContent: 'center'
                                    }}>
                                        <span style={{ color: '#650E3D', fontSize: '13px' }}>Associate</span>
                                        <span style={{ fontWeight: '400', fontSize: '32px', lineHeight: '30px' }}>Mr. John Doe</span>
                                        <span style={{ fontWeight: '400', fontSize: '13px', lineHeight: '16px' }}>
                                            Created account on January 3, 2023
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                        <Tooltip title={t('Edit')}>
                                            <IconButton>
                                                <img src={EditSVG} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{
                                    width: '100%'
                                    , height: '80px'
                                    , backgroundColor: 'rgba(101, 14, 61, 0.04)'
                                    , borderRadius: '16px'
                                    , display: 'flex'
                                    , alignItems: 'center'
                                    , paddingLeft: '25px'
                                }}>
                                    <Grid container>
                                        <Grid item xs={12} md={7}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{
                                                    textTransform: 'uppercase'
                                                    , color: '#650E3D'
                                                    , lineHeight: '20px'
                                                    , fontWeight: '700'
                                                    , fontSize: '14px'
                                                }}>password</span>
                                                <span >****************</span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div style={{ display: 'flex' }}>
                                                <CustomAddButton title={'Reset Password'} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{
                                    minHeight: '300px'
                                    , width: '100%'
                                    , backgroundColor: 'rgba(101, 14, 61, 0.04)'
                                    , borderRadius: '16px'
                                }}>
                                    <div style={{ padding: '25px', marginLeft: '5px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span style={{
                                                        textTransform: 'uppercase'
                                                        , color: '#650E3D'
                                                        , lineHeight: '20px'
                                                        , fontWeight: '700'
                                                        , fontSize: '14px'
                                                    }}>location</span>
                                                    <span>Lebanon</span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span style={{
                                                        textTransform: 'uppercase'
                                                        , color: '#650E3D'
                                                        , lineHeight: '20px'
                                                        , fontWeight: '700'
                                                        , fontSize: '14px'
                                                    }}>phone number</span>
                                                    <span>+961112233</span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span style={{
                                                        textTransform: 'uppercase'
                                                        , color: '#650E3D'
                                                        , lineHeight: '20px'
                                                        , fontWeight: '700'
                                                        , fontSize: '14px'
                                                    }}>nationality</span>
                                                    <span>Lebanon</span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span style={{
                                                        textTransform: 'uppercase'
                                                        , color: '#650E3D'
                                                        , lineHeight: '20px'
                                                        , fontWeight: '700'
                                                        , fontSize: '14px'
                                                    }}>email address</span>
                                                    <span>johndoe@gmail.com</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserSettings