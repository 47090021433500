
export const getRowDataByColumn = (data,colName,colNameValue) => {
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element[colName] == colNameValue){
            return element;
        }
    }
    return;
}

export const convertBytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  export const getFileSize = (kb) => {
      if (kb < 1000){
          return `${kb.toFixed(2)} Kb`;
      }
      if (kb < 10000000){
        return `${(kb/1024).toFixed(2)} Mb`;
      }
      return `${(kb/(1024 * 1024)).toFixed(2)} Gb`;
  };

  export const getDateFormat = (date) => {
    return date
};

export const getYears = () => {
    return [
        {id:"2015",label:"2015"},
        {id:"2016",label:"2016"},
        {id:"2017",label:"2017"},
        {id:"2018",label:"2018"},
        {id:"2019",label:"2019"},
        {id:"2020",label:"2020"},
        {id:"2021",label:"2021"},
        {id:"2022",label:"2022"},
        {id:"2023",label:"2023"},
        {id:"2024",label:"2024"},
        {id:"2025",label:"2025"},
        {id:"2026",label:"2026"},
        {id:"2027",label:"2027"},
        
    ];
}

export const getDescription=(description,language)=>{
    return language == "en-US" || language == "en" ?
        `${description}`
        // 'name'
        :
        `${description}_${language}`
        // 'name_ar'

}
export const getValue=(e,valueName,language)=>{
    return language == "en-US" || language == "en" ?
        e[valueName]?.toString()
        // 'name'
        :
        e[valueName+`_${language}`]?.toString()
        // e.valueName_+`${language}`
        // 'name_ar'

}