import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
// import handelErrors from 'src/helpers/handelErrors';
import { TextField, FormControl, Autocomplete, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import handelErrors from '../helpers/handelErrors';
import { useTranslation } from 'react-i18next';

const SingleCustomAutocompleteServerSide = props => {
    const { t, i18n } = useTranslation();

    const filedName = props.filedName;
    const fieldLabel = props.label;
    const fieldValue = props.value;
    const listKey = props.listKey;
    const listDescription = props.description;
    const customOnChange = props.customOnChange;
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const previousController = useRef();
    const previousController2 = useRef();
    const params = props.params ? props.params : null;

    const getData = async (searchTerm, source) => {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_API_BASE_URL}${props.apiEndPoint}`, {
                params: {
                    searchQuery: JSON.stringify(
                        {
                            q: searchTerm,
                            selectedValues: fieldValue ? [fieldValue] : [],
                        }
                    ),
                    extraParams: params
                },
                cancelToken: source.token,
            })
                .then(function (response) {
                    setList(response.data);
                    resolve();

                })
                .catch(function (error) {
                    handelErrors(error);
                    // reject(error)
                })
        })
    };

    const onInputChange = (event) => {
        const value = event.target.value
        if (value) {
            let source = previousController.current;
            source?.cancel("cancelled_cleanup");
            source = previousController.current = axios.CancelToken.source();
            if (previousController2.current) {
                clearTimeout(previousController2.current)
            }

            previousController2.current = setTimeout(() => {
                if (!isLoading) {
                    getData(value, source);
                }

            }, 500)
        }
    };
    useEffect(() => {
        const source = axios.CancelToken.source();
        if (fieldValue) {
            let fetchData = true;
            list.forEach(element2 => {
                if (element2[listKey] == fieldValue) {
                    fetchData = false;
                }
            });
            if (fetchData) {
                setIsLoading(true);
                getData("", source).catch(() => { setIsLoading(false); });
                setIsLoading(false);
            }
        }
        else {
            setList([])
        }
        return function () {
            source.cancel("cancelled_cleanup");
        };
    }, [fieldValue])

    const optionForLabel = (label) => {
        if (typeof label === 'object' &&
            !Array.isArray(label) &&
            label !== null) {
            return `${label[listDescription]}`
        }
        let toReturn = list.find(
            (source) => { return source[listKey] == label }
        )
        if (toReturn) {
            return `${toReturn[listDescription]}`
        }
        return `${label}`
    }

    const getOptionSelected = (option, value) => {
        if (typeof option === 'object' &&
            !Array.isArray(option) &&
            option !== null) {
            return option[listKey] == value
        }
        return false
    }

    const update = (event, value) => {
        if (!customOnChange) {
            return;
        }
        if (value === null) {
            customOnChange(null, filedName, event);
            return
        } else {
            customOnChange(value[listKey], filedName, value[listDescription], event, value);
        }
    }
    const rtl = i18n.language.includes("en")
    ? {}
    : {
        // transformOrigin: "right !important",
        left: "inherit !important",
        right: "2rem !important",
        overflow: "unset",
    };

    const rtlIndicator = i18n.language =="ar" ?
    {
        left: " 1rem !important",
        right: "inherit !important",
        overflow: "unset",
    }
    :
    {};

    const rtlRoot = i18n.language =="ar" ? 
    {
        paddingRight:'1rem !important',
    }
    : {};

    const theme = createTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '30px',
                            border: 'none',
                            backgroundColor:'#F4F4F4',
                            fontFamily: 'Poppins, sans-serif',
                            ...rtlRoot,
                            '& input': {
                                color: '#66023C',
                                // direction: 'rtl',
                                // textAlign: 'right',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border:'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                // borderColor: '#66023C',
                                border:'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#66023C',
                                border:'none'
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                                color: '#66023C',
                            },
                            '& .MuiAutocomplete-endAdornment':{
                                ...rtlIndicator,
                            }
                        },
                    },
                    popper: {
                        '& .MuiAutocomplete-option': {
                            color: '#66023C',
                            // direction: 'rtl',
                            // textAlign: 'right',
                        },
                    },
                },
            },
            MuiTextField: { // Add this section to override TextField styles
                styleOverrides: {
                    root: {
                        '& label': {
                            color:'#66023C',
                            fontWeight:'bold',
                            ...rtl,
                        },
                        '& label.Mui-focused': {
                            color: '#66023C',
                            marginTop:'5px',
                            // display:'none'
                        },
                    },
                },
            },
        },
    });
    return (
        <FormControl style={{ width: "100%",marginTop:'10px',marginBottom:'10px' }}>
            {isLoading ? <CircularProgress /> :
                <ThemeProvider theme={theme}>
                    <Autocomplete
                        disabled={props.disabled ? props.disabled : false}
                        name={filedName}
                        options={list} // Options List
                        value={fieldValue}
                        onChange={update}
                        getOptionLabel={optionForLabel}
                        getOptionSelected={getOptionSelected}
                        style={{ width: "100%" }}
                        groupBy={props.groupBy ? (option) => option[props.groupBy] : null}
                        renderInput={(params) => <TextField {...params} label={fieldLabel} onChange={onInputChange} />}
                    />
                </ThemeProvider>
            }
        </FormControl>
    );
}

SingleCustomAutocompleteServerSide.propTypes = {
    filedName: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired,
    value: PropTypes.any,
    listKey: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired,
    customOnChange: PropTypes.any,
    disabled: PropTypes.any,
    groupBy: PropTypes.any,
    isLoading: PropTypes.bool,
    apiEndPoint: PropTypes.any.isRequired,

}

export default SingleCustomAutocompleteServerSide;