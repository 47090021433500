import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomTabs from '../../components/Custom/CustomTabs';
import { CustomInput, CustomSearch } from 'src/components/Custom/CustomInputForms';
import CustomButton from 'src/components/Custom/CustomButton';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import AddUpdateChat from './AddUpdateChat';
import { collection, getDocs, query, where, doc, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from 'src/FireBase/App';
import CustomChat from './CustomChat';
import CustomChatView from './CustomChatView';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import AddUpdateSingleChat from './AddUpdateSingleChat';

const CaseChats = () => {
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [tabSelected, setTabSelected] = useState(0);
    const [chatID, setChatID] = useState('');
    const [chatsList, setChatsList] = useState([]);
    const [chatsLoading, setChatsLoading] = useState(false);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const previousController = useRef();
    const previousController2 = useRef();
    const [chatDetails, setChatDetails] = useState({
        id: null,
        name: '',
        category:'',
    });
    const [open, setOpen] = useState(false);
    const [openSingle, setOpenSingle] = useState(false);
    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
        setFilterForm({
            ...filterForm,
            type: 'client'
        });
    }

    const [filterForm, setFilterForm] = useState({
        name: '',
        type: 'client'
    });

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        setFilterForm({
            ...filterForm,
            [id]: value,
        });
        if (value) {
            let source = previousController.current;
            source?.cancel("cancelled_cleanup");
            source = previousController.current = axios.CancelToken.source();
            if (previousController2.current) {
                clearTimeout(previousController2.current)
            }
            previousController2.current = setTimeout(() => {
                // if (!chatsLoading) {
                getData(value, source);
                // }

            }, 500)
        }
    }
    const addSingleChat = () => {
        setChatID(null);
        setOpenSingle(true);
    }
    const addGroupChat = () => {
        setChatID(null);
        setOpen(true);
    }
    const closeGoogleChat = (refresh) => {
        if (refresh == true) {
            const firebase_id = localStorage.getItem('google_uid');
            setFilterForm({
                ...filterForm,
                name: '',
            });
            setIsRefresh(!isRefresh);
        }
        setOpen(false);
    }
    const closeSingleChat = (refresh) => {
        if (refresh == true) {
            const firebase_id = localStorage.getItem('google_uid');
            setFilterForm({
                ...filterForm,
                name: '',
            });
            setIsRefresh(!isRefresh);
        }
        setOpenSingle(false);
    }
    useEffect(() => {
        if (chatDetails.id != null || chatDetails.id > 0) {
            const updatedItems = chatsList.filter((item) => item.id === chatDetails.id);
            if (updatedItems.length > 0) {
                setChatDetails({
                    id: updatedItems[0].id,
                    name: updatedItems[0].name,
                    category: updatedItems[0].category,
                });
            }
        }
    }, [chatsList]);

    const getData = async (searchTerm, source) => {
        return new Promise((resolve, reject) => {
            setChatsLoading(true);
            let chats = [];
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/chats/get`, {
                params: {
                    company_id: id,
                    case_id: caseID > 0 ? caseID : null,
                    type: filterForm.type,
                    searchTerm: searchTerm,
                },
                cancelToken: source?.token,
            })
                .then(res => {
                    const data = res.data;
                    if (data.length > 0) {
                        data.forEach(element => {
                            chats.push(element.firebase_id);
                        });
                    }
                    if (chats.length > 0) {
                        const chatsRef = collection(db, "chats");
                        const chatsQuery = query(chatsRef, where('id', 'in', chats), orderBy('last_message_date', 'desc'));
                        const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
                            console.log("inside chat rendering");
                            const chatsData = [];
                            snapshot.forEach((doc) => {
                                const chat = doc.data();
                                chatsData.push(chat);
                            });
                            setChatsList(chatsData);
                        });
                        setChatsLoading(false);
                        resolve();
                        return () => {
                            unsubscribe();
                        }
                    } else {
                        setChatsList([]);
                        setChatsLoading(false);
                        resolve();
                        return;
                    }
                }).catch(err => {
                    handelErrors(err);
                })
        });
    }

    useEffect(() => {
        if (filterForm.name == '') {
            getData()
        }
    }, [isRefresh, tabSelected, filterForm.name]);

    const getChatMessages = (e) => {
        setChatDetails({
            name: e.name,
            id: e.id,
            category: e.category
        });
    }
    useEffect(() => {
        setMessagesLoading(true);
        const messageRef = collection(db, "chat_messages");
        const chatMesagesQuery = query(messageRef, where('chat_id', '==', chatDetails.id), orderBy("date"));
        const unsubscribe = onSnapshot(chatMesagesQuery, (snapshot) => {
            const messages = [];
            snapshot.forEach((doc) => {
                const message = doc.data();
                messages.push(message);
            });
            setChatMessages(messages);
        });
        setMessagesLoading(false);
        return () => {
            // Unsubscribe when component is unmounted or chatId changes
            unsubscribe();
        };
    }, [chatDetails.id]);

    const updateForm = (chatID) => {
        setChatID(chatID);
        setOpen(true);
    }

    const updateSingleForm = (chatID) => {
        setChatID(chatID);
        setOpenSingle(true);
    }

    return (
        <Grid container>
            <Grid item xs={4}>
                <CustomSearch
                    id={'name'}
                    value={filterForm.name}
                    onChange={handleInputChange}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                />
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <CustomAddButton variant="outlined" title={t('New') + ' ' + t('Chat')} onClick={addSingleChat} />
                    </div>
                    <div style={{ width: '50%' }}>
                        <CustomAddButton variant="outlined" title={t('New') + ' ' + t('Group')} onClick={addGroupChat} />
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    {
                        chatsLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress style={{ color: '#650E3D' }} />
                            </div>
                            :
                            chatsList.map((e) => (
                                <CustomChat data={e} chat={chatDetails} onClick={() => getChatMessages(e)} />
                            ))
                    }
                </div>
            </Grid>
            <Grid item xs={8}>
                {
                    messagesLoading ? <CircularProgress style={{ color: '#650E3D' }} />
                        :
                        chatDetails.id == null
                            ?
                            <div className='d-flex justify-content-center align-items-center'
                                style={{ height: '100%', width: '100%' }}>Choose a chat</div>
                            :
                            <CustomChatView data={chatMessages}
                                chat={chatDetails}
                                isLoading={messagesLoading}
                                updateForm={chatDetails.category=='single' ? null : updateForm} />
                }
            </Grid>
            <AddUpdateChat open={open}
                id={chatID}
                filterForm={filterForm}
                onClose={closeGoogleChat} />

            <AddUpdateSingleChat
                open={openSingle}
                id={chatID}
                filterForm={filterForm}
                onClose={closeSingleChat} />
        </Grid>
    )

}
export default CaseChats