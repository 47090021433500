import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import CustomTabs from 'src/components/Custom/CustomTabs';
import HomeLogo from 'src/images/home_svg.svg';
import CaseLogo from 'src/images/case_svg.svg';
import ChatsLogo from 'src/images/chat_svg.svg';
import FilesLogo from 'src/images/files_svg.svg';
import ClientsLogo from 'src/images/contact_svg.svg';
import TeamsLogo from 'src/images/team_filled.svg';
import TasksLogo from 'src/images/task_svg.svg';
import FinancialSVG from 'src/images/financial_svg.svg';
import ArchiveLogo from 'src/images/archive_svg.svg';
import CustomDates from 'src/components/Custom/CustomDates';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useParams, useNavigate, Route, Routes, useLocation, matchPath } from 'react-router-dom';
import * as moment from 'moment'
import Meetings from '../Meetings/Meetings';
import { useTranslation } from 'react-i18next';

const currentDate = new Date();

const Home = (props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [tabSelected, setTabSelected] = useState(0);
    const [isLoadingMeetings, setIsLoadingMeetings] = useState(false);
    const [openMeetings, setOpenMeetings] = useState(false);
    const [meetings, setMeetings] = useState([]);
    let { id } = useParams();
    const { pathname } = useLocation();
    const pathCase = matchPath({ path: "/:id/Landing/:tab" }, pathname);
    const presetPagesCodes = JSON.parse(localStorage.getItem("preset_pages_codes"));

    useEffect(()=>{
        if(pathCase == null){
            setTabSelected(0);
        }else if(pathCase.params.tab =='Clients'){
            setTabSelected(1);
        }else if(pathCase.params.tab =='Cases'){
            setTabSelected(2);
        }else if(pathCase.params.tab =='Tasks'){
            setTabSelected(3);
        }else if(pathCase.params.tab =='Files'){
            setTabSelected(4);
        }else if(pathCase.params.tab =='Chats'){
            setTabSelected(5);
        }else if(pathCase.params.tab =='Team'){
            setTabSelected(6);
        }else if(pathCase.params.tab =='Financials'){
            setTabSelected(7);
        }else if(pathCase.params.tab =='Archives'){
            setTabSelected(8);
        }
    },[pathname]);

    const tabsArray = [
        {
            key: 0
            ,label: t('home')
            ,image: HomeLogo
            ,path: `/${id}`
            ,display:presetPagesCodes.includes('home') ? true : false
        },
        {
            key: 1, label: t('Clients'), image: ClientsLogo
            ,path: `/${id}/Landing/Clients`,display:presetPagesCodes.includes('company_clients') ? true : false
        },
        {
            key: 2, label: t('Cases'), image: CaseLogo
            ,path: `/${id}/Landing/Cases`,display:presetPagesCodes.includes('cases') ? true : false
        },
        {
            key: 3, label: t('Tasks'), image: TasksLogo
            ,path: `/${id}/Landing/Tasks`,display:presetPagesCodes.includes('tasks') ? true : false
        },
        {
            key: 4, label: t('Files'), image: FilesLogo
            ,path: `/${id}/Landing/Files`,display:presetPagesCodes.includes('files') ? true : false
        },
        {
            key: 5, label: t('Chat'), image: ChatsLogo
            ,path: `/${id}/Landing/Chats`,display:presetPagesCodes.includes('chats') ? true : false
        },
        {
            key: 6, label: t('Team'), image: TeamsLogo
            ,path: `/${id}/Landing/Team`,display:presetPagesCodes.includes('company_employees') ? true : false
        },
        {
            key: 7, label: t('Financials'), image: FinancialSVG
            , path: `/${id}/Landing/Financials`,display:presetPagesCodes.includes('financials') ? true : false
        },
        {
            key: 8, label: t('Archives'), image: ArchiveLogo
            ,path: `/${id}/Landing/Archives`,display:presetPagesCodes.includes('archives') ? true : false
        },
    ];

    const [filterForm, setFilterForm] = useState({
        fromDate: null,
        toDate: null,
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
    });

    const onTabChange = (e, newValue) => {
        const targetElement = tabsArray.find(e => e.key === newValue)
        navigate(targetElement.path);
        setTabSelected(newValue);
    }

    const getMeetings = () => {
        setIsLoadingMeetings(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/meetings/getAllByMonthYear`, {
            params: {
                month: filterForm.month,
                year: filterForm.year,
                company_id: id,
            }
        })
            .then(res => {
                setIsLoadingMeetings(false);
                const data = res.data;
                const arr = [];
                if (data.length > 0) {
                    data.map((e) => {
                        arr.push(e.start);
                    })
                }
                setMeetings(arr);
            }).catch(err => {
                setIsLoadingMeetings(false);
                handelErrors(err);
            })
    }

    useEffect(() => {
        getMeetings();
    }, [filterForm.month, filterForm.year]);

    const handleRowClick = (rowData, rowMeta) => {
        navigate(`/${id}/Case/${rowData[0]}`)
    };
    const handleMeetings = () => {
        setOpenMeetings(true);
    }
    const handleCloseMeetings = () => {
        setOpenMeetings(false);
    }
    const onChangeDate = (selectedDates) => {
        let start = null;
        let end = null;
        let startDate = null;
        let endDate = null;

        // if(selectedDates.length > 2){
        //     toast.warning('Please choose 2 dates only !');return;
        // }else 
        // if(selectedDates.length == 1){
        // start = selectedDates[0];
        start = selectedDates;
        // end = selectedDates[0];
        end = selectedDates;
        startDate = new Date(start).setHours(0, 0, 0, 0);
        endDate = new Date(end).setHours(0, 0, 0, 0);
        // }else{
        //     toast.warning('Please choose 1 date only !');
        // console.log(selectedDates);
        //     return;
        // }
        // else if(selectedDates.length == 2){
        //     start = selectedDates[0];
        //     end = selectedDates[1];
        //     startDate = new Date(start).setHours(0, 0, 0, 0);
        //     endDate = new Date(end).setHours(0, 0, 0, 0);
        // }
        setFilterForm({
            ...filterForm,
            fromDate: startDate,
            toDate: endDate,
        });
        handleMeetings();
    }
    const handleYearChange = (year) => {
        setFilterForm({
            ...filterForm,
            year: year
        });
    }
    const handleMonthChange = (month) => {
        setFilterForm({
            ...filterForm,
            month: month
        });
    }

    return (
        <Grid container style={{ paddingTop: '40px' }} spacing={3}>
            <Grid item xs={12} md={9}  order={{ xs: 2, md: 1 }}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <CustomTabs
                            tabs={tabsArray} tabValue={tabSelected} onTabChange={onTabChange} />
                    </Grid>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={3}  order={{ xs: 1, md: 2 }}>
                <Grid item xs={12}>
                    <div className="d-flex" style={{ height: '100%', width: '100%', marginTop: '35px' }}>
                        <span style={{ color: '#650E3D', fontSize: '24px', width: '90%' }} >{t('my_calendar')}</span>
                    </div>
                    <div style={{ height: '100%', width: '100%', marginTop: '5px', borderRadius: '10px', backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
                        <CustomDates onChangeDate={onChangeDate}
                            handleYearChange={handleYearChange}
                            handleMonthChange={handleMonthChange}
                            data={meetings}
                        />
                    </div>
                </Grid>
            </Grid>
            <Meetings open={openMeetings} onClose={handleCloseMeetings} filterForm={filterForm} />
        </Grid>
    )
}
export default Home