import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomDateTimePicker, CustomInput, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CustomAutocompleteServerSide from 'src/components/Custom/CustomAutocompleteServerSide';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import CustomToast from 'src/components/Custom/CustomToast';


const AddUpdateMeeting = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [courtsList, setCourtsList] = useState([]);
    const [cases, setCases] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [currencies, setCurrencies] = useState([]);

    const [formData, setFormData] = useState({
        id: -1,
        name: '',
        company_id: id,
        description: '',
        case_id: caseID,
        type: null,
        address: '',
        link: '',
        currency: null,
        participants: [],
        fees: '',
        duration: '',
        start: props.selectedDate != null ? props.selectedDate : new Date(),
        end: props.selectedDate != null ? props.selectedDate : new Date(),
        creation_source_id: -1,
        meeting_link: '',
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            name: '',
            company_id: id,
            description: '',
            case_id: caseID,
            type: null,
            address: '',
            link: '',
            currency: null,
            participants: [],
            fees: '',
            duration: '',
            start: props.selectedDate != null ? props.selectedDate : new Date(),
            end: props.selectedDate != null ? props.selectedDate : new Date(),
            creation_source_id: -1,
            meeting_link: '',
        });
    }


    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/meetings/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    name: response.data.title,
                    company_id: id,
                    description: response.data.description,
                    case_id: response.data.case_id,
                    type: response.data.type,
                    address: response.data.address,
                    link: response.data.link,
                    currency: response.data.currency,
                    participants: response.data.participants ? response.data.participants.split(',') : [],
                    fees: response.data.fees,
                    duration: response.data.duration,
                    start: response.data.start ? new Date(response.data.start) : null,
                    end: response.data.end ? new Date(response.data.end) : null,
                    creation_source_id: response.data.creation_source_id,
                    meeting_link: response.data.meeting_link,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }

            if (cases.length == 0) {
                const getCases = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
                        params: {
                            company_id: id
                        },
                    })
                        .then(function (response) {
                            setCases(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getCases();
            }

            if (props.open && currencies.length == 0) {
                const getCurrencies = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/currencies/get`, {
                        params: {},
                    })
                        .then(function (response) {
                            setCurrencies(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getCurrencies();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;

        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }


    const saveForm = () => {
        setSaveIsLoading(true);
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            if (value && value !== "" && value !== null) {
                if (Array.isArray(value)) {
                    // value.forEach(element => {
                    //     formDataObj.append(`${key}[]`, element);
                    // });
                    let arr = [];
                    value.forEach(element => {
                        arr.push(element);
                        // formDataObj.append(`${key}[]`, element);
                    });
                    formDataObj.append(`${key}`, JSON.stringify(arr));
                }
                else {
                    if (key == "start" || key == "end") {
                        formDataObj.append(key, moment(value).format('YYYY-MM-DD H:m:s'));
                    } else {
                        formDataObj.append(key, value);
                    }
                }
            }
        }

        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/meetings/update`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('updated_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/meetings/add`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('insert_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Tooltip title={"Close"}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => props.onClose(false)}
                        aria-label="Close"
                        style={{ position: 'absolute', right: '30px' }}
                    >
                        <CloseIcon style={{ color: '#650E3D' }} />
                    </IconButton>
                </Tooltip>
                <div className='fs-3' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('Meeting')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleCustomAutocomplete
                            filedName="type"
                            label={t('type')}
                            list={[
                                {
                                    id: 'in_person', label: t('in_person')
                                },
                                {
                                    id: 'virtual', label: t('Virtual')
                                },
                            ]}
                            value={formData.type}
                            listKey="id"
                            description="label"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomDateTimePicker id="start"
                            label={t('Date&Time')}
                            value={formData.start}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'duration'}
                            value={formData.duration}
                            onChange={handleInputChange}
                            placeholder={t('Duration/Minutes')}
                            withBorder={false}
                            type={'number'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleCustomAutocomplete
                            filedName="case_id"
                            label={t('Case')}
                            list={cases}
                            value={formData.case_id}
                            listKey="id"
                            description="name"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    {
                        formData.type === "in_person" ?
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    id={'address'}
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    placeholder={t('address')}
                                    withBorder={false}
                                />
                            </Grid>
                            :
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    id={'link'}
                                    value={formData.link}
                                    onChange={handleInputChange}
                                    placeholder={t('Link')}
                                    withBorder={false}
                                />
                            </Grid>
                    }
                    <Grid item xs={12} md={12}>
                        <CustomInput
                            id={'description'}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={t('Description')}
                            withBorder={false}
                            multiline={true}
                            minRows={3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomAutocompleteServerSide
                            filedName="participants"
                            label="Participants"
                            values={formData.participants}
                            listKey="id"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={"/masterData/users/getAllInCompany"}
                            companyID={id}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'fees'}
                            value={formData.fees}
                            onChange={handleInputChange}
                            placeholder={t('Fees')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleCustomAutocomplete
                            filedName="currency"
                            label={t('Currency')}
                            list={currencies}
                            value={formData.currency}
                            listKey="code"
                            description="code"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <SingleDateTime
                            id="start"
                            label={t('from_date')}
                            value={formData.start}
                            onChange={handleInputChange}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} md={6}>
                        <SingleDateTime
                            id="end"
                            label={t('to_date')}
                            value={formData.end}
                            onChange={handleInputChange}
                        />
                    </Grid> */}
                    <Grid container style={{ marginBottom: '1%' }} justifyContent={"right"} alignItems={"center"}>
                        <Grid item xs={12} md={6} marginTop={"20px"}>
                            {
                                formData.creation_source_id > 0 ?
                                    null
                                    :
                                    <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddUpdateMeeting