import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomDateTimePicker, CustomInput, SingleDate, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import CustomToast from 'src/components/Custom/CustomToast';
import { getDescription } from 'src/components/helpers/Functions';


const AddUpdateHearing = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [lawyers, setLawyers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID > 0 ? caseID : null,
        name: '',
        plaintiff_or_defendant: null,
        assigned_to: null,
        status: null,
        date: new Date(),
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID > 0 ? caseID : null,
            name: '',
            plaintiff_or_defendant: null,
            assigned_to: null,
            status: null,
            date: new Date(),
        });
    }

    useEffect(() => {
        if (props.open && clients.length == 0) {
            const getClients = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/users/getClientsByCase`, {
                    params: {
                        case_id: caseID
                    },
                })
                    .then(function (response) {
                        setClients(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getClients();
        }
        if (props.open && statuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/HearingStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }
        if (lawyers.length == 0) {
            const getLawyers = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/users/getLawyersByCase`, {
                    params: {
                        case_id: caseID
                    },
                })
                    .then(function (response) {
                        setLawyers(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getLawyers();
        }
    }, [props.open]);

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: caseID,
                    name: response.data.name,
                    plaintiff_or_defendant: response.data.plaintiff_or_defendant,
                    assigned_to: response.data.assigned_to,
                    status: response.data.status,
                    date: response.data.date ? new Date(response.data.date) : null,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/update`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('updated_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/add`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('insert_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }

    };

    return (
        <Dialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('Hearing')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="plaintiff_or_defendant"
                            label={t('plaintiff_or_defendant')}
                            list={clients}
                            value={formData.plaintiff_or_defendant}
                            listKey="id"
                            description="concat_name"
                            customOnChange={customOnChange}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="assigned_to"
                            label={t('assign_to')}
                            list={lawyers}
                            value={formData.assigned_to}
                            listKey="id"
                            description="concat_name"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="status"
                            label={t('Status')}
                            list={statuses}
                            value={formData.status}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDateTimePicker
                            id="date"
                            label={t('Date')}
                            value={formData.date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"} marginBottom={'2%'}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddUpdateHearing