import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, List, Popover, Tooltip, Typography } from '@mui/material';
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import AddIcon from '@mui/icons-material/Add'
import EditSVG from 'src/images/edit_svg.svg'
import DeleteSVG from 'src/images/carbon_delete.svg'
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SearchSVG from 'src/images/search_svg.svg'
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import AddUpdateHearing from './AddUpdateHearing';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import ScheduleSVG from 'src/images/schedule_svg.svg';
import UpdateReschedule from './UpdateReschedule';
import InfoSVG from 'src/images/info_svg.svg';
import InfoForm from './InfoForm';
import CustomButton from 'src/components/Custom/CustomButton';
import { statuses } from 'src/components/helpers/FixedData';
import UpdateStatus from './UpdateStatus';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomToast from 'src/components/Custom/CustomToast';
import { getValue } from 'src/components/helpers/Functions';

const Hearings = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openReschedule, setOpenReschedule] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'hearings')
    const targetPageCase = presetPages.find(e => e.page_code === 'case_hearings')

    let openPop = Boolean(anchorEl);
    const id_pop = openPop ? 'simple-popover' : undefined;

    const handleOpenPop = (event, id) => {
        setSelectedID(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClosePop = () => {
        setAnchorEl(null);
    };

    const [filterForm, setFilterForm] = useState({
        fromDate: null,
        toDate: null,
    });
    const handleclear = () => {
        setFilterForm({
            fromDate: null,
            toDate: null,

        })
    };
    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/get`, {
            params: {
                company_id: id,
                case_id: caseID,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        if (statuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/HearingStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }
        getData();
    }, []);

    const openStatusForm = meta => {
        setSelectedID(meta.rowData[0]);
        setOpenStatus(true);
    }
    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'color_code',
            label: 'color',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'court',
            label: t('Court'),
        },
        {
            name: 'manual_court_id',
            label: t('court_id'),
        },
        {
            name: 'date',
            label: t('court_date'),
        },
        {
            name: 'judge_name',
            label: t('Judge')+' '+t('name'),
        },
        {
            name: "status",
            label: t('Status'),
            options: {
                filter: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    const targetElement = statuses?.find(e => e.id == value)
                    return (
                        <>
                            <div aria-describedby={id_pop}>

                                <CustomButton variant="outlined"
                                    style={{
                                        height: '40px'
                                        , minwidth: '150px'
                                        , border: `2px solid #650E3D`
                                        , color: `#650E3D`
                                        , borderRadius: '30px'
                                        , fontSize: '12px'
                                        , fontWeight: '700'
                                        , textAlign: 'left'
                                        , justifyContent: 'left'
                                    }}
                                    title={
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                                                <span style={{
                                                    fontWeight: '700'
                                                    , fontSize: '7px'
                                                    , textTransform: 'capitalize'
                                                    , lineHeight: '3px'
                                                    , marginTop: '7px'
                                                }}>
                                                    {t('Status')}
                                                </span>
                                                <span style={{ color: `${meta.rowData[1]}` }}>
                                                    {/* {value} */}
                                                    {
                                                        getValue(targetElement,'name',i18n.language)
                                                    }
                                                </span>
                                            </div>
                                        </>
                                    }
                                    onClick={(e) => (targetPageCase?.can_view === 1 && targetPageCase?.can_edit === 1) ? handleOpenPop(e, meta.rowData[0]) : null}
                                    endIcon={<KeyboardArrowDownIcon style={{ color: `#650E3D`, marginTop: '5px' }} />}
                                />
                            </div>
                            <Popover
                                id={id_pop}
                                open={openPop}
                                anchorEl={anchorEl}
                                onClose={handleClosePop}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <List xs={{ pt: 0 }}>
                                    {
                                        saveIsLoading ?
                                            <CircularProgress />
                                            :
                                            statuses.map((e, key) => (
                                                <Typography key={key} onClick={() => changeStatus(e.id)}
                                                    sx={{ p: 2, cursor: 'pointer', color: `${e.color_code}` }}>{getValue(e,'name',i18n.language)}</Typography>
                                            ))
                                    }
                                </List>
                            </Popover>
                        </>
                    )
                },
            },
        },
        {
            name: "actions",
            label: t('Actions'),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            <Tooltip title={t('Info')}>
                                <IconButton onClick={() => handleInfo(meta)}>
                                    <img src={InfoSVG} width={22} />
                                </IconButton>
                            </Tooltip>
                            {
                                targetPageCase?.can_view === 1 && targetPageCase?.can_edit === 1
                                    ?
                                    <>
                                        <Tooltip title={t('Edit')}>
                                            <IconButton onClick={() => handleEdit(meta)}>
                                                <img src={EditSVG} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('reschedule')}>
                                            <IconButton onClick={() => handleReschedule(meta)}>
                                                <img src={ScheduleSVG} width={22} />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                    : null
                            }
                            {
                                targetPageCase?.can_view === 1 && targetPageCase?.can_delete === 1 ?
                                    <Tooltip title={t('Delete')}>
                                        <IconButton onClick={() => deleteForm(meta)}>
                                            <img src={DeleteSVG} />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                            }
                        </>
                    )
                },
            },
        },
    ];

    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (
                <>
                    {
                        targetPageCase?.can_view === 1 && targetPageCase?.can_add === 1
                            ?
                            <span style={{ marginLeft: '10px' }}><CustomAddButton onClick={addForm} title={t('New') + ' ' + t('Hearing')} /></span>

                            : null
                    }
                </>
            );
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const addForm = () => {
        setSelectedID('');
        setOpen(true);
    }
    const handleEdit = meta => {
        setSelectedID(meta.rowData[0]);
        setOpen(true);
    }
    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }
    const handleReschedule = meta => {
        setSelectedID(meta.rowData[0]);
        setOpenReschedule(true);
    }
    const handleCloseReschedule = (refresh) => {
        setOpenReschedule(false);
        if (refresh) {
            getData();
        }
    }
    const handleInfo = meta => {
        setSelectedID(meta.rowData[0]);
        setOpenInfo(true);
    }
    const handleCloseInfo = (refresh) => {
        setOpenInfo(false);
        if (refresh) {
            getData();
        }
    }

    const deleteForm = meta => {
        const id = meta.rowData[0];
        Swal.fire({
            title: t('delete_mssg'),
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/delete`,
                    data: { id: id },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        getData();
                    })
                    .catch(error => {
                        handelErrors(error);
                    });
            }
        });
    };

    const handleCloseStatus = (refresh) => {
        setOpenStatus(false);
        if (refresh) {
            getData();
        }
    }
    const changeStatus = (status) => {
        setSaveIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/updateStatus`,
            data: { id: selectedID, status: status },
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('updated_mssg') });
                setSaveIsLoading(false);
                handleClosePop();
                getData();
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    };
    return (
        <div>
            <CustomDataTable
                data={data}
                columns={columns}
                options={options}
            />
            <AddUpdateHearing
                open={open}
                id={selectedID}
                onClose={handleClose} />
            <UpdateReschedule
                open={openReschedule}
                id={selectedID}
                onClose={handleCloseReschedule} />
            <InfoForm
                open={openInfo}
                id={selectedID}
                onClose={handleCloseInfo} />
            <UpdateStatus
                open={openStatus}
                id={selectedID}
                onClose={handleCloseStatus}
            />
        </div>
    )
}
export default Hearings