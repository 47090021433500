import React, { useEffect, useState } from 'react'
import { Grid, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { CustomInput, SingleDate } from '../../components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
// import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSVG from 'src/images/carbon_delete.svg'
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SingleCustomAutocompleteServerSide from 'src/components/Custom/SingleCustomAutocompleteServerSide';
import { getAuth } from "firebase/auth";
import { addDoc, collection, doc, getDocs, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import { db } from "src/FireBase/App";
import CustomToast from 'src/components/Custom/CustomToast';

const AddUpdateChat = (props) => {
    const auth = getAuth();
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [participantsList, setParticipantsList] = useState([]);
    let { id, caseID } = useParams();
    const [list, setList] = useState([]);
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        id: null,
        type: props.filterForm?.type,
        name: '',
        participant_id: null,
        participant_firebase_id: null,
        all_participant_firebase_id: [],
        participant_desc: '',
        all_participants_ids: [],
        case_id: caseID > 0 ? caseID : null,
        company_id: id,
        category:'group'
    });

    const resetForm = () => {
        setFormData({
            id: null,
            type: props.filterForm?.type,
            name: '',
            participant_id: null,
            participant_firebase_id: null,
            participant_desc: '',
            all_participants_ids: [],
            all_participant_firebase_id: [],
            case_id: caseID > 0 ? caseID : null,
            company_id: id,
            category:'group'
        });
        setList([]);
    }

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/chats/getById-${props.id}`,
        })
            .then(response => {
                console.log(response.data);
                const details = response.data.details;
                const google_uid = localStorage.getItem('google_uid');
                setFormData({
                    id: props.id,
                    type: response.data.type,
                    name: response.data.chat_name,
                    company_id: response.data.company_id,
                    participant_id: null,
                    case_id: caseID > 0 ? caseID : null,
                    participant_firebase_id: null,
                    category:'group',
                    participant_desc: '',
                    all_participants_ids: response.data.all_participants_ids ? response.data.all_participants_ids.split(',') : [],
                    all_participant_firebase_id: response.data.all_participant_firebase_id ? response.data.all_participant_firebase_id.split(',') : [],
                });
                let items = [];
                let ids = [response.data.all_participants_ids ? response.data.all_participants_ids.split(',') : []];
                let firebase_ids = [response.data.all_participant_firebase_id ? response.data.all_participant_firebase_id.split(',') : []];
                details.map((e) => {
                    if (e.user_uid !== google_uid) {
                        items.push({
                            id: e.user_uid,
                            description: e.user_name
                        });
                    }
                });
                // ids.push(formData.participant_id);
                // firebase_ids.push(formData.participant_firebase_id);
                setList(items);
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        // console.log(props.id > 0);
        if (props.id) {
            getFormData();
        } else {
            resetForm();
        }
        if (props.open && participantsList.length == 0) {
            const getParticipants = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/courts/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setParticipantsList(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getParticipants();
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name, description, object, value) => {
        setFormData({
            ...formData,
            [name]: newValue,
            participant_desc: description,
            participant_firebase_id: value.uid,
        });
    }

    const addParticipants = () => {
        let items = [...list];
        let ids = [...formData.all_participants_ids];
        let firebase_ids = [...formData.all_participant_firebase_id];
        items.push({
            id: formData.participant_id,
            description: formData.participant_desc
        });
        ids.push(formData.participant_id);
        firebase_ids.push(formData.participant_firebase_id);
        setList(items);
        setFormData({
            ...formData,
            all_participants_ids: ids,
            all_participant_firebase_id: firebase_ids,
            participant_id: null,
            participant_desc: ''
        });
    }

    const deleteParticipants = (id) => {
        let items = [...list];
        const updatedItems = items.filter((item) => item.id !== id);
        setList(updatedItems);
        const updatedIds = items.filter((e) => e.id !== id).map((e)=>e.id);
        const google_uid = localStorage.getItem('google_uid');

        if(!updatedIds.includes(google_uid)){
            updatedIds.push(google_uid);
        }
        setFormData({
            ...formData,
            all_participants_ids: updatedIds,
            all_participant_firebase_id: updatedIds,
            participant_id: null,
            participant_desc: ''
        });
    }

    const saveForm = async () => {
        // console.log(formData);return;
        setSaveIsLoading(true)
        if (formData.id) {
            if (formData.all_participant_firebase_id.length == 0) {
                setSaveIsLoading(true)
                CustomToast({type:'warning',message : 'Please Add participants'}); return;
            }
            const chatRef = doc(db, 'chats', formData.id);
            try {
                await updateDoc(chatRef, {
                    name: formData.name
                });
                const chatMembersRef = collection(db, 'chat_members');
                const chatQuery = query(chatMembersRef, where('chat_id', '==', formData.id));
                getDocs(chatQuery).then((querySnapshot) => {
                    const batch = writeBatch(db);
                    querySnapshot.forEach((doc) => {
                        batch.delete(doc.ref);
                    });

                    batch.commit().then( async () => {
                        console.log('Documents deleted successfully');
                        let all_participants = [...formData.all_participant_firebase_id];
                        // all_participants.push(localStorage.getItem('google_uid'))
                        const promises = all_participants.map((id) => {
                            return addDoc(collection(db, 'chat_members'), {
                                user_id: id
                                , chat_id: formData.id
                            });
                        });
                        try {
                            await Promise.all(promises);
                            axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_BASE_URL}/chats/update`,
                                data: formData,
                            })
                                .then((response) => {
                                    CustomToast({type:'success',message : 'Success'});
                                    setSaveIsLoading(false);
                                    props.onClose(true);
                                })
                                .catch((error) => {
                                    setSaveIsLoading(false)
                                    handelErrors(error)
                                });
                            props.onClose(true);
                        } catch (error) {
                            handelErrors(error);
                        }
                    }).catch((error) => {
                        console.error('Error deleting documents:', error);
                    });
                }).catch((error) => {
                    console.error('Error getting documents:', error);
                });
                setSaveIsLoading(false)
            } catch (error) {
                console.error('Error updating chat name:', error);
                setSaveIsLoading(false)
            }
            setSaveIsLoading(false)

        } else {
            if (formData.all_participant_firebase_id.length == 0) {
                setSaveIsLoading(false)
                CustomToast({type:'warning',message : 'Please Add participants'}); return;
            }
            try {
                const name = formData.name;
                const docRef = await addDoc(collection(db, 'chats'), {
                    name: name,
                    id: null,
                    last_message_date: new Date(),
                    type:formData.type,
                    case_id:0,
                    category: formData.category,
                    company_id: formData.company_id
                })
                await updateDoc(docRef, {
                    id: docRef.id, // Set the custom field with the generated ID
                });

                const docId = docRef.id;
                let all_participants = [...formData.all_participant_firebase_id];
                all_participants.push(localStorage.getItem('google_uid'))
                const promises = all_participants.map((id) => {
                    return addDoc(collection(db, 'chat_members'), {
                        user_id: id
                        , chat_id: docId
                    });
                });
                try {
                    await Promise.all(promises);
                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_BASE_URL}/chats/add`,
                        data: { ...formData, firebase_id: docId },
                    })
                        .then((response) => {
                            CustomToast({type:'success',message : 'Sucess'});
                            setSaveIsLoading(false);
                            props.onClose(true);
                        })
                        .catch((error) => {
                            setSaveIsLoading(false)
                            handelErrors(error)
                        });
                    props.onClose(true);
                } catch (error) {
                    handelErrors(error);
                }
            } catch (error) {
                handelErrors(error);
            }
        }
    }
    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id ? 'Edit' : t('New')} {t('Chat')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container >
                    <Grid item xs={12}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <SingleCustomAutocompleteServerSide
                            filedName="participant_id"
                            label={t('Participants')}
                            value={formData.participant_id}
                            listKey="uid"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={`/masterData/companies/getUsersBySearchQuery`}
                            params={{
                                company_id: id,
                                case_id: caseID > 0 ? caseID : null,
                                type:props.filterForm?.type
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ marginLeft: '-7px' }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
                            <Tooltip title={"Add"}>
                                <IconButton onClick={addParticipants}>
                                    <AddIcon style={{ color: '#650E3D' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <List sx={{
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300,
                            '& ul': { padding: 0 },
                        }}>
                            {
                                list.map((e) => (
                                    <ListItem key={e.id}>
                                        {/* <ListItemText>{e.description} </ListItemText> */}
                                        <div style={{ width: '100%', height: '30px', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '95%' }}>{e.description}</div>
                                            <div>
                                                <Tooltip title={"Delete"}>
                                                    <IconButton onClick={() => deleteParticipants(e.id)}>
                                                        {/* <DeleteIcon style={{ color: '#650E3D' }} /> */}
                                                        <img src={DeleteSVG} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"}>
                        <CustomButton
                            variant="contained"
                            title={t('Save')}
                            onClick={saveForm}
                            isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddUpdateChat