import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, LinearProgress, List, Popover, Typography } from '@mui/material';
import WorldSVG from 'src/images/world_svg.svg';
import EmailSVG from 'src/images/email_svg.svg';
import PhoneSVG from 'src/images/phone_svg.svg';
import LocationSVG from 'src/images/location.svg'
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchSVG from 'src/images/search_svg.svg'
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CustomButton from 'src/components/Custom/CustomButton';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import { useTranslation } from 'react-i18next';
import CustomToast from 'src/components/Custom/CustomToast';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import { getValue } from 'src/components/helpers/Functions';

const ClientDetails = (props) => {
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [tableISLoading, setTableIsLoading] = useState(false);
    let { id, caseID } = useParams();
    const [caseStatuses, setCaseStatuses] = useState([]);
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedID, setSelectedID] = useState(-1);
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'company_clients')

    let openPop = Boolean(anchorEl);
    const id_pop = openPop ? 'simple-popover' : undefined;

    const handleOpenPop = (event, id) => {
        setSelectedID(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClosePop = () => {
        setAnchorEl(null);
    };

    const [formData, setFormData] = useState({
        id: -1,
        status: '',
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            status: '',
        });
    }


    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/companyClients/getById`, {
            params: {
                company_id: id,
                id: props.id,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    const getCases = () => {
        setTableIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
            params: {
                company_id: id,
                client_id: props.userID,
            }
        })
            .then(res => {
                setTableIsLoading(false);
                setTableData(res.data);
            }).catch(err => {
                setTableIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        if (props.id > 0) {
            if (caseStatuses.length == 0) {
                const getStatuses = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/CaseStatuses/get`, {
                        params: {},
                    })
                        .then(function (response) {
                            setCaseStatuses(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getStatuses();
            }
            getData();
            getCases();
            resetForm();
        }
    }, [props.id]);
    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'color_code',
            label: 'color',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: t('case_name'),
        },
        {
            name: "status",
            label: t('Status'),
            options: {
                filter: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    const targetElement = caseStatuses?.find(e => e.id == value)
                    return (
                        <>
                            <div aria-describedby={id_pop}>

                                <CustomButton variant="outlined"
                                    style={{
                                        height: '40px'
                                        , minwidth: '150px'
                                        , border: `2px solid #650E3D`
                                        , color: `#650E3D`
                                        , borderRadius: '30px'
                                        , fontSize: '12px'
                                        , fontWeight: '700'
                                        // , textAlign: 'left'
                                        // , justifyContent: 'left'
                                    }}
                                    title={
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                                                    <span style={{
                                                        fontWeight: '700'
                                                        , fontSize: '7px'
                                                        , textTransform: 'capitalize'
                                                        , lineHeight: '3px'
                                                        , marginTop: '7px'
                                                    }}>
                                                        {t('Status')}
                                                    </span>
                                                    <span style={{ color: `${meta.rowData[1]}` }}>
                                                        {/* {value} */}{
                                                            getValue(targetElement,'name',i18n.language)
                                                        }
                                                    </span>
                                                </div>
                                                <KeyboardArrowDownIcon style={{ color: `#650E3D`, marginTop: '5px' }} />
                                            </div>
                                        </>
                                    }
                                    onClick={targetPage?.can_edit === 1 ? (e) => handleOpenPop(e, meta.rowData[0]) : null}
                                // endIcon={<KeyboardArrowDownIcon style={{ color: `#650E3D`, marginTop: '5px' }} />}
                                />
                            </div>
                            <Popover
                                id={id_pop}
                                open={openPop}
                                anchorEl={anchorEl}
                                onClose={handleClosePop}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <List xs={{ pt: 0 }}>
                                    {
                                        saveIsLoading ?
                                            <CircularProgress />
                                            :
                                            caseStatuses.map((e, key) => (
                                                <Typography key={key} onClick={() => changeStatus(e.id)}
                                                    sx={{ p: 2, cursor: 'pointer', color: `${e.color_code}` }}>{getValue(e,'name',i18n.language)}</Typography>
                                            ))
                                    }
                                </List>
                            </Popover>
                        </>
                    )
                },
            },
        },
        {
            name: 'lawyers_full_name',
            label: t('lawyers')
        },
    ];
    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: tableISLoading ?
                    <LinearProgress /> :
                    'There is no matching data to display',
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                //   <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }
    const changeStatus = (status) => {
        setSaveIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/updateStatus`,
            data: { id: selectedID, status: status },
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('updated_mssg') });
                setSaveIsLoading(false);
                handleClosePop();
                getCases();
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    };
    return (
        <div style={{ height: '100%', position: 'relative' }}>
            <div style={{ backgroundColor: '#ffffff', minHeight: '100px', paddingTop: '25px', paddingBottom: '25px' }}>
                <div style={{
                    width: '94%'
                    , height: '94%'
                    , marginLeft: 'auto'
                    , marginRight: 'auto'
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <div style={{ color: '#650E3D', cursor: 'pointer' }} onClick={props.onClick}>
                                {'< '}{t('Back')}
                            </div>
                        </Grid>
                        {
                            isLoading ?
                                <CircularProgress />
                                :
                                <>
                                    <Grid item xs={12} md={4}>
                                        <div style={{
                                            height: '60px'
                                            , display: 'flex'
                                            , alignItems: 'center'
                                        }}>
                                            <img src={'https://symbols.getvecta.com/stencil_198/24_black-small-square.3fb8f5251a.svg'}
                                                width={50} style={{ borderRadius: '25px' }} />
                                        </div>
                                        <div className='d-flex  align-items-center'>
                                            <img src={LocationSVG} width={8} />
                                            <span style={{
                                                fontWeight: '400'
                                                , fontSize: '11px'
                                                , marginLeft: '5px'
                                                , color: '#650E3D'
                                            }}>{data?.nationality_name} </span>
                                        </div>
                                        <div style={{ lineHeight: '15px', fontWeight: '700', fontSize: '16px', color: '#650E3D' }}>
                                            {data?.full_name}
                                        </div>
                                        <div style={{ lineHeight: '15px', fontWeight: '900', fontSize: '12px', color: 'rgba(87, 87, 87, 1)' }}>
                                            {t('active_at')} {moment(data?.last_login_date).format('h:mm A')}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div style={{
                                            backgroundColor: 'rgba(248, 248, 248, 1)'
                                            , height: '102px'
                                            , display: 'flex'
                                            , flexDirection: 'column'
                                            , borderRadius: '5px'
                                        }}>
                                            <div className='d-flex' style={{ marginLeft: '20px', marginTop: '12px' }}>
                                                <img src={EmailSVG} />
                                                <span style={{
                                                    marginLeft: '13px', fontSize: '12px'
                                                    , fontWeight: '700', color: '#650E3D'
                                                }}>{data?.email}</span>
                                            </div>
                                            <div className='d-flex' style={{ marginLeft: '20px', marginTop: '12px' }}>
                                                <img src={PhoneSVG} />
                                                <span style={{
                                                    marginLeft: '13px', fontSize: '12px'
                                                    , fontWeight: '700', color: '#650E3D'
                                                }}>{data?.full_phone}</span>
                                            </div>
                                            <div className='d-flex' style={{ marginLeft: '20px', marginTop: '12px' }}>
                                                <img src={WorldSVG} />
                                                <span style={{
                                                    marginLeft: '13px', fontSize: '12px'
                                                    , fontWeight: '700', color: '#650E3D'
                                                }}>12:00 AM GMT+3</span>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div style={{
                                            height: '44%'
                                            , backgroundColor: 'rgba(248, 248, 248, 1)'
                                            , display: 'flex'
                                            , flexDirection: 'column'
                                            , lineHeight: '17px'
                                            , justifyContent: 'center'
                                            , color: '#650E3D'
                                        }}>
                                            <span style={{ marginLeft: '20px' }}>{t('Total') + ' ' + t('Invoice')}</span>
                                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{data?.total_invoices} USD</span>
                                        </div>
                                        <div style={{
                                            height: '44%'
                                            , backgroundColor: 'rgba(248, 248, 248, 1)'
                                            , marginTop: '5px'
                                            , display: 'flex'
                                            , flexDirection: 'column'
                                            , lineHeight: '17px'
                                            , justifyContent: 'center'
                                            , color: '#650E3D'
                                        }}>
                                            <span style={{ marginLeft: '20px' }}>{t('Total') + ' ' + t('Recieved')}</span>
                                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{data?.total_payments} USD</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{
                                            width: '100%'
                                            , backgroundColor: '#650E3D'
                                            , color: '#ffffff'
                                            , borderRadius: '16px'
                                            , marginTop: '2px'
                                        }}>
                                            <span style={{ paddingLeft: '10px', fontSize: '14px', fontWeight: '700', textTransform: 'uppercase' }}>
                                                {data?.total_expenses} {t('Ongoing') + ' ' + t('Retainer')}
                                            </span>
                                        </div>
                                    </Grid>
                                </>

                        }
                    </Grid>
                </div>
            </div>
            <div>
                <CustomDataTable
                    data={tableData}
                    columns={columns}
                    options={options}
                    height={'300px'}
                />
            </div>
        </div>
    )
}
export default ClientDetails