import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomInput, SingleDate, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';


const AddUpdateNote = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        id: -1,
        hearing_id: null,
        note: '',
    });

    const resetForm = () => {
        setFormData({
            id: props.id,
            hearing_id: props.hearing_id,
            note: '',
        });
    }

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/getNoteById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    hearing_id: props.hearing_id,
                    note: response.data.note
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }

    
    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/updateNote`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('updated_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/addNote`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('insert_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }

    };
    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-4' style={{ color: '#650E3D' }}>{props.id > 0 ? 'Update' : 'Add'} {t('Note')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid item xs={12}>
                    <CustomInput
                        id={'note'}
                        value={formData.note}
                        onChange={handleInputChange}
                        placeholder={t('Note')}
                        withBorder={false}
                        multiline={true}
                        minRows={4}
                    />
                </Grid>
                <Grid item xs={12} marginTop={"50px"}>
                    <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddUpdateNote