import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomInput, SingleDate } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CustomAutocompleteServerSide from 'src/components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import AddIcon from '@mui/icons-material/Add'
import AddExpenseType from './AddExpenseType';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';



const AddUpdateExpenses = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [openType, setOpenType] = useState(false);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [cases, setCases] = useState([]);

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID > 0 ? caseID : null,
        name: '',
        description: '',
        expense_type_id: null,
        value: '',
        quantity: 1,
        currency: null,
        vat: 11,
        total: '',
        date: new Date(),
        with_vat: false,
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID > 0 ? caseID : null,
            name: '',
            description: '',
            expense_type_id: null,
            value: '',
            quantity: 1,
            currency: null,
            vat: 11,
            total: '',
            date: new Date(),
            with_vat: false,
        });
    }

    const getExpenseTypes = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/expenseTypes/get`, {
            params: {
                general: caseID > 0 ? false : true
            },
        })
            .then(function (response) {
                setExpenseTypes(response.data);
            })
            .catch(function (error) {
                handelErrors(error)
            })
    }

    useEffect(() => {
        if (currencies.length == 0) {
            const getCurrencies = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/currencies/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCurrencies(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCurrencies();
        }

        if (expenseTypes.length == 0) {
            getExpenseTypes();
        }
        if (props.open && cases.length == 0) {
            const getCases = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
                    params: {
                        company_id: id
                    },
                })
                    .then(function (response) {
                        setCases(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCases();
        }

    }, [props.open]);

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/expenses/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: response.data.case_id,
                    name: response.data.name,
                    description: response.data.description,
                    expense_type_id: response.data.expense_type_id,
                    value: response.data.value,
                    currency: response.data.currency,
                    quantity: response.data.quantity,
                    vat: response.data.vat,
                    total: response.data.total,
                    with_vat: response.data.with_vat == 1 ? true : false,
                    date: response.data.date ? new Date(response.data.date) : null,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    useEffect(() => {
        if (formData.value > 0) {
            let total = 0;
            const vat = 11;
            if (formData.value > 0 && formData.quantity > 0) {
                if (formData.with_vat == true) {
                    total = Number(formData.value) + Number(parseFloat((formData.value * vat) / 100).toFixed(2));
                } else {
                    total = formData.value
                }
                total = total * formData.quantity;
            }
            setFormData({
                ...formData,
                total: parseFloat(total).toFixed(2),
            });
        } else {
            setFormData({
                ...formData,
                total: 0,
            });
        }
    }, [formData.value, formData.with_vat, formData.quantity]);

    const saveForm = () => {
        setSaveIsLoading(true);
        // const formDataObj = new FormData();
        // for (const [key, value] of Object.entries(formData)) {
        //     if (value && value !== "" && value !== null) {
        //         console.log(typeof value, key, Array.isArray(value), value);
        //         if (Array.isArray(value)) {
        //             value.forEach(element => {
        //                 console.log(element);
        //                 formDataObj.append(`${key}[]`, element);
        //             });
        //         }
        //         else {
        //             if (key == "date") {
        //                 formDataObj.append(key, moment(value).format('YYYY-MM-DD'));
        //             }else{
        //                 formDataObj.append(key, value);
        //             }
        //         }
        //     }
        // }
        if (formData.quantity > 0) {
            if (formData.id > 0) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/expenses/update`,
                    data: formData,
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('updated_mssg') });
                        setSaveIsLoading(false);
                        props.onClose(true);
                    })
                    .catch(error => {
                        setSaveIsLoading(false);
                        handelErrors(error);
                    });
            } else {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/expenses/add`,
                    data: formData,
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('insert_mssg') });
                        setSaveIsLoading(false);
                        props.onClose(true);
                    })
                    .catch(error => {
                        setSaveIsLoading(false);
                        handelErrors(error);
                    });
            }
        } else {
            CustomToast({ type: 'warning', message: t('qty_mssg') });
        }
    };
    const addForm = () => {
        setOpenType(true);
    }
    const handleClose = (refresh) => {
        console.log("refresh");
        console.log(refresh);
        setOpenType(false);
        if (refresh) {
            getExpenseTypes();
        }
    }

    return (
        <CustomNewDialog maxWidth={'md'}
            fullWidth
            open={props.open}
            onClose={props.onClose}>
            <DialogTitle>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') :t('Add')} {t('Expense')}</div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={5}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SingleDate
                            id="date"
                            label={t('Date')}
                            value={formData.date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    {
                        caseID > 0 ?
                            null
                            :
                            <Grid item xs={12} md={3}>
                                <SingleCustomAutocomplete
                                    filedName="case_id"
                                    label={t('Case')}
                                    list={cases}
                                    value={formData.case_id}
                                    listKey="id"
                                    description="name"
                                    customOnChange={customOnChange}
                                />
                            </Grid>
                    }
                    <Grid item xs={10} md={4}>
                        <CustomInput
                            id={'value'}
                            value={formData.value}
                            onChange={handleInputChange}
                            placeholder={t('Value')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={1} md={3}>
                        <SingleCustomAutocomplete
                            filedName="currency"
                            label={t('Currency')}
                            list={currencies}
                            value={formData.currency}
                            listKey="code"
                            description="code"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <SingleCustomAutocomplete
                            filedName="expense_type_id"
                            label={t('Expense') + t('Type')}
                            list={expenseTypes}
                            value={formData.expense_type_id}
                            listKey="id"
                            description="name"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={2} md={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <div >
                            <Tooltip title={"Add"}>
                                <IconButton onClick={addForm}>
                                    <AddIcon style={{ color: '#650E3D' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'description'}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={t('Description')}
                            withBorder={false}
                            multiline={true}
                            minRows={3}
                        />
                    </Grid>
                    {/* {
                        caseID > 0 ?
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <CustomInput
                                            id={'quantity'}
                                            value={formData.quantity}
                                            onChange={handleInputChange}
                                            placeholder={t('Quantity')}
                                            withBorder={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null
                    } */}

                    <Grid item xs={2} md={1}>
                        <CustomCheckbox
                            label={t('Taxable')}
                            checked={formData.with_vat}
                            id="with_vat"
                            onClick={handleInputChange}
                        />
                    </Grid>
                    {
                        formData.with_vat == true ?
                            <Grid item xs={6} md={4}>
                                <CustomInput
                                    id={'vat'}
                                    value={formData.vat}
                                    onChange={handleInputChange}
                                    placeholder={t('VAT')}
                                    withBorder={false}
                                />
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <span style={{ color: '#650E3D', fontSize: '24px', fontWeight: '400',textTransform:'uppercase' }}>{t('Total')}</span>
                        <span style={{ float: i18n.language =="ar" ? 'left' :'right', color: '#650E3D', fontSize: '24px', fontWeight: '700' }}>
                            {formData.total > 0 ? formData.total : 0}
                        </span>
                    </Grid>
                    <Grid item xs={12} marginTop={"20px"} style={{ marginBottom: '2%' }}>
                        <Grid container >
                            <Grid item xs={0} md={6}></Grid>
                            <Grid item xs={12} md={6}>
                                <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <AddExpenseType
                open={openType}
                id={''}
                onClose={handleClose} />
        </CustomNewDialog>
    )
}
export default AddUpdateExpenses