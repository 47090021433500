import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import EditSVG from 'src/images/edit_svg.svg'
import { useParams } from 'react-router-dom'
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import handelErrors from 'src/components/helpers/handelErrors';
import SearchSVG from 'src/images/search_svg.svg'
import NotificationFilledSVG from 'src/images/notification_filled_svg.svg';
import NotificationSVG from 'src/images/notification_svg.svg';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import axios from 'axios';
import moment from 'moment';
import AddUpdateMeeting from './AddUpdateMeeting';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import { useTranslation } from 'react-i18next';
import AddReminder from './AddReminder';

const MeetingsTable = (props) => {
    let { id, caseID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [events, setEvents] = useState([]);
    const { t, i18n } = useTranslation();
    const [selectedID, setSelectedID] = useState('');
    const [openReminder, setOpenReminder] = useState(false);
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'meetings')
    const targetPageCase = presetPages.find(e => e.page_code === 'case_meetings')
    const getData = (date) => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/meetings/getData`, {
            params: {
                company_id: id,
                case_id: caseID,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }

    useEffect(() => {
        getData();
    }, []);

    const addForm = () => {
        setSelectedID('');
        setOpen(true);
    }
    const handleClose = (refresh = false) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }
    const handleEdit = meta => {
        setSelectedID(meta.rowData[0]);
        setOpen(true);
    }

    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'reminder',
            label: 'Reminder',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'title',
            label: t('name')
        }
        , {
            name: 'description',
            label: t('Description')
        },
        {
            name: 'start',
            label: t('Start'),
            options: {
                filter: false,
                // sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <div>
                            {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                }
            }
        },

        {
            name: 'end',
            label: t('End'),
            options: {
                filter: false,
                // sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <div>
                            {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                }
            }
        },
        {
            name: "actions",
            label: t('Actions'),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            {
                                targetPageCase?.can_view === 1 && targetPageCase?.can_edit === 1 ?
                                    <Tooltip title={t('Edit')}>
                                        <IconButton onClick={() => handleEdit(meta)}>
                                            <img src={EditSVG} />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                            }
                            {
                                targetPageCase?.can_view === 1 && targetPageCase?.can_delete === 1 ?
                                    <Tooltip title={t('Reminder')}>
                                        <IconButton onClick={() => addReminder(meta)}>
                                            {
                                                meta.rowData[1] > 0 ? <img src={NotificationFilledSVG} width={20} /> : <img src={NotificationSVG} width={20} />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                    : null
                            }
                        </>
                    )
                },
            },
        },
    ];

    const options = {
        filter: false,
        print: false,
        download: true,
        viewColumns: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (<span>
                {
                    targetPageCase?.can_add === 1 && targetPageCase?.can_view === 1
                        ?
                        <span style={{ marginLeft: '10px' }}><CustomAddButton onClick={addForm} title={t('New')+' '+t('Meeting')} /></span>

                        : null
                }
            </span>);
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };
    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }

    const addReminder = (meta) => {
        setSelectedID(meta.rowData[0]);
        setOpenReminder(true);
    }
    const handleCloseReminder = (refresh) => {
        setOpenReminder(false);
        if (refresh == true) {
            getData();
        }
    }
    return (
        <div>
            <CustomDataTable
                data={data}
                columns={columns}
                options={options}
            />
            <AddUpdateMeeting open={open} onClose={handleClose} id={selectedID} />
            <AddReminder
                open={openReminder}
                id={selectedID}
                onClose={handleCloseReminder}
            />
        </div>
    )
}
export default MeetingsTable