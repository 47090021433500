import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import CustomDialog from '../../components/Custom/CustomDialog';
import styles from '../../styles/login.module.css';
import { CustomInput, SingleDate } from '../../components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SingleCustomAutocomplete from '../../components/Custom/SingleCustomAutocomplete';
import CustomAutocompleteServerSide from '../../components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomFileButton from 'src/components/Custom/CustomFileButton';
import { getFileSize } from 'src/components/helpers/Functions';
import SingleFile from 'src/images/single_file_svg.svg';
import DownloadSvg from 'src/images/download_svg.svg';

const CustomFile = (props) => {

    const downloadFile = (path) => {
        window.open(`${process.env.REACT_APP_API_BASE_URL_FILE}/CompanyFiles/${path}`, '_blank').focus();
    }
    return (
        <div style={{
            display: 'flex', width: '100%', color: '#650E3D'
            , height: '40px'
            , alignItems: 'center'
        }}>
            <img src={SingleFile} />
            <div style={{
                height: '100%'
                , alignItems: 'center'
                , display: 'flex'
                , marginLeft: '20px'
                , fontWeight: '700'
                , width: '90%'
            }}>
                {props.data.visible_name}
            </div>
            <div><img src={DownloadSvg} style={{ cursor: 'pointer' }} onClick={() => downloadFile(props.data.path_name)} /></div>
        </div>
    )
}

const FilesModal = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        task_id: -1,
        files: [],
    });
    const fileInputRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState({
        count: 0,
        size: 0,
    });
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'files')
    const targetPageCase = presetPages.find(e => e.page_code === 'case_task_files')

    const resetForm = () => {
        setFormData({
            task_id: -1,
            files: [],
        });
        setSelectedFileName({
            count: 0,
            size: 0,
        })
    }

    const getData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/getFilesById-${props.id}`,
        })
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            getData();
            setFormData({
                task_id: props.id,
                files: [],
            });
        } else {
            resetForm();
        }
    }, [props.open]);

    const saveForm = () => {
        setSaveIsLoading(true);
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            if (value && value !== "" && value !== null) {
                if (key == "files") {
                    formData.files.map((e) => {
                        formDataObj.append('files[]', e);
                    });
                } else {
                    formDataObj.append(key, value);
                }
            }
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/addFiles`,
            data: formDataObj,
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('insert_mssg') });
                setSaveIsLoading(false);
                props.onClose(true);
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    };
    const handleChooseFile = () => {
        fileInputRef.current.click();
    };

    const handleChangeFile = (e) => {
        e.preventDefault()
        let uploadedFiles = e.target.files;
        console.log(uploadedFiles);
        if (uploadedFiles) {
            setFormData({
                ...formData,
                files: [...uploadedFiles]
            });
            let uploadedFilesCount = 0;
            let uploadedFilesSize = 0;
            Object.keys(uploadedFiles).forEach(key => {
                uploadedFilesCount++;
                uploadedFilesSize += (uploadedFiles[key].size / 1000);
            });
            setSelectedFileName({
                count: uploadedFilesCount,
                size: uploadedFilesSize,
            })
        }
        else {
            setSelectedFileName({
                count: 0,
                size: 0,
            })
        }
        return
    }

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{t('Tasks')} {t('Files')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid item xs={12} md={12}>
                    <div style={{
                        width: '100%'
                        , borderRadius: '16px'
                        , backgroundColor: 'rgba(101, 14, 61, 0.04)'
                        , padding: '25px'
                    }}>
                        <h4 style={{ color: '#650E3D' }}>{t('Files')}</h4>
                        <div style={{
                            width: '100%'
                            , marginTop: '20px'
                            , display: 'flex'
                            , flexDirection: 'column'
                            , height: '200px'
                            , overflow: 'auto'
                        }}>
                            {
                                data.map((e) => (
                                    <CustomFile data={e} />
                                ))
                            }
                        </div>
                        {/* <div style={{ width: '100%', marginTop: '10px' }}>
                            <CustomButton variant="contained" onClick={addFile} title={t('add')} />
                        </div> */}
                    </div>
                </Grid>
                {
                    (targetPage?.can_add === 1 && targetPage?.can_view === 1)
                    ||
                    (targetPageCase?.can_add === 1 && targetPageCase?.can_view === 1)
                     ?
                        <>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} multiple name={t('attachement')} id="attachement" onChange={(e) => handleChangeFile(e)} />
                                <div className='d-flex'>
                                    <CustomFileButton onClick={handleChooseFile} />
                                    <div className='d-flex flex-column'>
                                        <span style={{ marginTop:'10px',marginLeft: '10px', marginRight:'5px',color: '#650E3D' }}>{selectedFileName.count > 0 ? selectedFileName.count : t('upload_files')}</span>
                                        <span style={{ marginLeft: '10px', fontWeight: '700', color: '#650E3D' }}>{selectedFileName.size > 0 ? getFileSize(selectedFileName.size) : ''}</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} marginTop={"50px"}>
                                <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                            </Grid>
                        </>
                        : null
                }

            </DialogContent>
        </Dialog>
    )
}
export default FilesModal