import React from 'react'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import LinearProgress from '@mui/material/LinearProgress'

const CustomDataTable = (props) => {
    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        selectableRows: 'none',
        onDownload: (buildHead, buildBody, columns, data) => {
            return '\uFEFF' + buildHead(columns) + buildBody(data)
        },
        textLabels: {
            body: {
                noMatch: props.isLoading ? <LinearProgress /> : 'There is no matching data to display',
            },
        },
    }
    return (
        <ThemeProvider theme={getMuiTheme(props)}>
            <MUIDataTable
                options={props.options ? props.options : options}
                columns={props.columns}
                data={props.isLoading ? [] : props.data}
                title={props.title}
            />
        </ThemeProvider>
    )
}
CustomDataTable.propTypes = {
    isLoading: PropTypes.any,
    theme: PropTypes.any,
    options: PropTypes.any,
    columns: PropTypes.any,
    data: PropTypes.any,
    title: PropTypes.any,
    height: PropTypes.any,
}

function getMuiTheme(props) {
    let height = `calc(${props.style?.height ? props.style.height : "100vh"} - 360px)!important`;

    if (props.height) {
        height = props.height;
    }
    return createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        fontFamily: 'Poppins, sans-serif',
                        // direction:'ltr'
                    },
                    paper: {
                        boxShadow: 'none',
                    },
                    responsiveBase: {
                        height: height,
                    },
                },
            },
            MUIDataTableBodyRow: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#F9F9F9',
                        border: 'none',
                    },
                },
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#F4F4F4',
                        borderBottom:'1px solid #F4F4F4',
                        color: '#650E3D',
                        fontWeight: '500',
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'uppercase',
                        '& span': {
                            justifyContent: 'left',
                            fontFamily: 'Poppins, sans-serif',
                        },
                        '& div': {
                            textAlign: 'left',
                            fontFamily: 'Poppins, sans-serif',
                        },
                        '& button': {
                            paddingLeft: 0,
                            fontWeight: '500',
                            textTransform: 'uppercase',
                            fontFamily: 'Poppins, sans-serif',
                            height:'36px',
                        },
                    },
                },
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        paddingTop: props.rowPadding ? props.rowPadding : '6px',
                        paddingBottom: props.rowPadding ? props.rowPadding : '6px',
                        fontFamily: 'Poppins, sans-serif',
                    },
                },
            },
            MUIDataTableToolbar: {
                styleOverrides: {
                    icon: {
                        color: '#650E3D',
                        '&:hover': {
                            color: '#650E3D', // Set the hover color for the icons
                        },
                        '&:focus': {
                            color: '#650E3D', // Set the focus color for the icons
                        },
                        '&:active': {
                            color: '#650E3D', // Set the active (clicked) color for the icons
                        },
                    },

                }
            },
            MUIDataTableFooter: {
                styleOverrides: {
                    root: {
                        '& p': {
                            paddingTop: '15px',
                            color: '#650E3D',
                            fontFamily: 'Poppins, sans-serif'
                        }
                    }
                }
            },
            MUIDataTablePagination: {
                styleOverrides: {
                    root: {
                        '& .MuiIconButton-root': {
                            color: '#650E3D', // Change the color of the pagination icons
                            fontFamily: 'Poppins, sans-serif'
                        },
                        '& .MuiTypography-root': {
                            color: '#650E3D', // Change the color of the page numbers
                            fontFamily: 'Poppins, sans-serif',
                        },
                    },
                },
            },
        },
    })
}


export default CustomDataTable