import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import CustomTabs from '../../components/Custom/CustomTabs';
import HomeLogo from '../../images/home_svg.svg';
import ChatsLogo from '../../images/chat_svg.svg';
import FilesLogo from '../../images/files_svg.svg';
import ClientsLogo from '../../images/contact_svg.svg';
import TasksLogo from '../../images/task_svg.svg';
import SearchSVG from 'src/images/search_svg.svg'
import ArchiveLogo from '../../images/archive_svg.svg';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CustomDataTable from '../../components/Custom/CustomDataTable';
import CustomDates from '../../components/Custom/CustomDates';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CustomClientHeader from 'src/components/Header/CustomClientHeader';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import { useTranslation } from 'react-i18next';

const ClientHome = (props) => {
    const [tabSelected, setTabSelected] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])
    const { t, i18n } = useTranslation();

    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
    }


    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/getByClient`, {
            params: {
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })

    }
    useEffect(() => {
        if (tabSelected == 0) {
            getData();
        }
    }, [tabSelected]);

    const columns = [
        {
            name: 'name',
            label: 'Case Name'
        }
        , {
            name: 'court_name',
            label: 'Court'
        },
        {
            name: 'manual_court_id',
            label: 'Court ID'
        },
        {
            name: 'clients_full_name',
            label: 'Name&Client',
            options: {
                filter: false,
                // sort: false,
                customBodyRenderLite: (dataIndex) => {
                    console.log(data[dataIndex].serial);
                    return (
                        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%' }}>
                            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <img
                                        alt=""
                                        //   src={`${process.env.REACT_APP_API_BASE_URL_FILE}/customersLogo/${data[dataIndex].logo}`}
                                        src={EmployeeLogo}
                                        width="25"
                                        height="25"
                                        style={{ borderRadius: '50%' }}
                                    /> */}
                                    <AccountCircleOutlinedIcon />
                                    <span style={{ marginLeft: '5px', fontSize: '14px' }}>{data[dataIndex].clients_full_name}</span>
                                </div>
                                <div className='colored-text size-20'>
                                    {data[dataIndex].name}
                                </div>
                                {/* <div className='small_text_column'>
                                    Lebanon
                                </div> */}
                            </div>

                        </div>
                    )
                }
            }
        },
        {
            name: 'status',
            label: 'Status'
        },
        {
            name: 'lawyers_full_name',
            label: 'Lawyers'
        },
    ];

    const options = {
        filter: false,
        print: false,
        download: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    'There is no matching data to display',
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e)=>handleSearchBar(e,handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event,onSearch)=>{
        onSearch(event.target.value)
    }
    return (
        <Grid container style={{ backgroundColor: '#F4F4F4', minHeight: '100vh' }}>
            <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                <CustomClientHeader />
                <Grid container spacing={4}>
                    <Grid item xs={12} md={9} style={{ marginTop: '40px'}}>
                        <Grid container>
                            <Grid item xs={10} md={10}>
                                <CustomTabs
                                    tabs={[
                                        {
                                            key: 0, label: 'Home', image: HomeLogo,display:true
                                        },
                                        {
                                            key: 1, label: 'Chats', image: ChatsLogo,display:true
                                        },
                                        {
                                            key: 2, label: 'Files', image: FilesLogo,display:true
                                        },
                                        {
                                            key: 3, label: 'Clients', image: ClientsLogo,display:true
                                        },
                                        {
                                            key: 4, label: 'Tasks', image: TasksLogo,display:true
                                        },
                                        {
                                            key: 5, label: 'Archive', image: ArchiveLogo,display:true
                                        },
                                    ]} tabValue={tabSelected} onTabChange={onTabChange} />
                            </Grid>
                            <Grid item xs={0} md={2}>
                                <div className="d-flex justify-content-end align-items-center" style={{ height: '100%' }}>
                                    <NotificationsOutlinedIcon style={{ color: '#650E3D' }} fontSize='large' />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    tabSelected === 0
                                        ?
                                        <CustomDataTable
                                            data={data}
                                            columns={columns}
                                            options={options}
                                        />
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ marginTop: '40px' }}>
                        <Grid item xs={12}>
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                <span style={{ color: '#650E3D', fontSize: '24px' }} >My Calendar</span>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ height: '100%', width: '100%', marginTop:'40px',backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
                                <CustomDates />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}
export default ClientHome