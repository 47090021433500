import { Tab, Tabs,useMediaQuery,useTheme } from '@mui/material'
import React from 'react'
const CustomTabs = (props) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    return(
        <Tabs 
            value={props.tabValue}
            onChange={props.onTabChange}
            variant="scrollable"
            scrollButtons={isMobile ? 'auto' : 'off'}
            allowScrollButtonsMobile
            aria-label="icon label tabs example"
            sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#650E3D',
                  height:'3px',
                  borderRadius:'5px',
                },
                '& .Mui-selected': {
                  fontWeight: 700, // Font weight for the selected tab
                },
              }}
        >
            {props.tabs.map((value,index)=>{
                return(
                    value.display == true ? 
                    <Tab key={index} label={value.label} 
                        icon={value.image ? <img src={value.image}/> : null}
                        style={{
                            color:'#650E3D',
                            fontFamily: 'Poppins, sans-serif',
                            textTransform:'capitalize'
                        }}
                    />
                    :
                    <Tab key={index} label={value.label} 
                        icon={value.image ? <img src={value.image}/> : null}
                        style={{
                            color:'#650E3D',
                            fontFamily: 'Poppins, sans-serif',
                            textTransform:'capitalize',
                            display:'none'
                        }}
                    />
                )
            })}
        </Tabs>
    )
}

export default CustomTabs