import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material';
import ClientsLogo from '../../images/contact_svg.svg';
import EditSVG from 'src/images/edit_svg.svg'
import SendSVG from 'src/images/send_svg.svg'
import CustomMessage from './CustomMessage';
import { CustomBoxInput } from 'src/components/Custom/CustomInputForms';
import { useTranslation } from 'react-i18next';
import { getAuth } from "firebase/auth";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "src/FireBase/App";
import handelErrors from 'src/components/helpers/handelErrors';
import axios from 'axios';

const CustomChatView = props => {
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        message: '',
        chat_id: props.data.chat_id,
    });

    const resetForm = () => {
        setFormData({
            message: '',
            chat_id: props.data.chat_id,
        });
    }
    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }

    const sendMessage = async () => {
        try {
            const txt = formData.message;
            if (txt.trim() !== "") {
                resetForm();
                const docRef = await addDoc(collection(db, 'chat_messages'), {
                    name: props.chat.name,
                    chat_id: props.chat.id,
                    date: new Date(),
                    message: txt.trim(),
                    user_id: localStorage.getItem('google_uid'),
                    user_name: localStorage.getItem('google_email'),
                })
                const chatRef = doc(db, 'chats', props.chat.id);
                updateDoc(chatRef, { last_message_date: new Date() });
                axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API_BASE_URL}/chats/addMessage`,
                    data: {
                        firebase_id: props.chat.id,
                        uid: localStorage.getItem('google_uid'),
                        message: txt.trim(),
                    },
                })
                    .then((response) => {
                    })
                    .catch((error) => {
                        handelErrors(error)
                    });
            }
        } catch (error) {
            handelErrors(error);
        }
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            sendMessage();
        }
    }

    return (
        <div style={{
            height: '60px'
            , backgroundColor: 'white'
            , width: '90%'
            , marginLeft: 'auto'
            , marginRight: 'auto'
            , marginTop: '10px'
            , borderRadius: '5px'
        }}>
            <div style={{
                width: '88%'
                , marginLeft: 'auto'
                , marginRight: 'auto'
                , display: 'flex'
                , alignItems: 'center'
                , height: '100%'
            }}>
                <div style={{ width: '14%', display: 'flex', alignItems: 'center' }}>
                    <img src={ClientsLogo} width={35} />
                </div>
                <div style={{ width: '80%', display: 'flex', alignItems: 'center' }}>
                    <span>{props.chat ? props.chat.name : 'chat'}</span>
                </div>
                {
                    props.updateForm != null
                        ?
                        <div style={{ width: '6%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                            onClick={() => props.updateForm(props.chat.id)}>
                            <img src={EditSVG} />
                        </div>
                        : null
                }

            </div>
            <div style={{ width: '100%', position: 'relative' }}>
                <div style={{ height: '440px', overflow: 'auto', marginTop: '10px' }}>
                    {
                        props.isLoading ?
                            <CircularProgress style={{ color: '#650E3D' }} />
                            :
                            props.data.length == 0 ?
                                <div>
                                    {t('no_message_found')}
                                </div>
                                :
                                <div style={{ marginBottom: '50px' }}>
                                    {
                                        props.data.map((e) => (
                                            <CustomMessage data={e} />
                                        ))
                                    }
                                </div>
                    }
                </div>
                <div style={{
                    position: 'absolute', bottom: 40
                    , width: '100%'
                    , height: '20px'
                    , backdropFilter: 'blur(0.8px)'
                }} />
                <div style={{
                    position: 'absolute'
                    , bottom: '-20px'
                    , width: '100%'
                }}>
                    <div style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <CustomBoxInput
                            id={'message'}
                            value={formData.message}
                            onKeyPress={onKeyPress}
                            onChange={handleInputChange}
                            placeholder={t('Send') + ' ' + t('Message')}
                            withBorder={false}
                            endAdornment={
                                <img src={SendSVG} style={{ cursor: 'pointer' }} onClick={sendMessage} />
                            } />
                    </div>
                </div>
            </div>

        </div>
    )

}
export default CustomChatView