import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
// import handelErrors from 'src/helpers/handelErrors';
import { Autocomplete, TextField, Chip, FormControl } from '@mui/material';
import handelErrors from '../helpers/handelErrors';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

const CustomAutocompleteServerSide = props => {
  const { t, i18n } = useTranslation();
  const filedName = props.filedName;
  const fieldLabel = props.label;
  const fieldValues = props.values;
  const listKey = props.listKey;
  const companyID = props.companyID;
  const inTeam = props.inTeam;
  const listDescription = props.description;
  const customOnChange = props.customOnChange;
  const [list, setList] = useState([]);
  const previousController = useRef();
  const previousController2 = useRef();

  // const parameters = props.params;
  // let par= [];
  // if(parameters != null){
  //   parameters.map((e)=>{
  //     par.push(e);
  //   });
  // }

  // console.log(par);
  const getData = (searchTerm, source) => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}${props.apiEndPoint}`, {
      params: {
        searchQuery: JSON.stringify({
          q: searchTerm,
          selectedValues: fieldValues,
        }),
        companyID: companyID > 0 ? companyID : null,
        inTeam: inTeam > 0 ? inTeam : null,
      },
      cancelToken: source.token,
    })
      .then(function (response) {
        setList(response.data);
      })
      .catch(function (error) {
        handelErrors(error)
      })
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      let source = previousController.current;
      source?.cancel("cancelled_cleanup");
      source = previousController.current = axios.CancelToken.source();
      if (previousController2.current) {
        clearTimeout(previousController2.current)
      }

      previousController2.current = setTimeout(() => {
        getData(value, source);
      }, 500)
    }
  };
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (fieldValues.length > 0) {
      let fetchData = false;
      fieldValues.forEach(element => {
        let isFound = false;
        list.forEach(element2 => {
          if (element2[listKey] == element) {
            isFound = true;
          }
        });
        if (!isFound) {
          fetchData = true;
          return;
        }
      });
      if (fetchData) {
        getData("", source)
      }
    }
    return function () {
      source.cancel("cancelled_cleanup");
    };
  }, [fieldValues])

  const onFocus = () => {
    const source = axios.CancelToken.source();
    if (fieldValues.length === 0 && list.length === 0) {
      getData("", source)
    }
    return function () {
      source.cancel("cancelled_cleanup");
    };
  }

  const rtl = i18n.language.includes("en")
    ? {}
    : {
      // transformOrigin: "right !important",
      left: "inherit !important",
      right: "2rem !important",
      overflow: "unset",
    };

  const rtlIndicator = i18n.language == "ar" ?
    {
      left: " 1rem !important",
      right: "inherit !important",
      overflow: "unset",
    }
    :
    {};

  const rtlRoot = i18n.language == "ar" ?
    {
      paddingRight: '1rem !important',
    }
    : {};

  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
              border: 'none',
              backgroundColor: '#F4F4F4',
              minHeight: '50px',
              fontFamily: 'Poppins, sans-serif',
              ...rtlRoot,
              '& input': {
                color: '#66023C',
                // direction: 'rtl',
                // textAlign: 'right',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                // borderColor: '#66023C',
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#66023C',
                border: 'none'
              },
              '& .MuiAutocomplete-popupIndicator': {
                color: '#66023C',
              },
              '& .MuiAutocomplete-endAdornment': {
                ...rtlIndicator,
              }
            },
          },
          popper: {
            '& .MuiAutocomplete-option': {
              color: '#66023C',
              // direction: 'rtl',
              // textAlign: 'right',
            },
          },
        },
      },
      MuiTextField: { // Add this section to override TextField styles
        styleOverrides: {
          root: {
            '& label': {
              color: '#66023C',
              fontWeight: 'bold',
              ...rtl,
            },
            '& label.Mui-focused': {
              color: '#66023C',
              // display:'none'
            },
          },
        },
      },
    },
  });
  return (
    <FormControl style={{ width: "100%", marginTop: '10px', marginBottom: '10px' }}>
      <ThemeProvider theme={theme}>
        <Autocomplete
          disableCloseOnSelect={true}
          name={filedName}
          options={list} // Options List
          value={fieldValues}
          getOptionSelected={(option, value) => (value == option[listKey])}
          onInputChange={onInputChange}
          onFocus={onFocus}
          multiple
          onChange={(event, newValue) => {
            const newList = newValue ? newValue.map((v) => v[listKey] ? v[listKey] : v) : [];
            customOnChange(newList, filedName, event);
          }}
          getOptionLabel={(option) => option[listDescription]}
          renderInput={(params) => <TextField {...params} label={fieldLabel} />}
          renderTags={(tagValue) => {
            const fillteredList = list.filter((v) => (tagValue.includes(v[listKey]) || tagValue.includes(`${v[listKey]}`)))
            return (<div>
              {fillteredList.map((value) => (
                <Chip
                  key={value[listKey]}
                  label={value[listDescription]}
                  style={{ margin: '2px' }}
                />
              ))}
            </div>)
          }}
        />
      </ThemeProvider>
    </FormControl>
  );
}
CustomAutocompleteServerSide.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  // list: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any.isRequired,
  apiEndPoint: PropTypes.any.isRequired,
}

export default CustomAutocompleteServerSide;