import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip, List, Popover, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchSVG from 'src/images/search_svg.svg'
import CustomTabs from 'src/components/Custom/CustomTabs';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import CustomButton from 'src/components/Custom/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import CustomDataTable from 'src/components/Custom/CustomDataTable';

const TasksTable = props => {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [caseStatuses, setCaseStatuses] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedID, setSelectedID] = useState(-1);

    let openPop = Boolean(anchorEl);

    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/get`, {
            params: {
                company_id: id,
                user_id: props.id,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        if (props.id > 0) {
            getData();
        }
    }, [props.id]);

    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'reminder',
            label: 'Reminder',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: t('name'),
        },
        {
            name: 'description',
            label: t('Description'),
        },
        {
            name: 'case_name',
            label: t('case_name'),
        },
        {
            name: 'clients_full_name',
            label: t('assign_to'),
        },
    ];
    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }

    return (
        <div style={{position:'absolute',width:'100%'}}>
           <CustomDataTable
                data={data}
                columns={columns}
                options={options}
                height={'300px'}
            />
        </div>
    )
}
export default TasksTable