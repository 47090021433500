import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomDateTimePicker, CustomInput, SingleDate, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomAutocompleteServerSide from 'src/components/Custom/CustomAutocompleteServerSide';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import { statuses } from 'src/components/helpers/FixedData';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';

const AddUpdateTracking = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [taskStatuses, setTasksStatuses] = useState([]);
    let { id, caseID } = useParams();
    const [cases, setCases] = useState([]);
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID,
        description: '',
        from_date: null,
        to_date: null,
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID,
            description: '',
            from_date: null,
            to_date: null,
        });
    }


    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/timesheet/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: response.data.case_id,
                    from_date: response.data.from_date ? new Date(response.data.from_date) : null,
                    to_date: response.data.to_date ? new Date(response.data.to_date) : null,
                    description: response.data.description,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open && cases.length == 0) {
            const getCases = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
                    params: {
                        company_id: id
                    },
                })
                    .then(function (response) {
                        setCases(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCases();
        }
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            if (value && value !== "" && value !== null) {
                if (Array.isArray(value)) {
                    let arr = [];
                    value.forEach(element => {
                        arr.push(element);
                        // formDataObj.append(`${key}[]`, element);
                    });
                    formDataObj.append(`${key}`, JSON.stringify(arr));
                } else {
                    if (key == "from_date" || key == "to_date") {
                        formDataObj.append(key, moment(value).format('YYYY-MM-DD H:m:s'));
                    } else {
                        formDataObj.append(key, value);
                    }
                }
            }
        }
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/timesheet/update`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('updated_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/timesheet/add`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('insert_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };

    return (
        <CustomNewDialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? 'Update' : 'Add'} {t('TimeSheet')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <CustomDateTimePicker id="from_date"
                            label={t('from_date')}
                            value={formData.from_date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDateTimePicker id="to_date"
                            label={t('to_date')}
                            value={formData.to_date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'description'}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={t('Description')}
                            withBorder={false}
                            multiline
                            minRows={3}
                        />
                    </Grid>
                    {
                        caseID > 0 ?
                            null
                            :
                            <Grid item xs={12}>
                                <SingleCustomAutocomplete
                                    filedName="case_id"
                                    label={t('Case')}
                                    list={cases}
                                    value={formData.case_id}
                                    listKey="id"
                                    description="name"
                                    customOnChange={customOnChange}
                                />
                            </Grid>
                    }
                    <Grid item xs={12} marginTop={"50px"} style={{ marginBottom: '2%' }}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </CustomNewDialog>
    )
}
export default AddUpdateTracking