import { Dialog, DialogTitle, Grid, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
// import Frame from '../../images/frame.svg';
import Frame from '../../images/logo_svg.svg';
import EmployeeLogo from '../../images/employee_logo.png';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Swal from 'sweetalert2';
import axios from 'axios';
import cookie from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import allActions from 'src/actions/allActions';
import { toast } from 'react-toastify';
import CustomToast from '../Custom/CustomToast';

const CustomClientHeader = props => {
    const dispatch = useDispatch()

    const logout = () => {
        Swal.fire({
            title: 'Please Confirm Your Action ?',
            showCancelButton: true,
            confirmButtonText: 'Logout!',
            icon: 'warning',
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`)
                    .then((res) => {
                        axios.defaults.headers.common['Authorization'] = ``
                        localStorage.clear()
                        cookie.remove('token')
                        dispatch(allActions.userActions.logOut())
                        window.location.reload()
                    })
                    .catch((e) => {
                        CustomToast({type:'error',message : 'Error'});
                    })
            }
        })
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={2.5} md={1} style={{ textAlign: 'center' }}>
                    <img src={Frame} width={50} style={{ marginRight: '25px' }} />
                </Grid>
                <Grid item xs={3.5} md={8} textAlign={'left'}>
                </Grid>
                <Grid item xs={6} md={3}>
                    <div style={{
                        width: '100%', height: '50px',
                        backgroundColor: 'white'
                        , display: 'flex'
                        , borderRadius: '34px'
                    }}>
                        <Grid container alignItems={'center'} spacing={1}>
                            <Grid item xs={2}>
                                <div className='flex-centered'>
                                    <img src={EmployeeLogo} className='image-fit-cover' width={40} height={40} />
                                </div>
                            </Grid>
                            <Grid item xs={6} md={5.5} textAlign={'left'}>
                                <div className='vertical-flex'>
                                    <span className='colored-text' style={{ fontSize: '6px' }}>
                                        Lebanon
                                    </span>
                                    <span style={{ fontSize: '12px' }}>
                                        Test User
                                    </span>
                                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                                        Client
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <SettingsOutlinedIcon style={{ color: '#650E3D' }} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={logout}><LogoutOutlinedIcon style={{ color: '#650E3D', cursor: 'pointer' }} /></div>
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default CustomClientHeader