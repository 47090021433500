import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";


const CustomDialog = (props)=>{
    return(
        <Dialog
            maxWidth={props.maxWidth ? props.maxWidth : 'md'}
            fullWidth
            // fullScreen
            open={props.open}
            onClose={props.onClose}
            // BackdropProps={{ onClick: props.onClose }}
        >
            <DialogContent>
                {props.children}
            </DialogContent>
        </Dialog>
    )
}
export default CustomDialog