import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocationSVG from 'src/images/location.svg'
import CustomTabs from 'src/components/Custom/CustomTabs';
import moment from 'moment';
import CasesTable from './CasesTable';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import TasksTable from './TasksTable';

const EmployeeDetails = props => {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [tabSelected, setTabSelected] = useState(0);
    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
    }
    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/companyEmployees/getById`, {
            params: {
                company_id: id,
                id: props.id,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        if (props.id > 0 && props.show == true) {
            getData();
        }
    }, [props.id,props.show]);
    return (
        <div style={{
            height: '100%'
            , display: 'flex'
            , overflow: 'auto'
            , flexDirection: 'column'
        }}>
            <div style={{ backgroundColor: '#ffffff', minHeight: '100px', paddingTop: '25px', paddingBottom: '25px' }}>
                <div style={{
                    width: '94%'
                    , height: '94%'
                    , marginLeft: 'auto'
                    , marginRight: 'auto'
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <div style={{ color: '#650E3D', cursor: 'pointer' }} onClick={props.onClick}>
                                {'< '}{t('Back')}
                            </div>
                        </Grid>
                        {
                            isLoading ?
                                <CircularProgress />
                                :
                                <>
                                    <Grid item xs={12} md={4}>
                                        <div style={{
                                            height: '60px'
                                            , display: 'flex'
                                            , alignItems: 'center'
                                        }}>
                                            <img src={'https://symbols.getvecta.com/stencil_198/24_black-small-square.3fb8f5251a.svg'}
                                                width={50} style={{ borderRadius: '25px' }} />
                                        </div>
                                        <div className='d-flex  align-items-center'>
                                            <img src={LocationSVG} width={8} />
                                            <span style={{
                                                fontWeight: '400'
                                                , fontSize: '11px'
                                                , marginLeft: '5px'
                                                , color: '#650E3D'
                                            }}>{data?.nationality_name} </span>
                                        </div>
                                        <div style={{ lineHeight: '15px', fontWeight: '700', fontSize: '16px', color: '#650E3D' }}>
                                            {data?.full_name}
                                        </div>
                                        <div style={{ lineHeight: '15px', fontWeight: '900', fontSize: '12px', color: 'rgba(87, 87, 87, 1)' }}>
                                            {t('active_at')} {moment(data?.last_login_date).format('h:mm A')}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div style={{
                                            height: '44%'
                                            , backgroundColor: 'rgba(248, 248, 248, 1)'
                                            , display: 'flex'
                                            , flexDirection: 'column'
                                            , lineHeight: '17px'
                                            , justifyContent: 'center'
                                            , color: '#650E3D'
                                        }}>
                                            <span style={{ marginLeft: '20px' }}>{t('Total')} {t('Invoiced')}</span>
                                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{data?.total_invoices} USD</span>
                                        </div>
                                        <div style={{
                                            height: '44%'
                                            , backgroundColor: 'rgba(248, 248, 248, 1)'
                                            , marginTop: '5px'
                                            , display: 'flex'
                                            , flexDirection: 'column'
                                            , lineHeight: '17px'
                                            , justifyContent: 'center'
                                            , color: '#650E3D'
                                        }}>
                                            <span style={{ marginLeft: '20px' }}>{t('Total')} {t('Recieved')}</span>
                                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{data?.total_payments} USD</span>
                                        </div>
                                    </Grid>
                                </>
                        }
                    </Grid>
                </div>
            </div>
            <div style={{ height: '60%' }}>
                <CustomTabs
                    tabs={[
                        {
                            key: 0, label: t('Cases'),display:true
                        },
                        {
                            key: 1, label: t('Tasks'),display:true
                        },
                        {
                            key: 1, label: t('Financials'),display:true
                        },
                    ]} tabValue={tabSelected} onTabChange={onTabChange} />
                {
                    tabSelected === 0 ?
                        <div style={{ height: '100%', position: 'relative' }}>
                            <CasesTable id={props.id} />
                        </div>
                        : tabSelected === 1 ?
                            <div style={{ height: '100%', position: 'relative' }}>
                                <TasksTable id={props.id} />
                            </div>
                            : null
                }
            </div>
        </div>
    )
}
export default EmployeeDetails