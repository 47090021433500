import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import handelErrors from 'src/components/helpers/handelErrors';
import CustomButton from 'src/components/Custom/CustomButton';
import SingleCustomAutocompleteServerSide from 'src/components/Custom/SingleCustomAutocompleteServerSide';
import { useTranslation } from 'react-i18next';
import { CustomCheckbox, CustomInput } from 'src/components/Custom/CustomInputForms';
import { useParams } from 'react-router-dom';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomFileButton from 'src/components/Custom/CustomFileButton';
import { getFileSize } from 'src/components/helpers/Functions';

const AddFiles = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [currencies, setCurrencies] = useState([]);
    let { id } = useParams();
    const fileInputRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState({
        count: 0,
        size: 0,
    });

    const [formData, setFormData] = useState({
        id: -1,
        company_id: null,
        case_id: null,
        parent_firm_folder_id: null,
        files: [],
        fees: '',
        currency: null,
    });
    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: props.case_id,
            parent_firm_folder_id: props.parent_firm_folder_id,
            files: [],
            fees: '',
            currency: null,
        });
        
        setSelectedFileName({
            count: 0,
            size: 0,
        })
    }

    const handleChooseFile = () => {
        fileInputRef.current.click();
    };
    useEffect(() => {
        if (currencies.length == 0) {
            const getCurrencies = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/currencies/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCurrencies(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCurrencies();
        }
        if (props.open) {
            resetForm();
        }
    }, [props.open]);

    const saveForm = () => {
        setSaveIsLoading(true);
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            if (value && value !== "" && value !== null) {
                if (key == "files") {
                    formData.files.map((e) => {
                        formDataObj.append('files[]', e);
                    });
                } else {
                    formDataObj.append(key, value);
                }
            }
        }

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/files/addFile`,
            data: formDataObj,
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('insert_mssg') });
                setSaveIsLoading(false);
                props.onClose(true);
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    }

    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const handleChangeFile = (e) => {
        e.preventDefault()
        let uploadedFiles = e.target.files;
        console.log(uploadedFiles);
        if (uploadedFiles) {
            setFormData({
                ...formData,
                files: [...uploadedFiles]
            });
            let uploadedFilesCount = 0;
            let uploadedFilesSize = 0;
            Object.keys(uploadedFiles).forEach(key => {
                uploadedFilesCount++;
                uploadedFilesSize += (uploadedFiles[key].size / 1000);
            });
            setSelectedFileName({
                count: uploadedFilesCount,
                size: uploadedFilesSize,
            })
        }
        else {
            setSelectedFileName({
                count: 0,
                size: 0,
            })
        }
        return
    }
    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }

    return (
        <Dialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-2' style={{ color: '#650E3D' }}>{t('Add')} {t('Files')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <CustomInput
                            id={'fees'}
                            value={formData.fees}
                            onChange={handleInputChange}
                            placeholder={t('fees')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <SingleCustomAutocomplete
                            filedName="currency"
                            label={t('Currency')}
                            list={currencies}
                            value={formData.currency}
                            listKey="code"
                            description="code"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    {
                        props.id > 0
                            ? null
                            :
                            // <Grid item xs={12}>
                            //     <input type="file" name={'Files'} id="files" multiple onChange={(e) => handleChangeFile(e)} />
                            // </Grid>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} multiple name={t('attachement')} id="attachement" onChange={(e) => handleChangeFile(e)} />
                                <div className='d-flex'>
                                    <CustomFileButton onClick={handleChooseFile} />
                                    <div className='d-flex flex-column'>
                                        <span style={{ marginTop:'10px',marginRight:'5px',marginLeft: '10px', color: '#650E3D' }}>{selectedFileName.count > 0 ? selectedFileName.count : t('upload_files')}</span>
                                        <span style={{ marginLeft: '10px', fontWeight: '700', color: '#650E3D' }}>{selectedFileName.size > 0 ? getFileSize(selectedFileName.size) : ''}</span>
                                    </div>
                                </div>
                            </Grid>
                    }

                    <Grid item xs={12} marginTop={"50px"}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
AddFiles.propTypes = {
    open: PropTypes.any.isRequired,
    categoryName: PropTypes.any,
    id: PropTypes.any,
    onClose: PropTypes.func.isRequired,
};
export default AddFiles