import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import { Link } from "react-router-dom";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";
import { useTranslation } from "react-i18next";
import gregorian from "react-date-object/calendars/gregorian";

const CustomDates = props => {
    const [selected, setSelected] = useState(null);
    const datesToColor = props.data ? props.data : [];
    const { t, i18n } = useTranslation();

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const onChangeDate = (selectedDates) => {
        props.onChangeDate(selectedDates);
        setSelected(selectedDates);
    }

    const handleYearChange = (e) => {
        props.handleYearChange(e.year);
    };

    const handleMonthChange = (e) => {
        props.handleMonthChange(e.month.number);
    };

    const renderDay = (date) => {
        const isHighlighted = datesToColor.includes(date.format('YYYY-MM-DD'));
        let props = {};

        if (isHighlighted) {
            props.style = {
                ...props.style,
                background: "rgba(147, 135, 167, 0.5)",
                pointerEvents: "auto",
            };
        }
        return props;
    };
    const customLocale = {
        months: [
          [t("January"), t("Jan")],
          [t("February"), t("Feb")],
          [t("March"), t("Mar")],
          [t("April"), t("Apr")],
          [t("May"), t("May")],
          [t("June"), t("Jun")],
          [t("July"), t("Jul")],
          [t("August"), t("Aug")],
          [t("September"), t("Sep")],
          [t("October"), t("Oct")],
          [t("November"), t("Nov")],
          [t("December"), t("Dec")],
        ],
        weekDays: [
          [t("Saturday"), t("Sat")],
          [t("Sunday"), t("Sun")],
          [t("Monday"), t("Mon")],
          [t("Tuesday"), t("Tue")],
          [t("Wednesday"), t("Wed")],
          [t("Thursday"), t("Thu")],
          [t("Friday"), t("Fri")],
        ],
        digits: [
          t("0"),
          t("1"),
          t("2"),
          t("3"),
          t("4"),
          t("5"),
          t("6"),
          t("7"),
          t("8"),
          t("9"),
        ],
        meridiems: [
          [t("AM"), t("am")],
          [t("PM"), t("pm")],
        ],
    };

    return (
        <Calendar
            value={selected ? selected : null}
            onChange={(dates) => onChangeDate(dates)}
            onYearChange={handleYearChange}
            onMonthChange={handleMonthChange}
            // multiple
            style={{ boxShadow: 'none' }}
            // calendar={i18n. arabic}
            locale={customLocale}
            color={"red"}
            mapDays={({ date }) => renderDay(date)}
        // range
        // rangeHover
        />
    )
}

export default CustomDates