import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import CustomDialog from '../../components/Custom/CustomDialog';
import styles from '../../styles/login.module.css';
import { useParams } from 'react-router-dom'
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import AddIcon from '@mui/icons-material/Add'
import SearchSVG from 'src/images/search_svg.svg'
import NotificationFilledSVG from 'src/images/notification_filled_svg.svg';
import NotificationSVG from 'src/images/notification_svg.svg';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import handelErrors from 'src/components/helpers/handelErrors';
import axios from 'axios';
import moment from 'moment';
import AddUpdateMeeting from './AddUpdateMeeting';
import CustomBigCalendar from 'src/components/Custom/CustomBigCalendar';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import { useTranslation } from 'react-i18next';
import AddReminder from './AddReminder';

const Meetings = (props) => {
    let { id, caseID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [events, setEvents] = useState([]);
    const [date, setDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const { t, i18n } = useTranslation();
    const [selectedID, setSelectedID] = useState('');
    const [openReminder, setOpenReminder] = useState(false);

    const getData = (date) => {
        let filterDate = date != null ? date : `${moment(props.filterForm.fromDate).format('YYYY-MM-DD')}`;
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/meetings/get`, {
            params: {
                fromDate: filterDate ? filterDate : null,
                // toDate: props.filterForm.toDate ? `${moment(props.filterForm.toDate).format('YYYY-MM-DD')}`:null,
                company_id: id,
                case_id: caseID,
            }
        })
            .then(res => {
                setIsLoading(false);
                const data = res.data;
                setData(res.data);
                const arr = [];
                data.map((e) => {
                    arr.push({
                        id: e.id,
                        title: e.title,
                        start: new Date(e.start),
                        end: new Date(e.end),
                        desc: e.description
                    });
                });
                console.log(arr);
                setEvents(arr);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }

    useEffect(() => {
        if (props.open) {
            getData();
        }
    }, [props.open]);

    const addForm = () => {
        setSelectedID('');
        setOpen(true);
    }
    const handleClose = (refresh = false) => {
        setOpen(false);
        if (refresh) {
            getData(date);
        }
    }

    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'reminder',
            label: 'Reminder',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: 'Name'
        }
        , {
            name: 'description',
            label: 'Description'
        },
        {
            name: 'type',
            label: 'Type'
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            <Tooltip title={t('Reminder')}>
                                <IconButton onClick={() => addReminder(meta)}>
                                    {
                                        meta.rowData[1] > 0 ? <img src={NotificationFilledSVG} width={20} /> : <img src={NotificationSVG} width={20} />
                                    }
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                },
            },
        },
    ];

    const options = {
        filter: false,
        print: false,
        download: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (<span>
                {/* <Tooltip title={"Add"}>
                    <IconButton onClick={addForm}>
                        <AddIcon style={{ color: '#650E3D' }} />
                    </IconButton>
                </Tooltip> */}
                <span style={{ marginLeft: '10px' }}><CustomAddButton onClick={addForm} title={'New Meeting'} /></span>
            </span>);
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    'There is no matching data to display',
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };
    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const handleNavigate = (date, view) => {
        setDate(moment(date).format('YYYY-MM-DD'));
        getData(moment(date).format('YYYY-MM-DD'));
    }
    const handleSelectSlot = ({ start, end }) => {
        setSelectedID('');
        setOpen(true);
        setSelectedDate(start);
    }

    const addReminder = (meta) => {
        setSelectedID(meta.rowData[0]);
        setOpenReminder(true);
    }
    const handleCloseReminder = (refresh) => {
        setOpenReminder(false);
        if (refresh == true) {
            getData();
        }
    }
    const handleSelectEvent=(e)=>{
        setSelectedID(e.id);
        setOpen(true);
    }
    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={'md'}
        >
            <div className={styles.signupForm}>
                {/* <p className={styles.text}>Meetings</p> */}
                <Grid container spacing={3}>
                    {/* <Grid item xs={12}>
                        <CustomDataTable
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </Grid> */}
                    <Grid xs={12} marginTop={5}>
                        {
                            // isLoading ? 
                            // <CircularProgress />
                            // : 
                            <CustomBigCalendar
                                events={events}
                                handleNavigate={handleNavigate}
                                defaultDate={props.filterForm.fromDate}
                                handleSelectSlot={handleSelectSlot}
                                handleSelectEvent={handleSelectEvent}
                            />
                        }
                    </Grid>
                </Grid>
                <AddUpdateMeeting
                    open={open}
                    onClose={handleClose}
                    selectedDate={selectedDate}
                    id={selectedID}
                />
            </div>
        </CustomDialog>
    )
}
export default Meetings