import React, { useEffect, useState } from 'react'
import { Grid, Popover } from '@mui/material';
import OptionsSVG from 'src/images/options_vertical.svg';
import LocationSVG from 'src/images/location.svg'
import moment from 'moment';
import { MoreVert } from '@mui/icons-material';
import EditSVG from 'src/images/edit_svg.svg'
import DeleteSVG from 'src/images/carbon_delete.svg'
import { useTranslation } from 'react-i18next';

const CustomPopover = (props) => {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <div>
            <MoreVert style={{ color: '#650E3D', fontSize: "1.2em", cursor: "pointer" }}
                onClick={handleClick}
            />
            <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="p-3"
                    style={{
                        borderRadius: "15px"
                    }}
                >
                    <div className='w-100 d-flex' style={{ cursor: 'pointer' }}
                        >
                        <div className='w-30 d-flex justify-content-center'>
                            <img src={EditSVG} width={17} />
                        </div>
                        <div className='w-70 d-flex justify-content-center'
                            style={{
                                marginLeft: '10px'
                                , color: '#650E3D'
                                , fontWeight: '700'
                                , fontSize: '14px'
                            }}
                            onClick={props.handleEdit}
                        >
                            {t('Edit')}
                        </div>
                    </div>
                    <div className='w-100 d-flex' style={{ cursor: 'pointer' }}
                        >
                        <div className='w-30 d-flex justify-content-center'>
                            <img src={DeleteSVG} width={18} />
                        </div>
                        <div className='w-70 d-flex justify-content-center'
                            style={{
                                marginLeft: '10px'
                                , color: '#650E3D'
                                , fontWeight: '700'
                                , fontSize: '14px'
                            }}
                        >
                            {t('Delete')}
                        </div>
                    </div>
                </div>

            </Popover>
        </div>
    )
}

const EmployeeCard = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="shadow-on-hover" style={{
            backgroundColor: '#ffffff'
            , minHeight: '184px', borderRadius: '10px'
            , display: 'flex', justifyContent: 'center'
            , alignItems: 'center', cursor: 'pointer'
        }} onDoubleClick={props.onClick}>
            <Grid container style={{ width: '90%' }}>
                <Grid item xs={11}>
                    <div style={{
                        height: '80px'
                        , display: 'flex'
                        , alignItems: 'center'
                    }}>
                        <img src={'https://symbols.getvecta.com/stencil_198/24_black-small-square.3fb8f5251a.svg'}
                            width={50} style={{ borderRadius: '25px' }} />
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div style={{ height: '50px', width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <CustomPopover
                            id={props.data?.id}
                            handleEdit={props.handleEdit}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='d-flex  align-items-center'>
                        <img src={LocationSVG} width={8} />
                        <span style={{
                            fontWeight: '400'
                            , fontSize: '11px'
                            , marginLeft: '5px'
                            , color: '#650E3D'
                        }}>{props.data?.nationality_name} </span>
                    </div>
                    <div style={{ lineHeight: '15px', fontWeight: '700', fontSize: '16px', color: '#650E3D' }}>
                        {props.data?.full_name}
                    </div>
                    <div style={{ lineHeight: '15px', fontWeight: '900', fontSize: '12px', color: 'rgba(87, 87, 87, 1)' }}>
                        {t('active_at')} {moment(props.data?.last_login_date).format("h:mm A")}
                    </div>
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: 'rgba(248, 248, 248, 1)', minHeight: '40px' }}>
                    <div className='d-flex flex-column' style={{ marginTop: '5px', marginLeft: '5px' }}>
                        <span style={{ fontSize: '10px', fontWeight: '700' }}>{props.data?.total_cases} {t('Cases')}</span>
                        <span style={{ fontSize: '11px', fontWeight: '700', lineHeight: '10px' }}>{props.data?.grouped_statuses}</span>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default EmployeeCard