import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { Popper, TextField } from '@mui/material'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

const CustomAutocomplete = (props) => {
  const { t, i18n } = useTranslation();

  const filedName = props.filedName
  const fieldLabel = props.label
  const list = props.list
  const fieldValues = props.values
  const listKey = props.listKey
  const listDescription = props.description
  const customOnChange = props.customOnChange

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const rtl = i18n.language.includes("en")
    ? {}
    : {
      // transformOrigin: "right !important",
      left: "inherit !important",
      right: "2rem !important",
      overflow: "unset",
    };

  const rtlIndicator = i18n.language == "ar" ?
    {
      left: " 1rem !important",
      right: "inherit !important",
      overflow: "unset",
    }
    :
    {};

  const rtlRoot = i18n.language == "ar" ?
    {
      paddingRight: '1rem !important',
    }
    : {};

  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
              border: 'none',
              backgroundColor: '#F4F4F4',
              fontFamily: 'Poppins, sans-serif',
              height: '50px',
              ...rtlRoot,
              '& input': {
                color: '#66023C',
                // direction: 'rtl',
                // textAlign: 'right',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                // borderColor: '#66023C',
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#66023C',
                border: 'none'
              },
              '& .MuiAutocomplete-popupIndicator': {
                color: '#66023C',
              },
              '& .MuiAutocomplete-endAdornment': {
                ...rtlIndicator,
              }
            },
          },
          popper: {
            '& .MuiAutocomplete-option': {
              color: '#66023C',
              // direction: 'rtl',
              // textAlign: 'right',
            },
          },
        },
      },
      MuiTextField: { // Add this section to override TextField styles
        styleOverrides: {
          root: {
            '& label': {
              color: '#66023C',
              fontWeight: 'bold',
              ...rtl,
            },
            '& label.Mui-focused': {
              color: '#66023C',
              // display:'none'
            },
          },
        },
      },
    },
  });
  return (
    <div style={{ position: 'relative', marginTop: '10px', marginBottom: '10px' }}>
      <ThemeProvider theme={theme}>
        <Autocomplete
          // disableCloseOnSelect={true}
          name={filedName}
          options={list} // Options List
          value={fieldValues}
          // getOptionSelected={(option, value) => value == option[listKey]}
          disabled={props.disabled ? props.disabled : false}
          multiple
          onChange={(event, newValue) => {
            const newList = newValue ? newValue.map((v) => (v[listKey] ? v[listKey] : v)) : []
            customOnChange(newList, filedName, event, newValue)
          }}
          disablePortal
          getOptionLabel={(option) => option[listDescription]}
          renderInput={(params) => <TextField
            {...params}
            // InputProps={{
            //   ...params.InputProps,
            //   onFocus:handleOpen
            // }}
            name={filedName}
            // variant='standard' 
            label={fieldLabel} />}
          // PopperComponent={({ children })=>(
          //   <Popper open={Boolean(anchorEl)} ref={anchorEl} anchorEl={anchorEl} sx={{zIndex: (theme) => theme.zIndex.modal + 1}}>
          //       {children}
          //   </Popper>
          // )}

          onClose={handleClose}
          renderTags={(tagValue, getTagProps) => {
            const fillteredList = list.filter(
              (v) => tagValue.includes(v[listKey]) || tagValue.includes(`${v[listKey]}`),
            )
            // return (<div>
            //   {fillteredList.map((value) => (
            //     <Chip
            //       key={value[listKey]}
            //       label={value[listDescription]}
            //     />
            //   ))}
            // </div>)
            return (
              <div>
                {fillteredList.map((value, index) => (
                  <Chip
                    key={value[listKey]}
                    label={value[listDescription]}
                    {...getTagProps({ index })}
                  />
                ))}
              </div>
            )
          }}
        />
      </ThemeProvider>
    </div>
  )
}
CustomAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any.isRequired,
  disabled: PropTypes.any,
}

export default CustomAutocomplete
