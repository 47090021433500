import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import PropTypes from 'prop-types'
import { Calendar, Views, dayjsLocalizer, DateLocalizer, momentLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import moment from 'moment'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

const localizer = dayjsLocalizer(dayjs)
// moment.locale("customLocale");
const mLocalizer = momentLocalizer(moment)

const event = [
    {
        id: 1,
        title: 'DST starts on this day (Europe)',
        start: new Date('2023-06-13 12:00:00'),
        end: new Date('2023-06-13 1:00:00'),
        desc: "testt"
    },
];
const CustomBigCalendar = (props) => {
    const { t, i18n } = useTranslation();

    const { defaultDate, views } = useMemo(
        () => ({
            defaultDate: props.defaultDate !=null ? props.defaultDate :  new Date(),
            views: [Views.WORK_WEEK,Views.MONTH,Views.DAY,Views.WEEK],
        }),
        []
    )
    // const handleSelectSlot =({start,end})=>{
    //     props.handleSelectSlot(start);
    // }
    // const customLocale = {
    //     months: [
    //       [t("January"), t("Jan")],
    //       [t("February"), t("Feb")],
    //       [t("March"), t("Mar")],
    //       [t("April"), t("Apr")],
    //       [t("May"), t("May")],
    //       [t("June"), t("Jun")],
    //       [t("July"), t("Jul")],
    //       [t("August"), t("Aug")],
    //       [t("September"), t("Sep")],
    //       [t("October"), t("Oct")],
    //       [t("November"), t("Nov")],
    //       [t("December"), t("Dec")],
    //     ],
    //     weekDays: [
    //       [t("Saturday"), t("Sat")],
    //       [t("Sunday"), t("Sun")],
    //       [t("Monday"), t("Mon")],
    //       [t("Tuesday"), t("Tue")],
    //       [t("Wednesday"), t("Wed")],
    //       [t("Thursday"), t("Thu")],
    //       [t("Friday"), t("Fri")],
    //     ],
    //     digits: [
    //       t("0"),
    //       t("1"),
    //       t("2"),
    //       t("3"),
    //       t("4"),
    //       t("5"),
    //       t("6"),
    //       t("7"),
    //       t("8"),
    //       t("9"),
    //     ],
    //     meridiems: [
    //       [t("AM"), t("am")],
    //       [t("PM"), t("pm")],
    //     ],
    // };

    const customMessages ={
        today:t('Today'),
        next:t('Next'),
        previous:t('Previous'),
        day:t('Day'),
        work_week:t('work_week'),
        month:t('Month'),
        week:t('Week'),
    }
    const customFormats = {
        dayFormat: (date, culture, localizer) => localizer.format(date, "ddd, MMM D"),
        agendaDateFormat: (date, culture, localizer) => localizer.format(date, "ddd, MMM D"),
        agendaTimeFormat: (date, culture, localizer) => localizer.format(date, "h:mm A"),
        agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
          `${localizer.format(start, "h:mm A")} - ${localizer.format(end, "h:mm A")}`,
      };
    return (
        <div>
            <Calendar
                // messages={{
                    // today:t('Today'),
                    // next:t('Next'),
                    // previous:t('Previous'),
                    // day:t('Day'),
                    // work_week:t('work_week'),
                    // month:t('Month'),
                    // week:t('Week'),
                // }}
                localizer={mLocalizer}
                messages={customMessages}
                events={props.events}
                defaultDate={defaultDate}
                defaultView={Views.WORK_WEEK}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, width: '100%' }}
                onNavigate={props.handleNavigate}
                selectable
                onSelectSlot={props.handleSelectSlot}
                onSelectEvent={props.handleSelectEvent}
                // showMultiDayTimes
                views={views}
            />
        </div>
    )
}
export default CustomBigCalendar

CustomBigCalendar.propTypes = {
    events: PropTypes.any.isRequired,
}