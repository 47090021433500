import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomInput, SingleDate } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CustomAutocompleteServerSide from 'src/components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { statuses } from 'src/components/helpers/FixedData';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';
import { getDescription } from 'src/components/helpers/Functions';


const AddUpdateSubTasks = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [taskStatuses, setTasksStatuses] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID,
        task_id: props.taskID,
        name: '',
        description: '',
        assign_to: [],
        due_date: null,
        status: null,
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID,
            task_id: props.taskID,
            name: '',
            description: '',
            assign_to: [],
            due_date: null,
            status: null,
        });
    }


    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/getSubTaskById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: caseID,
                    name: response.data.name,
                    task_id: props.taskID,
                    status: response.data.status,
                    description: response.data.description,
                    status: response.data.status,
                    assign_to: response.data.assign_to ? response.data.assign_to.split(',') : [],
                    due_date: response.data.due_date ? new Date(response.data.due_date) : null,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open && taskStatuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/TaskStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setTasksStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        // setSaveIsLoading(true);
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            if (value && value !== "" && value !== null) {
                if (Array.isArray(value)) {
                    let arr = [];
                    value.forEach(element => {
                        arr.push(element);
                        // formDataObj.append(`${key}[]`, element);
                    });
                    formDataObj.append(`${key}`, JSON.stringify(arr));

                }
                else {
                    if (key == "due_date") {
                        formDataObj.append(key, moment(value).format('YYYY-MM-DD'));
                    } else {
                        formDataObj.append(key, value);
                    }
                }
            }
        }
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/updateSubTask`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('updated_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/addSubTask`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('insert_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };
    return (
        <CustomNewDialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
            >
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('SubTask')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'description'}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={t('Description')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomAutocompleteServerSide
                            filedName="assign_to"
                            label={t('assign_to')}
                            values={formData.assign_to}
                            listKey="user_id"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={"/masterData/lawyers/getDataBySearchQuery"}
                            inTeam={true}
                            companyID={id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="status"
                            label={t('Status')}
                            list={taskStatuses}
                            value={formData.status}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleDate
                            id="due_date"
                            label={t('due_date')}
                            value={formData.due_date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"} style={{ marginBottom: '2%' }}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </CustomNewDialog>
    )
}
export default AddUpdateSubTasks