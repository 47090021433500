import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomTabs from 'src/components/Custom/CustomTabs';
import Tracking from './Tracking/Tracking';
import TimeSheetReport from './Report/TimeSheetReport';

const TimeSheet = () => {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const [tabSelected, setTabSelected] = useState(0);
    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
    }
    const presetPagesCodes = JSON.parse(localStorage.getItem("preset_pages_codes"));

    return (
        <div>
            <CustomTabs
                tabs={[
                    {
                        key: 0, label: t('Tracking'),display:presetPagesCodes.includes('timesheet_tracking') ? true : false
                    },
                    {
                        // key: 1, label: t('Accounts&Permissions'),display:presetPagesCodes.includes('company_employee_permissions') ? true : false
                        key: 1, label: t('Report'),display:presetPagesCodes.includes('timesheet_report') ? true :false,
                    },
                ]} tabValue={tabSelected} onTabChange={onTabChange} />
            {
                tabSelected === 0 ?
                    <Tracking />
                    :
                    tabSelected === 1 ?
                        <TimeSheetReport />
                        : null
            }
        </div>
    )
}
export default TimeSheet