import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomInput, SingleDate, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BankSVG from 'src/images/bank_svg.svg';
import LitigationSVG from 'src/images/litigation_svg.svg';
import Avatar from 'src/images/avatar_without_svg.svg';
import RowEdit from 'src/images/row_edit_svg.svg'
import moment from 'moment';
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import SingleFile from 'src/images/single_file_svg.svg';
import DownloadSvg from 'src/images/download_svg.svg';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import NotificationFilledSVG from 'src/images/notification_filled_svg.svg';
import NotificationSVG from 'src/images/notification_svg.svg';
import SearchSVG from 'src/images/search_svg.svg'
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import UpdateSummary from './UpdateSummary';
import AddUpdateNote from './AddUpdateNote';
import AddFile from './AddFile';
import AddReminder from './AddReminder';

const CustomFile = (props) => {

    const downloadFile = (path) => {
        window.open(`${process.env.REACT_APP_API_BASE_URL_FILE}/CompanyFiles/${path}`, '_blank').focus();
    }
    return (
        <div style={{
            display: 'flex', width: '100%', color: '#650E3D'
            , height: '40px'
            , alignItems: 'center'
        }}>
            <img src={SingleFile} />
            <div style={{
                height: '100%'
                , alignItems: 'center'
                , display: 'flex'
                , marginLeft: '20px'
                , fontWeight: '700'
                , width: '90%'
            }}>
                {props.data.visible_name}
            </div>
            <div><img src={DownloadSvg} style={{cursor:'pointer'}} onClick={()=>downloadFile(props.data.path_name)}/></div>
        </div>
    )
}

const InfoForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [openReminder, setOpenReminder] = useState(false);
    const [data, setData] = useState([]);
    const [notesData, setNotesData] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    const [rescheduleData, setRescheduleData] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/getInfo-${props.id}`,
        })
            .then(response => {
                setData(response.data.data);
                setRescheduleData(response.data.rescheduled_data);
                setNotesData(response.data.notes);
                setFilesData(response.data.files);
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            getFormData();
        }
    }, [props.open]);

    const handleEditSummary = () => {
        setOpenSummary(true);
    }
    const handleCloseSummary = (refresh) => {
        setOpenSummary(false);
        if (refresh) {
            getFormData();
        }
    }

    const columns = [
        {
            name: 'date & time',
            label: t('Datetime'),
            options: {
                filter: false,
                // sort: false,
                customBodyRenderLite: (dataIndex) => {
                    // console.log(data[dataIndex].id);
                    return (
                        <div style={{ width: '140px' }}>
                            <div style={{ fontSize: '14px', fontWeight: '700' }}>From</div>
                            {moment(rescheduleData[dataIndex].from_date).format('DD/MM/YYYY-hh:mm A')}
                            <div style={{ fontSize: '14px', fontWeight: '700' }}>To</div>
                            {moment(rescheduleData[dataIndex].to_date).format('DD/MM/YYYY-hh:mm A')}
                        </div>
                    )
                }
            }
        },
        {
            name: 'reason',
            label: t('Reason'),
        },
    ];

    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: false,
        search: false,
        rowsPerPage: 2,
        fixedHeader:false,
        rowsPerPageOptions: [2],
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e)=>handleSearchBar(e,handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };
    const handleSearchBar = (event,onSearch)=>{
        onSearch(event.target.value)
    }
    const addNote = () => {
        setSelectedID(-1);
        setOpenNotes(true);
    }
    const handleEditNote = (id) => {
        setSelectedID(id);
        setOpenNotes(true);
    }
    const handleCloseNote = (refresh) => {
        setOpenNotes(false);
        if (refresh) {
            getFormData();
        }
    }
    const addFile = () => {
        setOpenFile(true);
    }
    const handleCloseFile = (refresh) => {
        setOpenFile(false);
        if (refresh) {
            getFormData();
        }
    }
    const addReminder = () => {
        setOpenReminder(true);
    }
    const handleCloseReminder = (refresh) => {
        setOpenReminder(false);
    }
    return (
        <Dialog
            maxWidth={'md'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
            scroll='body'
        >
            <DialogContent style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div style={{ width: '100%',display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex',flexDirection: 'column', lineHeight: '22px' }}>
                        <div >
                            <span style={{float:i18n.language == "ar" ?'right' : 'left',fontSize: '1em', fontWeight: '700', color: '#650E3D' }}>
                            {t('Hearing')} #{data?.id}
                            </span>
                        </div>
                        <div style={{position:'relative'}}>
                            <span style={{ fontSize: '2em', fontWeight: '300', color: '#650E3D' }}>
                                {t('Hearing')} {t('name')}
                                {data?.name}
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '18px' }}>
                            <span style={{ color: '#650E3D', fontSize: '0.8em', textTransform: 'uppercase', textAlign: 'right' }}>
                                {t('current')} {t('Hearing')} {t('Date')}
                            </span>
                            <span style={{ color: '#650E3D', fontSize: '0.9em', fontWeight: '700', textAlign: 'right' }}>
                                {data.date != null ? moment(data.date).format('DD/MM/YYYY') : ''}
                            </span>
                            <span style={{ color: '#650E3D', fontSize: '0.9em', fontWeight: '700', textAlign: 'right' }}>
                                {data.date != null ? moment(data.date).format('hh:mm A') : ''}
                            </span>
                            <span style={{ color: '#650E3D', fontSize: '0.8em', textAlign: 'right', textTransform: 'uppercase' }}>
                                {data?.status_name}
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '20px' }}>
                            {
                                data?.reminder > 0
                                ?
                                    <img src={NotificationFilledSVG} style={{ cursor: 'pointer' }} onClick={addReminder}/>
                                :
                                    <img src={NotificationSVG} style={{ cursor: 'pointer' }} onClick={addReminder}/>
                            }
                        </div>
                    </div>
                </div>
                <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={12} md={6}>
                        <div style={{
                            width: '100%'
                            , height: '230px'
                            , borderRadius: '16px'
                            , backgroundColor: 'rgba(101, 14, 61, 0.04)'
                            , padding: '15px'
                        }}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{
                                    display: 'flex'
                                    , width: '50%'
                                }}>
                                    <div>
                                        <img width={28} src={BankSVG} />
                                    </div>
                                    <div style={{
                                        display: 'flex'
                                        , flexDirection: 'column'
                                        , lineHeight: '15px'
                                        , justifyContent: 'center'
                                        , marginLeft: '10px'
                                        , color: '#650E3D'
                                    }}>
                                        <span style={{ fontSize: '18px', fontWeight: '300' }}>{data.court}</span>
                                        <span style={{ fontSize: '12px', fontWeight: '700' }}>{data.manual_court_id}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '50%' }}>
                                    <img width={24} src={LitigationSVG} />
                                    <div style={{
                                        display: 'flex'
                                        , flexDirection: 'column'
                                        , lineHeight: '15px'
                                        , justifyContent: 'center'
                                        , marginLeft: '10px'
                                        , color: '#650E3D'
                                    }}>
                                        <span style={{ fontSize: '12px', fontWeight: '700' }}>{t('Judge')+' '+t('name')}</span>
                                        <span style={{ fontSize: '18px', fontWeight: '300' }}>{data.judge_name}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '15px', width: '100%' }}>
                                <div style={{ display: 'flex', width: '50%' }}>
                                    <img width={24} src={Avatar} />
                                    <div style={{
                                        display: 'flex'
                                        , flexDirection: 'column'
                                        , lineHeight: '15px'
                                        , justifyContent: 'center'
                                        , marginLeft: '10px'
                                        , color: '#650E3D'
                                    }}>
                                        <span style={{ fontSize: '18px', fontWeight: '300' }}>{t('Opponent')+' '+t('name')}</span>
                                        <span style={{ fontSize: '12px', fontWeight: '700' }}>{data.concat_name}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '50%' }}>
                                    <img width={24} src={Avatar} />
                                    <div style={{
                                        display: 'flex'
                                        , flexDirection: 'column'
                                        , lineHeight: '15px'
                                        , justifyContent: 'center'
                                        , marginLeft: '10px'
                                        , color: '#650E3D'
                                    }}>
                                        <span style={{ fontSize: '12px', fontWeight: '700' }}>{t('assign_to')}</span>
                                        <span style={{ fontSize: '18px', fontWeight: '300' }}>{data.assigned_concat_name}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '100%', marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#650E3D' }}>
                                    <span style={{ fontSize: '16px', fontWeight: '700', textTransform: 'uppercase' }}>{t('Summary')}</span>
                                    <span>
                                        <img src={RowEdit} width={22} style={{ cursor: 'pointer' }} onClick={handleEditSummary} />
                                    </span>
                                </div>
                                <div className='wrapped-text'>
                                    {data.summary}
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{
                            width: '100%'
                            , height: '260px'
                            , borderRadius: '16px'
                            , backgroundColor: 'rgba(101, 14, 61, 0.04)'
                            , padding: '15px'
                            , overflow: 'auto'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#650E3D' }}>
                                <span style={{ fontSize: '18px', textTransform: 'uppercase' }}>{t('Notes')}</span>
                                <span>
                                    <Tooltip title={t('Add')}>
                                        <IconButton onClick={addNote}>
                                            <AddIcon style={{ color: '#650E3D' }} />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            </div>
                            {
                                notesData.map((e) => (
                                    <div style={{ width: '98%', marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#650E3D' }}>
                                            <span style={{ fontSize: '16px', fontWeight: '700', textTransform: 'uppercase' }}>
                                                {moment(e.created_at).format('DD/MM/YYYY-hh:mm A')}
                                            </span>
                                            <span>
                                                <img src={RowEdit} width={22} style={{ cursor: 'pointer' }} onClick={() => handleEditNote(e.id)} />
                                            </span>
                                        </div>
                                        <div className='wrapped-text'>
                                            {e.note}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <p style={{ color: '#650E3D',fontSize: '30px' }}>{t('Rescheduling')}</p>
                        <div>
                            <CustomDataTable
                                data={rescheduleData}
                                columns={columns}
                                options={options}
                                style={
                                    {
                                        height: '88vh'
                                    }
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{
                            width: '100%'
                            , borderRadius: '16px'
                            , backgroundColor: 'rgba(101, 14, 61, 0.04)'
                            , padding: '25px'
                            , marginTop: '20px'
                        }}>
                            <h4 style={{ color: '#650E3D' }}>{t('Files')}</h4>
                            <div style={{
                                width: '100%'
                                , marginTop: '20px'
                                , display: 'flex'
                                , flexDirection: 'column'
                                , height: '240px'
                                , overflow: 'auto'
                            }}>
                                {
                                    filesData.map((e)=>(
                                        <CustomFile data={e}/>
                                    ))
                                }
                            </div>
                            <div style={{ width: '100%', marginTop: '10px' }}>
                                <CustomButton variant="contained" onClick={addFile} title={t('link_a_file')} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <UpdateSummary
                    open={openSummary}
                    id={props.id}
                    onClose={handleCloseSummary} />
                <AddUpdateNote
                    open={openNotes}
                    hearing_id={props.id}
                    id={selectedID}
                    onClose={handleCloseNote}
                />
                <AddFile
                    open={openFile}
                    id={props.id}
                    onClose={handleCloseFile}
                />
                <AddReminder
                    open={openReminder}
                    id={props.id}
                    onClose={handleCloseReminder}
                />
            </DialogContent>
        </Dialog>
    )
}
export default InfoForm