
export const statuses = JSON.parse(localStorage.getItem("statuses"));

export const AllCountries =[
{ id:"Afghanistan",prefix:"93"},
{ id:"Åland Islands",prefix:"358"},
{ id:"Albania",prefix:"355"},
{ id:"Algeria",prefix:"213"},
{ id:"American Samoa",prefix:"1"},
{ id:"Andorra",prefix:"376"},
{ id:"Angola",prefix:"244"},
{ id:"Anguilla",prefix:"1"},
{ id:"Antarctica",prefix:"672"},
{ id:"Antigua and Barbuda",prefix:"1"},
{ id:"Argentina",prefix:"54"},
{ id:"Armenia",prefix:"374"},
{ id:"Aruba",prefix:"297"},
{ id:"Australia",prefix:"61"},
{ id:"Austria",prefix:"43"},
{ id:"Azerbaijan",prefix:"994"},
{ id:"Bahamas",prefix:"1"},
{ id:"Bahrain",prefix:"973"},
{ id:"Bangladesh",prefix:"880"},
{ id:"Barbados",prefix:"1"},
{ id:"Belarus",prefix:"375"},
{ id:"Belgium",prefix:"32"},
{ id:"Belize",prefix:"501"},
{ id:"Benin",prefix:"229"},
{ id:"Bermuda",prefix:"1"},
{ id:"Bhutan",prefix:"975"},
{ id:"Bolivia (Plurinational State of)",prefix:"591"},
{ id:"Bonaire, Sint Eustatius and Saba",prefix:"599"},
{ id:"Bosnia and Herzegovina",prefix:"387"},
{ id:"Botswana",prefix:"267"},
{ id:"Bouvet Island",prefix:"47"},
{ id:"Brazil",prefix:"55"},
{ id:"British Indian Ocean Territory",prefix:"246"},
{ id:"United States Minor Outlying Islands",prefix:"246"},
{ id:"Virgin Islands (British)",prefix:"1"},
{ id:"Virgin Islands (U.S.) 1",prefix:"340"},
{ id:"Brunei Darussalam",prefix:"673"},
{ id:"Bulgaria",prefix:"359"},
{ id:"Burkina Faso",prefix:"226"},
{ id:"Burundi",prefix:"257"},
{ id:"Cambodia",prefix:"855"},
{ id:"Cameroon",prefix:"237"},
{ id:"Canada",prefix:"1"},
{ id:"Cabo Verde",prefix:"238"},
{ id:"Cayman Islands",prefix:"1"},
{ id:"Central African Republic",prefix:"236"},
{ id:"Chad",prefix:"235"},
{ id:"Chile",prefix:"56"},
{ id:"China",prefix:"86"},
{ id:"Christmas Island",prefix:"61"},
{ id:"Cocos (Keeling) Islands",prefix:"61"},
{ id:"Colombia",prefix:"57"},
{ id:"Comoros",prefix:"269"},
{ id:"Congo",prefix:"242"},
{ id:"Congo (Democratic Republic of the)",prefix:"243"},
{ id:"Cook Islands",prefix:"682"},
{ id:"Costa Rica",prefix:"506"},
{ id:"Croatia",prefix:"385"},
{ id:"Cuba",prefix:"53"},
{ id:"Curaçao",prefix:"599"},
{ id:"Cyprus",prefix:"357"},
{ id:"Czech Republic",prefix:"420"},
{ id:"Denmark",prefix:"45"},
{ id:"Djibouti",prefix:"253"},
{ id:"Dominica",prefix:"1"},
{ id:"Dominican Republic",prefix:"1"},
{ id:"Ecuador",prefix:"593"},
{ id:"Egypt",prefix:"20"},
{ id:"El Salvador",prefix:"503"},
{ id:"Equatorial Guinea",prefix:"240"},
{ id:"Eritrea",prefix:"291"},
{ id:"Estonia",prefix:"372"},
{ id:"Ethiopia",prefix:"251"},
{ id:"Falkland Islands (Malvinas)",prefix:"500"},
{ id:"Faroe Islands",prefix:"298"},
{ id:"Fiji",prefix:"679"},
{ id:"Finland",prefix:"358"},
{ id:"France",prefix:"33"},
{ id:"French Guiana",prefix:"594"},
{ id:"French Polynesia",prefix:"689"},
{ id:"French Southern Territories",prefix:"262"},
{ id:"Gabon",prefix:"241"},
{ id:"Gambia",prefix:"220"},
{ id:"Georgia",prefix:"995"},
{ id:"Germany",prefix:"49"},
{ id:"Ghana",prefix:"233"},
{ id:"Gibraltar",prefix:"350"},
{ id:"Greece",prefix:"30"},
{ id:"Greenland",prefix:"299"},
{ id:"Grenada",prefix:"1"},
{ id:"Guadeloupe",prefix:"590"},
{ id:"Guam",prefix:"1"},
{ id:"Guatemala",prefix:"502"},
{ id:"Guernsey",prefix:"44"},
{ id:"Guinea",prefix:"224"},
{ id:"Guinea-Bissau",prefix:"245"},
{ id:"Guyana",prefix:"592"},
{ id:"Haiti",prefix:"509"},
{ id:"Heard Island and McDonald Islands",prefix:"672"},
{ id:"Vatican City",prefix:"379"},
{ id:"Honduras",prefix:"504"},
{ id:"Hungary",prefix:"36"},
{ id:"Hong Kong",prefix:"852"},
{ id:"Iceland",prefix:"354"},
{ id:"India",prefix:"91"},
{ id:"Indonesia",prefix:"62"},
{ id:"Ivory Coast",prefix:"225"},
{ id:"Iran (Islamic Republic of)",prefix:"98"},
{ id:"Iraq",prefix:"964"},
{ id:"Ireland",prefix:"353"},
{ id:"Isle of Man",prefix:"44"},
{ id:"Israel",prefix:"972"},
{ id:"Italy",prefix:"39"},
{ id:"Jamaica",prefix:"1"},
{ id:"Japan",prefix:"81"},
{ id:"Jersey",prefix:"44"},
{ id:"Jordan",prefix:"962"},
{ id:"Kazakhstan",prefix:"76"},
{ id:"Kenya",prefix:"254"},
{ id:"Kiribati",prefix:"686"},
{ id:"Kuwait",prefix:"965"},
{ id:"Kyrgyzstan",prefix:"996"},
{ id:"Lao People's Democratic Republic",prefix:"856"},
{ id:"Latvia",prefix:"371"},
{ id:"Lebanon",prefix:"961"},
{ id:"Lesotho",prefix:"266"},
{ id:"Liberia",prefix:"231"},
{ id:"Libya",prefix:"218"},
{ id:"Liechtenstein",prefix:"423"},
{ id:"Lithuania",prefix:"370"},
{ id:"Luxembourg",prefix:"352"},
{ id:"Macao",prefix:"853"},
{ id:"North Macedonia",prefix:"389"},
{ id:"Madagascar",prefix:"261"},
{ id:"Malawi",prefix:"265"},
{ id:"Malaysia",prefix:"60"},
{ id:"Maldives",prefix:"960"},
{ id:"Mali",prefix:"223"},
{ id:"Malta",prefix:"356"},
{ id:"Marshall Islands",prefix:"692"},
{ id:"Martinique",prefix:"596"},
{ id:"Mauritania",prefix:"222"},
{ id:"Mauritius",prefix:"230"},
{ id:"Mayotte",prefix:"262"},
{ id:"Mexico",prefix:"52"},
{ id:"Micronesia (Federated States of)",prefix:"691"},
{ id:"Moldova (Republic of)",prefix:"373"},
{ id:"Monaco",prefix:"377"},
{ id:"Mongolia",prefix:"976"},
{ id:"Montenegro",prefix:"382"},
{ id:"Montserrat",prefix:"1"},
{ id:"Morocco",prefix:"212"},
{ id:"Mozambique",prefix:"258"},
{ id:"Myanmar",prefix:"95"},
{ id:"Namibia",prefix:"264"},
{ id:"Nauru",prefix:"674"},
{ id:"Nepal",prefix:"977"},
{ id:"Netherlands",prefix:"31"},
{ id:"New Caledonia",prefix:"687"},
{ id:"New Zealand",prefix:"64"},
{ id:"Nicaragua",prefix:"505"},
{ id:"Niger",prefix:"227"},
{ id:"Nigeria",prefix:"234"},
{ id:"Niue",prefix:"683"},
{ id:"Norfolk Island",prefix:"672"},
{ id:"Korea (Democratic People's Republic of)",prefix:"850"},
{ id:"Northern Mariana Islands",prefix:"1"},
{ id:"Norway",prefix:"47"},
{ id:"Oman",prefix:"968"},
{ id:"Pakistan",prefix:"92"},
{ id:"Palau",prefix:"680"},
{ id:"Palestine, State of",prefix:"970"},
{ id:"Panama",prefix:"507"},
{ id:"Papua New Guinea",prefix:"675"},
{ id:"Paraguay",prefix:"595"},
{ id:"Peru",prefix:"51"},
{ id:"Philippines",prefix:"63"},
{ id:"Pitcairn",prefix:"64"},
{ id:"Poland",prefix:"48"},
{ id:"Portugal",prefix:"351"},
{ id:"Puerto Rico",prefix:"1"},
{ id:"Qatar",prefix:"974"},
{ id:"Republic of Kosovo",prefix:"383"},
{ id:"Réunion",prefix:"262"},
{ id:"Romania",prefix:"40"},
{ id:"Russian Federation",prefix:"7"},
{ id:"Rwanda",prefix:"250"},
{ id:"Saint Barthélemy",prefix:"590"},
{ id:"Saint Helena, Ascension and Tristan da Cunha",prefix:"290"},
{ id:"Saint Kitts and Nevis",prefix:"1"},
{ id:"Saint Lucia",prefix:"1"},
{ id:"Saint Martin (French part)",prefix:"590"},
{ id:"Saint Pierre and Miquelon",prefix:"508"},
{ id:"Saint Vincent and the Grenadines",prefix:"1"},
{ id:"Samoa",prefix:"685"},
{ id:"San Marino",prefix:"378"},
{ id:"Sao Tome and Principe",prefix:"239"},
{ id:"Saudi Arabia",prefix:"966"},
{ id:"Senegal",prefix:"221"},
{ id:"Serbia",prefix:"381"},
{ id:"Seychelles",prefix:"248"},
{ id:"Sierra Leone",prefix:"232"},
{ id:"Singapore",prefix:"65"},
{ id:"Sint Maarten (Dutch part)",prefix:"1"},
{ id:"Slovakia",prefix:"421"},
{ id:"Slovenia",prefix:"386"},
{ id:"Solomon Islands",prefix:"677"},
{ id:"Somalia",prefix:"252"},
{ id:"South Africa",prefix:"27"},
{ id:"South Georgia and the South Sandwich Islands",prefix:"500"},
{ id:"Korea (Republic of)",prefix:"82"},
{ id:"Spain",prefix:"34"},
{ id:"Sri Lanka",prefix:"94"},
{ id:"Sudan",prefix:"249"},
{ id:"South Sudan",prefix:"211"},
{ id:"Suriname",prefix:"597"},
{ id:"Svalbard and Jan Mayen",prefix:"47"},
{ id:"Swaziland",prefix:"268"},
{ id:"Sweden",prefix:"46"},
{ id:"Switzerland",prefix:"41"},
{ id:"Syrian Arab Republic",prefix:"963"},
{ id:"Taiwan",prefix:"886"},
{ id:"Tajikistan",prefix:"992"},
{ id:"Tanzania, United Republic of",prefix:"255"},
{ id:"Thailand",prefix:"66"},
{ id:"Timor-Leste",prefix:"670"},
{ id:"Togo",prefix:"228"},
{ id:"Tokelau",prefix:"690"},
{ id:"Tonga",prefix:"676"},
{ id:"Trinidad and Tobago",prefix:"1"},
{ id:"Tunisia",prefix:"216"},
{ id:"Turkey",prefix:"90"},
{ id:"Turkmenistan",prefix:"993"},
{ id:"Turks and Caicos Islands",prefix:"1"},
{ id:"Tuvalu",prefix:"688"},
{ id:"Uganda",prefix:"256"},
{ id:"Ukraine",prefix:"380"},
{ id:"United Arab Emirates",prefix:"971"},
{ id:"United Kingdom of Great Britain and Northern Ireland",prefix:"44"},
{ id:"United States of America",prefix:"1"},
{ id:"Uruguay",prefix:"598"},
{ id:"Uzbekistan",prefix:"998"},
{ id:"Vanuatu",prefix:"678"},
{ id:"Venezuela (Bolivarian Republic of)",prefix:"58"},
{ id:"Vietnam",prefix:"84"},
{ id:"Wallis and Futuna",prefix:"681"},
{ id:"Western Sahara",prefix:"212"},
{ id:"Yemen",prefix:"967"},
{ id:"Zambia",prefix:"260"},
{ id:"Zimbabwe",prefix:"263"},
];


export const case_status = [
    {
        id:'open',label:'open'
    },
    {
        id:'under_progress',label:'Under Progress'
    },
    {
        id:'closed',label:'Closed'
    },
];

export const permittedCompanies = localStorage.getItem('permittedCompanies') ? JSON.parse(localStorage.getItem('permittedCompanies')) : []; 

export const currencies = [
    {
        id: 'USD', label: 'USD'
    },
    {
        id: 'LBP', label: 'LBP'
    }
];

export const yearMonthsList = [
    {id: '01', label: '01'},
    {id: '02', label: '02'},
    {id: '03', label: '03'},
    {id: '04', label: '04'},
    {id: '05', label: '05'},
    {id: '06', label: '06'},
    {id: '07', label: '07'},
    {id: '08', label: '08'},
    {id: '09', label: '09'},
    {id: '10', label: '10'},
    {id: '11', label: '11'},
    {id: '12', label: '12'},
];


