import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import SuccessNotification from 'src/images/success_notification.svg'
import errorNotification from 'src/images/error_notification.svg'
import WarningNotification from 'src/images/warning_notification.svg'

const CustomToast = ({type, message}) => {

    const ToastIcon = () => {
        switch (type) {
            case 'success':
                return <img src={SuccessNotification} width={20}/>;
            case 'error':
                return <img src={errorNotification} width={20}/>;
            case 'warning':
                return <img src={WarningNotification} width={20}/>;
            default:
                return null;
        }
    };

    return (
        toast[type](message,{
            icon:<ToastIcon />
        })
    );

}
export default CustomToast
