import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteSVG from 'src/images/carbon_delete.svg'
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import { CustomRoundedCheckbox, InLineCustomCheckbox } from 'src/components/Custom/CustomInputForms';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';


const Permission = props => {
    const { t, i18n } = useTranslation();

    return (
        <div style={{ marginTop: '30px' }}>
            <p style={{ fontSize: '14px', color: '#650E3D', fontWeight: '500' }}>{props.data?.name}</p>
            <div style={{ display: 'flex' }}>
                <div className="d-flex justify-content-center align-items-center"
                    style={{
                        width: '80px', height: '34px'
                        , backgroundColor: 'rgba(248, 248, 248, 1)'
                        , borderRadius: '24px'
                        , marginRight: '40px'
                    }}>
                    <div style={{ marginLeft: '15px' }}>
                        <InLineCustomCheckbox label={t('Label')}
                            checked={props.data?.can_view}
                            onClick={(e) => props.onClick(e, props.data, 'cv')} />
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center"
                    style={{
                        width: '80px', height: '34px'
                        , backgroundColor: 'rgba(248, 248, 248, 1)'
                        , borderRadius: '24px'
                        , marginRight: '40px'
                    }}>
                    <div style={{ marginLeft: '15px' }}>
                        <InLineCustomCheckbox label={t('Edit')}
                            checked={props.data?.can_edit}
                            onClick={(e) => props.onClick(e, props.data, 'ce')} />
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center"
                    style={{
                        width: '80px', height: '34px'
                        , backgroundColor: 'rgba(248, 248, 248, 1)'
                        , borderRadius: '24px'
                        , marginRight: '40px'
                    }}>
                    <div style={{ marginLeft: '15px' }}>
                        <InLineCustomCheckbox label={t('Add')}
                            checked={props.data?.can_add}
                            onClick={(e) => props.onClick(e, props.data, 'ca')} />
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center"
                    style={{
                        width: '80px', height: '34px'
                        , backgroundColor: 'rgba(248, 248, 248, 1)'
                        , borderRadius: '24px'
                        , marginRight: '40px'
                    }}>
                    <div style={{ marginLeft: '15px' }}>
                        <InLineCustomCheckbox label={t('Delete')}
                            checked={props.data?.can_delete}
                            onClick={(e) => props.onClick(e, props.data, 'cd')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const PermissionTable = props => {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/presets/getPages-${props.selected.id}`, {
            params: {
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })

    }
    useEffect(() => {
        if (props.selected.id > 0) {
            getData();
        }
    }, [props.open, props.selected.id]);


    const handleChangeCheckbox = (event, value, type) => {
        const { id, checked, tabIndex } = event.target;
        // let items = [...data];
        const index = data.findIndex(item => item.id === value.id);
        console.log(index);
        if (index !== -1) {
            const newData = [...data];
            if (type === 'cv') {
                newData[index] = { ...newData[index], can_view: checked };
            } else if (type === 'ce') {
                newData[index] = { ...newData[index], can_edit: checked };
            } else if (type === 'ca') {
                newData[index] = { ...newData[index], can_add: checked };
            } else if (type === 'cd') {
                newData[index] = { ...newData[index], can_delete: checked };
            }
            setData(newData);
        }
        const requestOptions = {
            page_id: value.id,
            checkbox_type: type,
            value: checked == true ? 1 : 0,
            preset_id: props.selected.id
        };
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/masterData/presets/pages/update`, requestOptions)
            .then(res => {
            })
            .catch(error => {
                handelErrors(error)
            });
    }

    return (
        <div style={{ marginLeft: '50px', paddingTop: '30px', height: '100%' }}>
            <p style={{ fontSize: '24px', color: '#650E3D' }}>{props.selected.name}</p>
            <div style={{ overflow: 'auto', height: '80%', marginBottom: '80px' }}>
                {
                    isLoading ? <CircularProgress />
                        :
                        data.map((e, key) => (
                            <Permission key={key} data={e} onClick={handleChangeCheckbox} />
                        ))
                }
            </div>
        </div>
    )
}
export default PermissionTable