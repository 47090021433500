import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import CustomDialog from '../../components/Custom/CustomDialog';
import styles from '../../styles/login.module.css';
import { CustomInput, SingleDate } from '../../components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SingleCustomAutocomplete from '../../components/Custom/SingleCustomAutocomplete';
import CustomAutocompleteServerSide from '../../components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CustomAutocomplete from 'src/components/Custom/CustomAutocomplete';
import { useTranslation } from 'react-i18next';
import { statuses } from 'src/components/helpers/FixedData';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';
import { getDescription } from 'src/components/helpers/Functions';

const AddForm = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [caseStatuses, setCaseStatuses] = useState([]);
    const [courtsList, setCourtsList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [specializationList, setSpecializationList] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [caseTypes, setCaseTypes] = useState([]);
    let { id } = useParams();
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        type: null,
        court_required: 0,
        company_id: id,
        name: '',
        court_id: null,
        manual_court_id: '',
        judge_name: '',
        status: null,
        clients_ids: [],
        case_manager: [],
        case_personnel: [],
        category_id: [],
        specialization_id: [],
        country_id: [],
        created_date: new Date(),
        languague : i18n.language,
    });

    const resetForm = () => {
        setFormData({
            type: null,
            court_required: 0,
            company_id: id,
            name: '',
            court_id: null,
            manual_court_id: '',
            judge_name: '',
            status: null,
            clients_ids: [],
            case_manager: [],
            case_personnel: [],
            category_id: [],
            specialization_id: [],
            country_id: [],
            created_date: new Date(),
            languague : i18n.language,
        });
    }

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: response.data.company_id,
                    type: response.data.type,
                    name: response.data.name,
                    court_id: response.data.court_id,
                    manual_court_id: response.data.manual_court_id,
                    judge_name: response.data.judge_name,
                    status: response.data.status,
                    languague: i18n.language,
                    clients_ids: response.data.clients_ids ? response.data.clients_ids.split(',') : [],
                    case_manager: response.data.case_manager ? response.data.case_manager.split(',') : [],
                    case_personnel: response.data.case_personnel ? response.data.case_personnel.split(',') : [],
                    category_id: response.data.category_id ? response.data.category_id.split(',') : [],
                    country_id: response.data.country_id ? response.data.country_id.split(',') : [],
                    specialization_id: response.data.specialization_id ? response.data.specialization_id.split(',') : [],
                    created_date: response.data.created_date ? new Date(response.data.created_date) : new Date(),
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.id > 0) {
            getFormData();
        } else {
            resetForm();
        }
        if (props.open && courtsList.length == 0) {
            const getCourts = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/courts/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCourtsList(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCourts();
        }
        if (props.open && caseStatuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/CaseStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCaseStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }
        if (specializationList.length == 0) {
            const getSpecializations = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/Specialization/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setSpecializationList(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getSpecializations();
        }
        if (categoriesList.length == 0) {
            const getCategories = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/CaseCategories/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCategoriesList(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCategories();
        }
        if (countriesList.length == 0) {
            const getCountries = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/countries/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCountriesList(response.data.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCountries();
        }
        if (caseTypes.length == 0) {
            const getCaseTypes = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/CaseTypes/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCaseTypes(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCaseTypes();
        }

    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name, description, object, value) => {

        if (name == 'type' && value != null) {
            setFormData({
                ...formData,
                [name]: newValue,
                court_required: value.court_required,
            });
        } else {
            setFormData({
                ...formData,
                [name]: newValue,
            });
        }
    }

    const saveForm = () => {
        setSaveIsLoading(true)
        if (formData.id > 0) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/update`,
                data: formData,
            })
                .then((response) => {
                    CustomToast({ type: 'success', message: 'Success' });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        } else {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/add`,
                data: formData,
            })
                .then((response) => {
                    CustomToast({ type: 'success', message: 'Success' });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        }

    }
    return (
        <CustomNewDialog
            maxWidth={'sm'}
            open={props.open}
            onClose={props.onClose}>
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? 'Update' : 'Add'} {t('Case')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <p>{props.title}</p>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('case_name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleCustomAutocomplete
                            filedName="type"
                            label={t('Type')}
                            list={caseTypes}
                            value={formData.type}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    {
                        formData.court_required == 1 ?
                            <>
                                <Grid item xs={12} md={4}>
                                    <SingleCustomAutocomplete
                                        filedName="court_id"
                                        label={t('Court')}
                                        list={courtsList}
                                        value={formData.court_id}
                                        listKey="id"
                                        description={getDescription('name',i18n.language)}
                                        customOnChange={customOnChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CustomInput
                                        id={'manual_court_id'}
                                        value={formData.manual_court_id}
                                        onChange={handleInputChange}
                                        placeholder={t('court_id')}
                                        withBorder={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <CustomInput
                                        id={'judge_name'}
                                        value={formData.judge_name}
                                        onChange={handleInputChange}
                                        placeholder={t('Judge')+' '+t('name')}
                                        withBorder={false}
                                    />
                                </Grid>
                            </>
                            :
                            null
                    }
                    <Grid item xs={12} md={12}>
                        <CustomAutocompleteServerSide
                            filedName="clients_ids"
                            label={t('Client')+' '+t('name')}
                            values={formData.clients_ids}
                            listKey="id"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={"/masterData/companyClients/getDataBySearchQuery"}
                            companyID={id}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomAutocompleteServerSide
                            filedName="case_manager"
                            label={t('case_manager')}
                            values={formData.case_manager}
                            listKey="user_id"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={`/masterData/companyEmployees/getDataBySearchQuery`}
                            companyID={id}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomAutocompleteServerSide
                            filedName="case_personnel"
                            label={t('case_personnel')}
                            values={formData.case_personnel}
                            listKey="user_id"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={`/masterData/companyEmployees/getDataBySearchQuery`}
                            companyID={id}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomAutocomplete
                            filedName="category_id"
                            label={t('Categories')}
                            list={categoriesList}
                            values={formData.category_id}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomAutocomplete
                            filedName="specialization_id"
                            label={t('Specialization')}
                            list={specializationList}
                            values={formData.specialization_id}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomAutocomplete
                            filedName="country_id"
                            label={t('Jurisdiction')}
                            list={countriesList}
                            values={formData.country_id}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleCustomAutocomplete
                            filedName="status"
                            label={t('Status')}
                            list={caseStatuses}
                            value={formData.status}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleDate
                            id="created_date"
                            label={t('created_date')}
                            value={formData.created_date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '2%' }}>
                    <Grid item xs={0} md={6}></Grid>
                    <Grid item xs={12} md={6} marginTop={"20px"}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </CustomNewDialog>
    )
}
export default AddForm