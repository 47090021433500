import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from "prop-types";
import CustomDialog from 'src/components/Custom/CustomDialog';
import { SingleDate } from 'src/components/Custom/CustomInputForms';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/Custom/CustomButton';

const ReportFilter = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <CustomDialog
            maxWidth={'xs'}
            open={props.openDialog}
            onClose={props.handleClose}
            onSave={props.handleSave}
            handleclear={props.handleclear}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h2 style={{color:'#650E3D'}}>{t('Filter')}</h2>
                </Grid>
                <Grid item xs={6}>
                    <SingleDate
                        id="fromDate"
                        label={t('from_date')}
                        value={props.filterForm.fromDate}
                        onChange={props.handleChangeInputFilter}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SingleDate
                        id="toDate"
                        label={t('to_date')}
                        value={props.filterForm.toDate}
                        onChange={props.handleChangeInputFilter}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Grid item xs={6}>
                            <CustomButton variant="contained" title={t('Save')} onClick={props.handleSave}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialog>
    )
}
ReportFilter.propTypes = PropTypes.shape({
    params: PropTypes.shape({
        openDialog: PropTypes.node,
        handleClose: PropTypes.node,
        filterForm: PropTypes.node,
        handleChangeInputFilter: PropTypes.func,
        customOnChange: PropTypes.func,
        handleSave: PropTypes.func,
        handleclear: PropTypes.func,
    }).isRequired,
}).isRequired


export default ReportFilter