import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import CustomDataTable from 'src/components/Custom/CustomDataTable'
import AddIcon from '@mui/icons-material/Add'
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteSVG from 'src/images/carbon_delete.svg'
import ListIcon from '@mui/icons-material/List';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import AddUpdateFolder from './AddUpdateFolder';
import FilesForm from './FilesForm';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import CustomToast from 'src/components/Custom/CustomToast';
import FolderView from './FolderView';
import { CustomInput, CustomSearch } from 'src/components/Custom/CustomInputForms';
import { CreateNewFolder, Folder, Search } from '@mui/icons-material';
import CustomButton from 'src/components/Custom/CustomButton';
import { CircularProgress } from '@mui/material';
import AddUpdateFolders from './AddUpdateFolders';
import AddFiles from './AddFiles';

const Folders = () => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [openFiles, setOpenFiles] = useState(false);
    const [parentFolderId, setParentFolderId] = useState('root');
    const [data, setData] = useState([])
    const [path, setPath] = useState([])
    const [search, setSearch] = useState("")
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'files')

    let { id, caseID } = useParams();

    const getData = () => {
        setIsLoading(true);
        let tmpParentFolderId = "root";
        if (search) {
            if (parentFolderId > 0 && parentFolderId !== "root") {
                tmpParentFolderId = parentFolderId;
            }
            else {
                tmpParentFolderId = null;
            }
        }
        else {
            tmpParentFolderId = parentFolderId;
        }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/files/getAllByFolders`, {
            params: {
                company_id: id,
                case_id: caseID > 0 ? caseID : null,
                parent_folder_id: tmpParentFolderId,
                search: search
            }
        })

            .then(res => {
                setIsLoading(false);
                setData(res.data.data);
                setPath(res.data.path)
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })

    }
    useEffect(() => {
        getData();
    }, [parentFolderId]);

    // const columns = [

    //     {
    //         name: 'category_name',
    //         label: 'Names',
    //     },
    //     {
    //         name: "actions",
    //         label: "Actions",
    //         options: {
    //             filter: false,
    //             sort: false,
    //             empty: true,
    //             customBodyRender: (value, meta) => {
    //                 return (
    //                     <>
    //                         {/* <Tooltip title={"Edit"}>
    //                             <IconButton onClick={() => handleEdit(meta)}>
    //                                 <EditIcon style={{ color: '#650E3D' }} />
    //                             </IconButton>
    //                         </Tooltip> */}
    //                         <Tooltip title={"Delete"}>
    //                             <IconButton onClick={() => deleteForm(meta)}>
    //                                 <img src={DeleteSVG} />
    //                             </IconButton>
    //                         </Tooltip>
    //                         <Tooltip title={"Files"}>
    //                             <IconButton onClick={() => handleFiles(meta)}>
    //                                 <ListIcon style={{ color: '#650E3D' }} />
    //                             </IconButton>
    //                         </Tooltip>
    //                         <Tooltip title={"Download File"}>
    //                             <IconButton onClick={() => downloadFile(meta)}>
    //                                 <FileDownloadIcon style={{ color: '#650E3D' }} />
    //                             </IconButton>
    //                         </Tooltip>
    //                     </>
    //                 )
    //             },
    //         },
    //     },
    // ];

    // const options = {
    //     filter: false,
    //     print: false,
    //     download: false,
    //     rowsPerPage: 100,
    //     rowsPerPageOptions: [20, 50, 100],
    //     selectableRows: "none",
    //     customToolbar: () => {
    //         return (<span>
    //             {/* <Tooltip title={"Add"}>
    //                 <IconButton onClick={addForm}>
    //                     <AddIcon style={{ color: '#650E3D' }} />
    //                 </IconButton>
    //             </Tooltip> */}
    //             <span style={{ marginLeft: '10px' }}><CustomAddButton onClick={addForm} title={'New Folder'} /></span>
    //         </span>);
    //     },
    //     textLabels: {
    //         body: {
    //             noMatch: isLoading ?
    //                 <LinearProgress /> :
    //                 'There is no matching data to display',
    //         },
    //     },
    // };

    const addFolder = () => {
        setOpen(true);
    }

    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh === true) {
            getData();
        }
    }
    const addFiles = () => {
        setOpenFiles(true);
    }
    const handleCloseFiles = (refresh) => {
        setOpenFiles(false);
        if (refresh === true) {
            getData();
        }
    }
    const downloadFile = meta => {
        // axios({
        //     method: "post",
        //     url: `${process.env.REACT_APP_API_BASE_URL}/masterData/files/downloadFolder`,
        //     data: {
        //         company_id: id,
        //         category_name: meta.rowData[0]
        //     },
        // })
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/files/downloadFolder`, {
            responseType: 'blob',
            params: {
                company_id: id,
                category_name: meta.rowData[0],
                case_id: caseID > 0 ? caseID : null
            }
        }).then((response) => {
            CustomToast({ type: 'success', message: t('Success') });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'folder.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
            .catch((error) => {
                handelErrors(error)
            });
    }

    return (
        <div>
            <div className="d-flex" >
                <div className="w-75" >
                    <CustomSearch
                        id={'search'}
                        value={search}
                        onChange={(event) => {
                            const { id, value, checked, type } = event.target;
                            setSearch(value)
                        }}
                        onKeyDown={(e) => { if (e.key === 'Enter') { getData() } }}
                        className={"file-search-overrrite"}
                        placeholder={t('Search') + "..."}
                        withBorder={false}
                        endAdornment={<Search style={{ cursor: 'pointer', color: '#650E3D' }} />}
                        variant="contained"
                    />
                </div>
                {
                    targetPage?.can_add === 1 ?
                        <>
                            <div className="w-25 d-flex" >
                                <div className="w-25 h-100 d-flex justify-content-center align-items-center">
                                    <CreateNewFolder className="mt-1" style={{ cursor: 'pointer', color: '#650E3D', fontSize: "2.8em" }}
                                        onClick={addFolder}
                                    />
                                </div>
                                <div className="w-75 h-100 d-flex justify-content-center align-items-center" >
                                    <CustomButton variant="contained" title={t('upload_files')}
                                        className={"file-upload-file-button"}
                                        onClick={addFiles}
                                    />
                                </div>
                            </div>
                        </>
                        : null
                }

            </div>
            <div>
                <span style={{
                    color: path.length === 0 ? "#650E3D" : "#575757",
                    cursor: "pointer"
                }}
                    onClick={() => {
                        setSearch("")
                        setParentFolderId("root");
                    }}
                >{`Root`}{path.length === 0 ? "" : "/"}</span>
                {path.map((v, i) => {
                    return <span
                        style={{
                            color: path.length === i + 1 ? "#650E3D" : "#575757",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setSearch("")
                            setParentFolderId(parseInt(v.id));
                        }}
                    >{`${v.name}`}{path.length === i + 1 ? "" : "/"}</span>
                })
                }
            </div>
            {isLoading ? <CircularProgress /> :
                <>
                    {data.length === 0 ?
                        // <h2 style={{
                        //     color: "#650E3D",
                        //     backgroundColor: "#FFFFFF",
                        //     padding: "1em",
                        //     borderRadius: "15px"
                        // }}>

                        // </h2> 
                        <div className='d-flex' style={{
                            backgroundColor: "#FFFFFF",
                            padding: "1em",
                            borderRadius: "15px",
                            marginTop: '20px',
                            color: "#650E3D",
                        }}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Folder
                                    style={{ color: '#650E3D', fontSize: "2.5em" }}
                                />
                            </div>
                            <div className='d-flex flex-column' style={{ paddingLeft: '1em' }}>
                                <div style={{ fontWeight: '700' }}>
                                    {t('folder_empty')}
                                </div>
                                <div>
                                    {t('upload_a_file_or_create_a_folder')}
                                </div>
                            </div>
                        </div>
                        :
                        <FolderView
                            data={data}
                            updateParentFolderId={(newParentFolderId) => {
                                setSearch("")
                                if (newParentFolderId !== "root") {
                                    setParentFolderId(parseInt(newParentFolderId))
                                }
                                else {
                                    setParentFolderId("root")
                                }

                            }}
                            viewPath={search ? true : false}
                            refresh={getData}
                        />}
                </>
            }
            <AddUpdateFolders
                open={open}
                onClose={handleClose}
                company_id={id}
                case_id={caseID}
                parent_firm_folder_id={parentFolderId}
            />
            <AddFiles
                open={openFiles}
                onClose={handleCloseFiles}
                case_id={caseID}
                parent_firm_folder_id={parentFolderId}
            />
        </div>
    )
}
export default Folders