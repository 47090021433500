import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import OptionsSVG from 'src/images/options_vertical.svg';
import LocationSVG from 'src/images/location.svg'
import moment from 'moment';

const ClientCard = (props) => {
    return (
        <div style={{
            backgroundColor: '#ffffff'
            , minHeight: '184px', borderRadius: '10px'
            , display: 'flex',justifyContent: 'center'
            , alignItems: 'center',cursor:'pointer'
        }} onClick={props.onClick}>
            <Grid container style={{ width: '90%' }}>
                <Grid item xs={11}>
                    <div style={{
                        height: '80px'
                        , display: 'flex'
                        , alignItems: 'center'
                    }}>
                        <img src={'https://symbols.getvecta.com/stencil_198/24_black-small-square.3fb8f5251a.svg'}
                            width={50} style={{ borderRadius: '25px' }} />
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div style={{ height: '50px', width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                        <img src={OptionsSVG} width={20} style={{ cursor: 'pointer' }} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='d-flex  align-items-center'>
                        <img src={LocationSVG} width={8} />
                        <span style={{
                            fontWeight: '400'
                            , fontSize: '11px'
                            , marginLeft: '5px'
                            , color: '#650E3D'
                        }}>{props.data?.nationality_name} </span>
                    </div>
                    <div style={{ lineHeight: '15px', fontWeight: '700', fontSize: '16px', color: '#650E3D' }}>
                        {props.data?.full_name}
                    </div>
                    <div style={{ lineHeight: '15px', fontWeight: '900', fontSize: '12px', color: 'rgba(87, 87, 87, 1)' }}>
                        Active at {moment(props.data?.last_login_date).format("h:mm A")}
                    </div>
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: 'rgba(248, 248, 248, 1)', height: '40px' }}>
                    <div className='d-flex flex-column' style={{ marginTop: '5px', marginLeft: '5px' }}>
                        <span style={{ fontSize: '10px', fontWeight: '700' }}>{props.data?.total_cases} cases</span>
                        <span style={{ fontSize: '11px', fontWeight: '700', lineHeight: '10px' }}>{props.data?.grouped_statuses}</span>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{
                        width: '100%'
                        , backgroundColor: '#650E3D'
                        , color: '#ffffff'
                        , borderRadius: '16px'
                        , marginTop: '10px'
                        , marginBottom: '20px'
                    }} onClick={props.onClick}>
                        <span style={{ paddingLeft: '10px',fontSize:'14px',fontWeight:'700',textTransform:'uppercase'}}>
                            {props.data?.total_expenses} ongoing retainer
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default ClientCard