import { Dialog, DialogTitle, Grid, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
// import Frame from '../../images/frame.svg';
import Frame from 'src/images/logo_svg.svg';
import NotificationSVG from 'src/images/notification_svg.svg';
import EmployeeLogo from '../../images/employee_logo.png';
import LocationSVG from 'src/images/location.svg'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Swal from 'sweetalert2';
import axios from 'axios';
import cookie from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import allActions from 'src/actions/allActions';
import { toast } from 'react-toastify';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
// import { permittedCompanies } from '../helpers/FixedData';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomToast from '../Custom/CustomToast';
import { useTranslation } from 'react-i18next';

const CustomHeader = props => {
    const currentUser = useSelector((state) => state.currentUser)
    const permittedCompanies = currentUser.user.current_companies
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { pathname } = useLocation();
    const path = matchPath({ path: "/:id/*" }, pathname);
    const { t, i18n } = useTranslation();

    // const pathCase = matchPath({ path: "/:id/Case/:CaseID" }, pathname);

    // let { id } = useParams();
    // const [open, setOpen] = useState(false);
    const [company, setCompany] = useState({
        id: -1,
        name: '',
        email: '',
        logo: '',
    });

    let open = Boolean(anchorEl);
    const id_pop = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        // setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        // setOpen(false);
        setAnchorEl(null);
    };

    const logout = () => {
        Swal.fire({
            title: 'Please Confirm Your Action ?',
            showCancelButton: true,
            confirmButtonText: 'Logout!',
            icon: 'warning',
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`)
                    .then((res) => {
                        axios.defaults.headers.common['Authorization'] = ``
                        localStorage.clear()
                        cookie.remove('token')
                        dispatch(allActions.userActions.logOut())
                        window.location.reload()
                    })
                    .catch((e) => {
                        CustomToast({ type: 'error', message: 'Error' });
                    })
            }
        })
    }

    const changeCompany = (id) => {
        navigate(`/${id}`);
        const targetElement = permittedCompanies.find(e => e.id === id)
        localStorage.setItem('vat', targetElement.vat);
        window.location.reload();
    }

    useEffect(() => {
        let id = 0;
        if(path !=null){
            id = path.params.id;
        }
        console.log("permittedCompanies");
        // console.log(permittedCompanies);
        permittedCompanies.map((e) => {
            if (id == e.id) {
                setCompany({
                    id: e.id,
                    name: e.name,
                    email: e.email,
                    logo: e.logo
                });
            }
        });
    }, [permittedCompanies]);

    return (
        // <div style={{ backgroundColor: '#F4F4F4' }}>
        //     <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                
        //     </div>
        // </div>
        <Grid container>
                    <Grid item xs={2.5} md={1} style={{ textAlign: 'center' }}>
                        <img src={Frame} width={50} style={{ marginRight: '25px' }} />
                    </Grid>
                    <Grid item xs={3} md={7} textAlign={'left'} >
                        <div style={{ display: 'flex', flexDirection: 'column' ,textAlign: i18n.language =="ar" ? 'right':'left'}} >
                            <span className='bold-text size-20' style={{ cursor: 'pointer' }} onClick={handleClick} aria-describedby={id_pop}>
                                {company.name} <ArrowDropDownOutlinedIcon style={{ color: '#650E3D', cursor: 'pointer' }} />
                            </span>
                            <span className='colored-text size_10'>12/23 Lawyers Online</span>
                        </div>
                        <Popover
                            id={id_pop}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: i18n.language =="ar" ?'right':'left',
                            }}
                            sx={{
                                '& .MuiPopover-paper': {
                                  marginLeft: i18n.language =="ar" ? '-50px':0, 
                                },
                              }}
                        >
                            <List xs={{ pt: 0 }}>
                                {
                                    permittedCompanies.map((e, key) => (
                                        <Typography key={key} onClick={() => changeCompany(e.id)} sx={{ p: 2, cursor: 'pointer' }}>{e.name}</Typography>
                                    ))
                                }
                            </List>
                        </Popover>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer' }}>
                            <img src={NotificationSVG} width={20} />
                        </div>
                    </Grid>
                    <Grid item xs={4} md={2.9}>
                        <div style={{
                            width: '100%', height: '50px',
                            backgroundColor: 'white'
                            , display: 'flex'
                            , borderRadius: '34px'
                            , marginLeft: '16px'
                        }}>
                            <Grid container alignItems={'center'} spacing={1}>
                                <Grid item xs={2}>
                                    <div className='flex-centered' style={{ marginLeft: '5px' }}>
                                        <img src={EmployeeLogo} className='image-fit-cover' width={40} height={40} />
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={6.5} textAlign={'left'}>
                                    <div className='vertical-flex'>
                                        <span className='colored-text' style={{ fontSize: '6px' }}>
                                            <img src={LocationSVG} /> Lebanon
                                        </span>
                                        <span style={{ fontSize: '12px' }}>
                                            Test User
                                        </span>
                                        <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                                            CEO
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={1} className='d-flex justify-content-center align-items-center'>
                                    {/* <div onClick={()=>navigate(`/${id}/Profile`)}> */}
                                    <SettingsOutlinedIcon style={{ color: '#650E3D', cursor: 'pointer' }} />
                                    {/* </div> */}
                                </Grid>
                                <Grid item xs={2} className='d-flex justify-content-center align-items-center'>
                                    <div onClick={logout}><LogoutOutlinedIcon style={{ color: '#650E3D', cursor: 'pointer' }} /></div>
                                </Grid>

                            </Grid>
                        </div>
                    </Grid>
                </Grid>
    )
}
export default CustomHeader