import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";

const CustomAddButton = (props) => {

    // const styleFilled = {
    //     backgroundColor: '#650E3D',
    //     minWidth: '150px',
    //     borderColor: '#650E3D',
    //     borderRadius: '30px',
    //     height: '30px'
    // }

    // const style = styleFilled ;
    // const variant = "contained";

    const styleFilled = {
        backgroundColor: '#650E3D',
        width: props.width ? props.width : '160px',
        borderColor: '#650E3D',
        borderRadius: '30px',
        height: '36px',
        fontFamily: 'Poppins, sans-serif',
        textTransform:'capitalize',
    }
    const styleOutlined = {
        color: '#650E3D',
        width: props.width ? props.width : '160px',
        border: '2px solid #650E3D',
        borderRadius: '30px',
        height: '36px',
        fontWeight:'bold',
        fontFamily: 'Poppins, sans-serif',
        textTransform:'capitalize',
    }

    const style = props.variant == "outlined" ?styleOutlined :  styleFilled;
    const variant = props.variant =="outlined" ? "outlined" : "contained";

    return (
        <Button
            variant={variant}
            className={props.className ? props.className : ''}
            disabled={props.isLoading ? true : false}
            style={props.style ? props.style : style}
            onClick={props.onClick}
            startIcon={props.startIcon ? props.startIcon : null}
            endIcon={props.endIcon ? props.endIcon : null}
        >
            {props.isLoading && <CircularProgress size="sm" />} {props.title}
        </Button>
    )
}

export default CustomAddButton
