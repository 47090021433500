import React, { useEffect, useRef, useState } from 'react'
import CustomDialog from '../Custom/CustomDialog'
import CustomButton from '../Custom/CustomButton';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import { Grid } from '@mui/material';
import CustomAutocompleteServerSide from '../Custom/CustomAutocompleteServerSide';
import axios from 'axios';
import { toast } from 'react-toastify';
import CustomToast from 'src/components/Custom/CustomToast';
import handelErrors from '../helpers/handelErrors';
import SingleCustomAutocomplete from '../Custom/SingleCustomAutocomplete';
import { CustomInput } from '../Custom/CustomInputForms';
import CustomFileButton from '../Custom/CustomFileButton';

const type = [
    { id: 'single', label: 'Single' },
    { id: 'company', label: 'Company' },
];

const CreateCompany = props => {
    const { t, i18n } = useTranslation();
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [plansList, setPlansList] = useState([]);
    const fileInputRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState({
        name: '',
        size: '',
    });
    const [formData, setFormData] = useState({
        plan_id: props.selectedPlan? props.selectedPlan.id : null,
        plan_type: props.selectedPlanType ? props.selectedPlanType : null,
        plan_price: props.selectedPlanType ? props.selectedPlanType == "monthly" ? props.selectedPlan.price : props.selectedPlan.yearly_price : null,
        name: '',
        address: '',
        phone: '',
        email: '',
        mof: '',
        type: '',
        vat: '',
        logo: null,
    });
    const resetForm = () => {
        setFormData({
            plan_id: props.selectedPlan? props.selectedPlan.id : null,
            plan_type: props.selectedPlanType ? props.selectedPlanType : null,
            plan_price: props.selectedPlanType ? props.selectedPlanType == "monthly" ? props.selectedPlan.price : props.selectedPlan.yearly_price : null,
            name: '',
            address: '',
            phone: '',
            email: '',
            mof: '',
            type: '',
            vat: '',
            logo: null,
        });
    }
    const handleChooseFile = () => {
        fileInputRef.current.click();
    };
    useEffect(()=>{
        if(props.open){
            resetForm();
        }
    },[props.open])
    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        if(formData.plan_id > 0){
            setSaveIsLoading(true);
            const formDataObj = new FormData();
            for (const [key, value] of Object.entries(formData)) {
                if (value && value !== "" && value !== null) {
                    formDataObj.append(key, value);
                }
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companies/add`,
                data: formDataObj,
            })
                .then((response) => {
                    CustomToast({type:'success',message : 'Success'});
                    setSaveIsLoading(false);
                    props.onClose(true,response.data.company_id,response.data.current_companies);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        }else{
            CustomToast({type:'warning',message : 'Choose a plan'});
        }
    }

    const handleChangeFile = (e) => {
        e.preventDefault()
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            setSelectedFileName({
                name: file.name,
                size: file.size
            });
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                setFormData({
                    ...formData,
                    logo: file
                });
            }
        } else {
            setFormData({
                ...formData,
                logo: null
            });
        }
    }
    return (
        <CustomDialog maxWidth={'md'} open={props.open} onClose={() => props.onClose(false)}>
            <div style={{ width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SingleCustomAutocomplete
                            filedName="type"
                            label={t('type')}
                            list={type}
                            value={formData.type}
                            listKey="id"
                            description="label"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            id={'address'}
                            value={formData.address}
                            onChange={handleInputChange}
                            placeholder={t('address')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            id={'email'}
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder={t('email')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            id={'phone'}
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder={t('phone')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            id={'mof'}
                            value={formData.mof}
                            onChange={handleInputChange}
                            placeholder={t('mof')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            id={'vat'}
                            value={formData.vat}
                            onChange={handleInputChange}
                            placeholder={t('VAT')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} name={t('attachement')} id="attachement" onChange={(e) => handleChangeFile(e)} />
                        <div className='d-flex'>
                            <CustomFileButton onClick={handleChooseFile} />
                            <div className='d-flex justify-content-center align-items-center'>
                                <span style={{ marginLeft: '10px', color: '#650E3D' }}>{selectedFileName.name != '' ? selectedFileName.name : 'Upload Logo'}</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{ width: '100%' }}>
                            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', marginTop: '10px' }}>
                                <Grid item xs={4}>
                                    <CustomButton variant="contained" title="Create" onClick={saveForm} isLoading={saveIsLoading} />
                                </Grid>
                            </div>

                        </Grid>
                    </Grid>
                </Grid>
            </div>

        </CustomDialog>
    )
}

CreateCompany.propTypes = {
    open: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired,
    // planId: PropTypes.any.isRequired,
    selectedPlan: PropTypes.any.isRequired,
    selectedPlanType: PropTypes.any.isRequired,
}
export default CreateCompany