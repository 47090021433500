import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import FilterSVG from 'src/images/filter_svg.svg'
import SearchSVG from 'src/images/search_svg.svg'
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import AddUpdateClient from './AddUpdateClient';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import CustomToast from 'src/components/Custom/CustomToast';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import ClientCard from './ClientCard';
import ClientDetails from './ClientDetails';

const CompanyClients = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState('');
    const [show, setShow] = useState('');
    const [show2, setShow2] = useState('none');
    const [data, setData] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    const [selectedUserID, setSelectedUserID] = useState('');
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'company_clients')

    const previousController = useRef();
    const previousController2 = useRef();
    const [filterForm, setFilterForm] = useState({
        name: ''
    });

    // const getData = () => {
    //     setIsLoading(true);
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/companyClients/get`, {
    //         params: {
    // company_id: id,
    // case_id: caseID,
    //         }
    //     })
    //         .then(res => {
    //             setIsLoading(false);
    //             setData(res.data);
    //         }).catch(err => {
    //             setIsLoading(false);
    //             handelErrors(err);
    //         })

    // }
    const getData = async (searchTerm, source) => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            let chats = [];
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/companyClients/get`, {
                params: {
                    company_id: id,
                    case_id: caseID,
                    searchTerm: searchTerm,
                },
                cancelToken: source?.token,
            })
                .then(res => {
                    setData(res.data);
                    setIsLoading(false);
                    resolve();
                }).catch(err => {
                    handelErrors(err);
                    setIsLoading(false);
                })
        });
    }
    useEffect(() => {
        if (filterForm.name == '') {
            getData();
        }
    }, [filterForm.name]);

    const addForm = () => {
        setSelectedID('');
        setOpen(true);
    }

    const handleEdit = meta => {
        setSelectedID(meta.rowData[0]);
        setOpen(true);
    }
    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }


    const deleteForm = meta => {
        const id = meta.rowData[0];
        Swal.fire({
            title: 'Are you sure you want to delete ?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyClients/delete`,
                    data: { id: id },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: 'Success' });
                        getData();
                    })
                    .catch(error => {
                        handelErrors(error);
                    });
            }
        });
    };

    const handleOpen = (id, userID) => {
        setSelectedID(id);
        setSelectedUserID(userID);
        setShow('none');
        setShow2('');
    }

    const handleCloseDetails = () => {
        setShow('');
        setShow2('none');
    }

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        setFilterForm({
            ...filterForm,
            [id]: value,
        });
        if (value) {
            let source = previousController.current;
            source?.cancel("cancelled_cleanup");
            source = previousController.current = axios.CancelToken.source();
            if (previousController2.current) {
                clearTimeout(previousController2.current)
            }
            previousController2.current = setTimeout(() => {
                getData(value, source);
            }, 500)
        }
    }
    return (
        <div style={{ marginTop: '10px' }}>
            <Grid container>
                <Grid xs={5} md={7} style={{ display: `${show}` }}>
                    <CustomSearch
                        id={'name'}
                        value={filterForm.name}
                        onChange={handleInputChange}
                        placeholder={t('Search') + '...'}
                        withBorder={false}
                        endAdornment={
                            <img src={SearchSVG} />
                        }
                    />
                </Grid>
                <Grid xs={1} style={{ display: `${show}` }}>
                    <div style={{
                        height: '96%'
                        , display: 'flex'
                        , justifyContent: 'center'
                        , alignItems: 'center'
                        , cursor: 'pointer'
                    }}>
                        <img src={FilterSVG} width={40} />
                    </div>
                </Grid>
                {
                    targetPage?.can_add === 1 ?
                        <Grid item xs={4} md={2} style={{ display: `${show}` }}>
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <CustomAddButton onClick={addForm} title={t('New')+' '+t('Client')} />
                            </div>
                        </Grid>
                        :
                        null
                }

            </Grid>
            <Grid container style={{ marginTop: '10px', overflow: 'auto', height: '500px' }} spacing={1}>
                {
                    data?.map((e, key) => (
                        <Grid item xs={6} style={{ display: `${show}` }} key={key}>
                            <ClientCard onClick={() => handleOpen(e.company_client_id, e.user_id)} data={e} />
                        </Grid>
                    ))
                }
                <Grid item xs={12} style={{ display: `${show2}`, height: '100%' }}>
                    <ClientDetails id={selectedID} onClick={handleCloseDetails} />
                </Grid>
            </Grid>

            <AddUpdateClient
                open={open}
                id={selectedID}
                onClose={handleClose} />
        </div>
    )
}
export default CompanyClients