import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import CustomDialog from '../../components/Custom/CustomDialog';
import styles from '../../styles/login.module.css';
import { CustomInput, SingleDate } from '../../components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SingleCustomAutocomplete from '../../components/Custom/SingleCustomAutocomplete';
import CustomAutocompleteServerSide from '../../components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';
import { getDescription } from 'src/components/helpers/Functions';


const AddUpdateMileStone = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [mileStoneStatuses, setMileStoneStatuses] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [clients, setClients] = useState([]);

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID,
        name: '',
        due_date: new Date(),
        status: '',
        recipient: null,
        description: '',
        fees: '',
        currency: null,
        meeting_link: '',
        expenses: '',
        expenses_currency: null,
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID,
            name: '',
            recipient: null,
            due_date: new Date(),
            status: '',
            description: '',
            fees: '',
            currency: null,
            meeting_link: '',
            expenses: '',
            expenses_currency: null,
        });
    }

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: caseID,
                    recipient: null,
                    name: response.data.name,
                    due_date: response.data.due_date ? new Date(response.data.due_date) : null,
                    status: response.data.status,
                    description: response.data.description,
                    fees: response.data.fees,
                    currency: response.data.currency,
                    expenses: response.data.expenses,
                    meeting_link: response.data.meeting_link,
                    expenses_currency: response.data.expenses_currency,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open && mileStoneStatuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/MileStoneStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setMileStoneStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }
        if (props.open && currencies.length == 0) {
            const getCurrencies = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/currencies/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCurrencies(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCurrencies();
        }

        if (props.open && clients.length == 0) {
            const getClients = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/users/getClientsByCase`, {
                    params: {
                        case_id: caseID
                    },
                })
                    .then(function (response) {
                        setClients(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getClients();
        }
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            if (value && value !== "" && value !== null) {
                if (key == "due_date") {
                    formDataObj.append(key, moment(value).format('YYYY-MM-DD'));
                } else {
                    formDataObj.append(key, value);
                }
            }
        }
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/update`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('updated_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/add`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('insert_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };

    useEffect(() => {
        if (clients.length > 0) {
            setFormData({
                ...formData,
                recipient: clients[0].id
            });
        }
    }, [clients]);
    return (
        <Dialog
            maxWidth={'md'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('Milestone')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleDate
                            id="due_date"
                            label={t('due_date')}
                            value={formData.due_date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleCustomAutocomplete
                            filedName="status"
                            label={t('Status')}
                            list={mileStoneStatuses}
                            value={formData.status}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'description'}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={t('Description')}
                            withBorder={false}
                        />
                    </Grid>
                    {
                        clients.length > 1 ?
                            <Grid item xs={12} md={6}>
                                <SingleCustomAutocomplete
                                    filedName="recipient"
                                    label={t('Recipient')}
                                    list={clients}
                                    value={formData.recipient}
                                    listKey="id"
                                    description="concat_name"
                                    customOnChange={customOnChange}
                                />
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} md={3}>
                        <CustomInput
                            id={'fees'}
                            value={formData.fees}
                            onChange={handleInputChange}
                            placeholder={t('fees')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SingleCustomAutocomplete
                            filedName="currency"
                            label={t('Currency')}
                            list={currencies}
                            value={formData.currency}
                            listKey="code"
                            description="code"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CustomInput
                            id={'expenses'}
                            value={formData.expenses}
                            onChange={handleInputChange}
                            placeholder={t('Expenses')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SingleCustomAutocomplete
                            filedName="expenses_currency"
                            label={t('Currency')}
                            list={currencies}
                            value={formData.expenses_currency}
                            listKey="code"
                            description="code"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'meeting_link'}
                            value={formData.meeting_link}
                            onChange={handleInputChange}
                            placeholder={t('meeting_link')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid container style={{ marginBottom: '2%' }}>
                        <Grid item xs={0} md={8}></Grid>
                        <Grid item xs={12} md={4} marginTop={"20px"}>
                            <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddUpdateMileStone