import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { CustomInput, SingleDate } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocompleteServerSide from 'src/components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';


const AddUpdatePayments = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [saveIsLoadingDetails, setSaveIsLoadingDetails] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [invoices, setInvoices] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [RowID, setRowID] = useState(-1);

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID > 0 ? caseID : null,
        name: '',
        description: '',
        invoice_id: null,
        value: '',
        total_invoice: '',
        remaining:'',
        origin_remaining:'',
        date: new Date(),
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID > 0 ? caseID : null,
            name: '',
            description: '',
            invoice_id: null,
            value: '',
            total_invoice: '',
            remaining:'',
            origin_remaining:'',
            date: new Date(),
        });
    }

    useEffect(() => {
        if (currencies.length == 0) {
            const getCurrencies = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/currencies/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCurrencies(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCurrencies();
        }
        // if (invoices.length == 0) {
        const getInvoices = () => {
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/invoices/get`, {
                params: {
                    company_id: id,
                    case_id: caseID
                },
            })
                .then(function (response) {
                    setInvoices(response.data);
                })
                .catch(function (error) {
                    handelErrors(error)
                })
        }
        getInvoices();
        // }
    }, [props.open]);

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/payments/getById-${props.id}`,
        })
            .then(response => {
                const targetElement = invoices.find(e => e.id === response.data.invoice_id)
                let remaining = 0;
                let total_invoice = formData.total_invoice > 0 ? formData.total_invoice : 0;
                let total = formData.value > 0 ? formData.value : 0;
                // remaining  = total_invoice - (total + targetElement.remaining);
                // remaining = targetElement.remaining - total

                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: response.data.case_id,
                    name: response.data.name,
                    description: response.data.description,
                    invoice_id: response.data.invoice_id,
                    value: response.data.value,
                    total_invoice: targetElement.total,
                    // remaining:parseFloat(remaining).toFixed(2),
                    remaining : targetElement.remaining,
                    origin_remaining:targetElement.remaining,
                    date: response.data.date ? new Date(response.data.date) : null,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {

        if(name =="invoice_id"){
            const targetElement = invoices.find(e => e.id === newValue)
            setFormData({
                ...formData,
                total_invoice: targetElement.total,
                origin_remaining: targetElement.remaining,
                [name]: newValue,
            });
        }else{
            setFormData({
                ...formData,
                [name]: newValue,
            });
        }
        
    }


    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/payments/update`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('updated_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/payments/add`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('insert_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };

    useEffect(()=>{
        let remaining = 0;
        let origin_remaining = formData.origin_remaining > 0 ? formData.origin_remaining : 0 ;
        let total_invoice = 0;
        if(formData.id > 0){
            total_invoice= formData.total_invoice > 0 ? formData.total_invoice : 0;
        }else{
            total_invoice = formData.origin_remaining > 0 ? formData.origin_remaining : 0;
        }
        let total = formData.value > 0 ? formData.value : 0;
        remaining  = total_invoice - total;
        setFormData({
            ...formData,
            remaining: parseFloat(remaining).toFixed(2),
        })
    },[formData.total_invoice,formData.value]);
    return (
        // <Dialog
        //     maxWidth={'sm'}
        //     open={props.open}
        //     onClose={props.onClose}
        //     scroll='body'
        // >
            
        // </Dialog>
        <CustomNewDialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('Payment')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'description'}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={t('Description')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SingleCustomAutocomplete
                            filedName="invoice_id"
                            label={t('Invoice')}
                            list={invoices}
                            value={formData.invoice_id}
                            listKey="id"
                            description="name"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SingleDate
                            id="date"
                            label={t('Date')}
                            value={formData.date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            id={'value'}
                            value={formData.value}
                            onChange={handleInputChange}
                            placeholder={t('Value')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <span style={{ color: '#650E3D', fontSize: '24px', fontWeight: '400' }}>{t('Remaining')}</span>
                        <span style={{ float: i18n.language == "ar" ? 'left': 'right', color: '#650E3D', fontSize: '24px', fontWeight: '700' }}>
                            {formData.remaining >= 0 ? formData.remaining : formData.origin_remaining}
                        </span>
                    </Grid>
                    <Grid item xs={12} md={6} marginTop={"50px"} marginLeft={"auto"} marginRight={"auto"} marginBottom={'2%'}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </CustomNewDialog>
    )
}
export default AddUpdatePayments