import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, List, Popover, Tooltip, Typography } from '@mui/material';
import CustomHeader from '../../components/Header/CustomHeader';
import CustomTabs from '../../components/Custom/CustomTabs';
import CustomDataTable from '../../components/Custom/CustomDataTable';
import CustomDates from 'src/components/Custom/CustomDates';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddUpdateMileStone from './AddUpdateMileStone';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomButton from 'src/components/Custom/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpdateStatus from './UpdateStatus';
import WorkGroup from './WorkGroup';
import Swal from 'sweetalert2';
import Tasks from 'src/views/Tasks/Tasks'
import Folders from 'src/views/Folders/Folders'
import MeetingsTable from 'src/views/Meetings/MeetingsTable'
import Meetings from 'src/views/Meetings/Meetings'
import EditSVG from 'src/images/edit_svg.svg'
// import TrashSVG from 'src/images/trash_svg.svg'
import SearchSVG from 'src/images/search_svg.svg'
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import DeleteSVG from 'src/images/carbon_delete.svg'
import Financial from '../Financial/Financial';
import ClientsLogo from 'src/images/contact_svg.svg';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import CaseChats from '../Chats/CaseChats';
import Hearings from '../Hearings/Hearings';
import CustomToast from 'src/components/Custom/CustomToast';
import { FileCopy } from '@mui/icons-material';
import NotificationFilledSVG from 'src/images/notification_filled_svg.svg';
import NotificationSVG from 'src/images/notification_svg.svg';
import MileStoneFilesModal from './MileStoneFilesModal';
import AddReminder from './AddReminder';
import { getValue } from 'src/components/helpers/Functions';

const currentDate = new Date();


const CustomClient = props => {
    return (
        <div className='d-flex  align-items-center' style={{
            marginRight: '15px'
            , paddingTop: '7px'
            , paddingBottom: '7px'
        }}>
            <img src={ClientsLogo} className='image-fit-cover' width={25} height={25} />
            <span style={{ marginLeft: '5px', fontSize: '16px' }}>{props.data}</span>
        </div>
    )

}

const MileStones = (props) => {
    const [tabSelected, setTabSelected] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [caseDataIsLoading, setCaseDataIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [isLoadingMeetings, setIsLoadingMeetings] = useState(false);
    const [openMeetings, setOpenMeetings] = useState(false);
    const [meetings, setMeetings] = useState([]);
    const [data, setData] = useState([]);
    const [caseData, setCaseData] = useState([]);
    const [CaseClients, setCaseClients] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [mileStoneStatuses, setMileStoneStatuses] = useState([]);
    const [openFilesModal, setOpenFilesModal] = useState(false);
    const [openReminder, setOpenReminder] = useState(false);
    const navigate = useNavigate();
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'case_milestones')
    const targetPageTasks = presetPages.find(e => e.page_code === 'case_tasks')
    const targetPageTeam = presetPages.find(e => e.page_code === 'case_team')
    const targetPageChats = presetPages.find(e => e.page_code === 'case_chats')
    const targetPageMeetings = presetPages.find(e => e.page_code === 'case_meetings')
    const targetPageFiles = presetPages.find(e => e.page_code === 'case_files')
    const targetPageHearings = presetPages.find(e => e.page_code === 'case_hearings')
    const targetPageExpenses = presetPages.find(e => e.page_code === 'case_financial_expenses')
    const targetPageFinancials = presetPages.find(e => e.page_code === 'case_financials')

    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
    }
    const [anchorEl, setAnchorEl] = useState(null);
    let openPop = Boolean(anchorEl);
    const id_pop = openPop ? 'simple-popover' : undefined;

    const handleOpenPop = (event, id) => {
        setSelectedID(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClosePop = () => {
        setAnchorEl(null);
    };
    const [filterForm, setFilterForm] = useState({
        fromDate: null,
        toDate: null,
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
    });
    const getMeetings = () => {
        setIsLoadingMeetings(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/meetings/getAllByMonthYear`, {
            params: {
                month: filterForm.month,
                year: filterForm.year,
                company_id: id,
                case_id: caseID,
            }
        })
            .then(res => {
                setIsLoadingMeetings(false);
                const data = res.data;
                const arr = [];
                if (data.length > 0) {
                    data.map((e) => {
                        arr.push(e.start);
                    })
                }
                setMeetings(arr);
            }).catch(err => {
                setIsLoadingMeetings(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        getMeetings();
    }, [filterForm.month, filterForm.year]);

    const getCaseData = () => {
        setCaseDataIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/getById-${caseID}`, {
            params: {}
        })
            .then(res => {
                setCaseData(res.data);
                setCaseClients(res.data.clients_full_name ? res.data.clients_full_name.split(",") : []);
                setCaseDataIsLoading(false);
            }).catch(err => {
                handelErrors(err);
                setCaseDataIsLoading(false);
            })
    }

    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/get`, {
            params: {
                case_id: caseID,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })

    }
    useEffect(() => {
        if (caseData.length == 0) {
            getCaseData();
        }
        if (tabSelected == 0) {
            if (mileStoneStatuses.length == 0) {
                const getStatuses = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/MileStoneStatuses/get`, {
                        params: {},
                    })
                        .then(function (response) {
                            setMileStoneStatuses(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getStatuses();
            }
            getData();
        }
    }, [tabSelected]);

    const openStatusForm = meta => {
        setSelectedID(meta.rowData[0]);
        setOpenStatus(true);
    }
    const handleCloseStatus = (refresh) => {
        setOpenStatus(false);
        if (refresh) {
            getData();
        }
    }

    const openFiles = (meta) => {
        setSelectedID(meta.rowData[0]);
        setOpenFilesModal(true);
    }

    const closeFiles = (meta) => {
        setSelectedID("");
        setOpenFilesModal(false);
    }
    const addReminder = (meta) => {
        setSelectedID(meta.rowData[0]);
        setOpenReminder(true);
    }
    const handleCloseReminder = (refresh) => {
        setOpenReminder(false);
        if (refresh == true) {
            getData();
        }
    }

    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'color_code',
            label: 'color',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'reminder',
            label: 'Reminder',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'milestone',
            label: t('MileStone'),
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div className='d-flex  align-items-center' style={{ width: '100%', height: '100%' }}>
                            <div style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => handleEdit(data[dataIndex].id)}>
                                <img src={EditSVG} width={20} />
                            </div>
                            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div className='d-flex  flex-row'>
                                    {
                                        data[dataIndex].expenses > 0
                                            ?
                                            <>
                                                <div className='d-flex flex-column' style={{ marginRight: '20px' }}>
                                                    <span className="colored-text"
                                                        style={{ fontSize: '7px', textTransform: 'uppercase', fontWeight: '700' }}>
                                                        {t('Expenses')}
                                                    </span>
                                                    <span className='colored-text'>
                                                        {data[dataIndex].expenses > 0 ? data[dataIndex].expenses : 0} {data[dataIndex].expenses_currency}
                                                    </span>
                                                </div>
                                            </>
                                            : null
                                    }
                                    {
                                        data[dataIndex].fees > 0 ?
                                            <>
                                                <div className='d-flex flex-column'>
                                                    <span className="colored-text"
                                                        style={{ fontSize: '7px', textTransform: 'uppercase', fontWeight: '700' }}>
                                                        {t('Fees')}
                                                    </span>
                                                    <span className='colored-text'>
                                                        {data[dataIndex].fees > 0 ? data[dataIndex].fees : 0} {data[dataIndex].currency}
                                                    </span>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>
                                <span className='colored-text' style={{ fontSize: '20px' }}>
                                    {data[dataIndex].name}
                                </span>
                                <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '10px', fontWeight: '700' }}>
                                    {t('due_date')} {moment(data[dataIndex].due_date).format('MMMM Do YYYY, h:mm:ss a')}
                                </span>
                                {
                                    data[dataIndex].description != null && data[dataIndex].description != ''
                                        ?
                                        <div style={{ width: '100%', minHeight: '50px', backgroundColor: '#F4F4F4' }} className='d-flex justify-content-center  align-items-center'>
                                            <div style={{
                                                width: '90%'
                                                , marginLeft: 'auto'
                                                , marginRight: 'auto'
                                                , color: 'rgba(101, 14, 61, 0.4)'
                                                , lineHeight: '18px', fontSize: '12px'
                                            }}>
                                                {data[dataIndex].description}
                                            </div>
                                        </div>
                                        : null
                                }

                            </div>
                        </div>
                    )
                }
            }
        },
        {
            name: "status",
            label: t('Status'),
            options: {
                filter: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    const targetElement = mileStoneStatuses?.find(e => e.id == value)
                    return (
                        <>
                            <div aria-describedby={id_pop}>
                                <CustomButton variant="outlined"
                                    style={{
                                        height: '40px'
                                        , minwidth: '150px'
                                        , border: `2px solid #650E3D`
                                        , color: `#650E3D`
                                        , borderRadius: '30px'
                                        , fontSize: '12px'
                                        , fontWeight: '700'
                                        , textAlign: 'left'
                                        , justifyContent: 'left'
                                    }}
                                    title={
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                                                <span style={{
                                                    fontWeight: '700'
                                                    , fontSize: '7px'
                                                    , textTransform: 'capitalize'
                                                    , lineHeight: '3px'
                                                    , marginTop: '7px'
                                                }}>
                                                    {t('status')}
                                                </span>
                                                <span style={{ color: `${meta.rowData[1]}` }}>
                                                    {/* {value} */}
                                                    {
                                                        getValue(targetElement,'name',i18n.language)
                                                    }
                                                </span>
                                            </div>
                                        </>
                                    }
                                    onClick={(e) => handleOpenPop(e, meta.rowData[0])}
                                    endIcon={<KeyboardArrowDownIcon style={{ color: `#650E3D`, marginTop: '5px' }} />}
                                />
                                <Popover
                                    id={id_pop}
                                    open={openPop}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePop}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <List xs={{ pt: 0 }}>
                                        {
                                            saveIsLoading ?
                                                <CircularProgress />
                                                :
                                                mileStoneStatuses.map((e, key) => (
                                                    <Typography key={key} onClick={() => changeStatus(e.id)}
                                                        sx={{ p: 2, cursor: 'pointer', color: `${e.color_code}` }}>{getValue(e,'name',i18n.language)}</Typography>
                                                ))
                                        }
                                    </List>
                                </Popover>
                            </div>

                        </>
                    )
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            <div className='d-flex '>
                                <div style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => deleteForm(meta)}>
                                    <img src={DeleteSVG} width={20} />
                                </div>
                                <div style={{ marginRight: '20px', cursor: 'pointer' }}>
                                    <FileCopy style={{ color: "#650E3D", fontSize: "1.5em" }} onClick={() => openFiles(meta)} />
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={() => addReminder(meta)}>
                                    {
                                        meta.rowData[2] > 0 ? <img src={NotificationFilledSVG} width={18} /> : <img src={NotificationSVG} width={18} />
                                    }
                                </div>
                                {/* <div style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => doanloadAttachement(meta)}>
                                    <img src={DownloadSVG} width={20} />
                                </div> */}
                            </div>
                        </>
                    )
                },
            },
        },
    ];

    const options = {
        filter: false,
        print: false,
        download: true,
        viewColumns: false,
        rowsPerPage: 100,
        viewColumns: false,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (<span>
                {/* <Tooltip title={"Add"}>
                    <IconButton onClick={addForm}>
                        <AddIcon style={{ color: '#650E3D' }} />
                    </IconButton>
                </Tooltip> */}
                <span style={{ marginLeft: '10px' }}><CustomAddButton onClick={addForm} title={t('New') + ' ' + t('MileStone')} /></span>
            </span>);
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const addForm = () => {
        setSelectedID('');
        setOpen(true);
    }

    const handleEdit = id => {
        setSelectedID(id);
        setOpen(true);
    }
    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }


    const deleteForm = meta => {
        const id = meta.rowData[0];
        Swal.fire({
            title: t('delete_mssg'),
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/delete`,
                    data: { id: id },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        getData();
                    })
                    .catch(error => {
                        handelErrors(error)
                    });
            }
        });
    };
    const handleMeetings = () => {
        setOpenMeetings(true);
    }
    const handleCloseMeetings = () => {
        setOpenMeetings(false);
    }
    const onChangeDate = (selectedDates) => {
        let start = null;
        let end = null;
        let startDate = null;
        let endDate = null;
        start = selectedDates;
        end = selectedDates;
        startDate = new Date(start).setHours(0, 0, 0, 0);
        endDate = new Date(end).setHours(0, 0, 0, 0);
        setFilterForm({
            ...filterForm,
            fromDate: startDate,
            toDate: endDate,
        });
        handleMeetings();
    }
    const handleYearChange = (year) => {
        setFilterForm({
            ...filterForm,
            year: year
        });
    }
    const handleMonthChange = (month) => {
        setFilterForm({
            ...filterForm,
            month: month
        });
    }
    const changeStatus = (status) => {
        // console.log(id,status);return;
        setSaveIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/updateStatus`,
            data: { id: selectedID, status: status },
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('updated_mssg') });
                setSaveIsLoading(false);
                handleClosePop();
                getData();
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    };

    return (
        <div>
            <div style={{ marginTop: '40px' }}>
                {/* <CustomHeader /> */}
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <div style={{
                            marginTop: '20px'
                            , display: 'flex', alignItems: 'center'
                        }}>
                            <div style={{ cursor: 'pointer', padding: '2px', height: '100%', position: 'relative' }} onClick={() => navigate(-1)}>
                                {
                                    i18n.language == "ar" ?
                                        <ArrowForwardIosIcon style={{ color: '#650E3D', fontSize: '20px' }} />
                                        :
                                        <ArrowBackIosIcon style={{ color: '#650E3D', fontSize: '20px' }} />

                                }
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '18px' }}>
                                <span style={{ fontSize: '11px', fontWeight: '700', color: '#650E3D', textTransform: 'uppercase' }}>{t('Case')} {caseID}</span>
                                <span style={{ fontSize: '13px', fontWeight: '700', color: '#650E3D' }}>{caseData.name}</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9} style={{ marginTop: '-20px' }} order={{ xs: 2, md: 1 }}>
                        <Grid container>
                            <Grid item xs={10} md={10}>
                                <CustomTabs
                                    tabs={[
                                        {
                                            key: 0, label: t('Milestones'), display: true
                                        },
                                        {
                                            key: 1, label: t('Tasks'), display: targetPageTasks?.can_view === 1 ? true : false
                                        },
                                        {
                                            key: 2, label: t('Team'), display: targetPageTeam?.can_view === 1 ? true : false
                                        },
                                        {
                                            key: 3, label: t('Chat'), display: targetPageChats?.can_view === 1 ? true : false
                                        },
                                        {
                                            key: 4, label: t('Meetings'), display: targetPageMeetings?.can_view === 1 ? true : false
                                        },
                                        {
                                            key: 5, label: t('Files'), display: targetPageFiles?.can_view === 1 ? true : false
                                        },
                                        {
                                            key: 6, label: t('Financial'), display: targetPageFinancials?.can_view === 1 ? true : false
                                        },
                                        {
                                            key: 7, label: t('Hearings'), display: targetPageHearings?.can_view === 1 ? true : false
                                        },
                                    ]} tabValue={tabSelected} onTabChange={onTabChange} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {
                                    tabSelected === 0
                                        ?
                                        <>
                                            <CustomDataTable
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                            <MileStoneFilesModal
                                                open={openFilesModal}
                                                onClose={closeFiles}
                                                id={selectedID}
                                            />
                                        </>
                                        : tabSelected === 2 ?
                                            <WorkGroup />
                                            : tabSelected === 1 ?
                                                <Tasks />
                                                : tabSelected === 5 ?
                                                    <Folders />
                                                    : tabSelected === 4 ?
                                                        <MeetingsTable />
                                                        : tabSelected === 6 ?
                                                            <Financial />
                                                            : tabSelected === 3 ?
                                                                <CaseChats />
                                                                : tabSelected === 7 ?
                                                                    <Hearings />
                                                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ marginTop: '-20px' }} order={{ xs: 1, md: 2 }}>
                        <Grid item xs={12}>
                            <div className="d-flex  align-items-center" style={{ height: '100%' }}>
                                <span style={{ color: '#650E3D', fontSize: '24px' }} >{t('Case')} {t('Details')}</span>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{
                                height: '100%', width: '100%', marginTop: '40px', borderRadius: '10px'
                                , backgroundColor: 'white', display: 'flex', flexDirection: 'column'
                                , alignItems: 'center', justifyContent: 'center'
                            }}>
                                <div className='d-flex  align-items-center' style={{ width: '100%', height: '100%' }}>
                                    <div style={{
                                        height: '100%'
                                        , width: '80%'
                                        , marginLeft: 'auto'
                                        , marginRight: 'auto'
                                        , marginTop: '10px'
                                        , display: 'flex'
                                        , flexDirection: 'column'
                                        , justifyContent: 'center'
                                    }}>

                                        <div>
                                            <Grid container>
                                                {
                                                    caseDataIsLoading ? <CircularProgress />
                                                        :
                                                        CaseClients.map((e) => (
                                                            <Grid item xs={12}>
                                                                <CustomClient data={e} />
                                                            </Grid>

                                                        ))
                                                }

                                            </Grid>

                                        </div>
                                        <span className='colored-text'>
                                            {caseData.total_fees} USD
                                        </span>
                                        <span className='colored-text' style={{ fontSize: '20px', fontWeight: '500' }}>
                                            {caseData.name}
                                        </span>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '10px', fontWeight: '700' }}>
                                            {t('Created')} {t('Date')} {moment(caseData.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                        </span>
                                    </div>
                                </div>
                                <CustomDates
                                    onChangeDate={onChangeDate}
                                    handleYearChange={handleYearChange}
                                    handleMonthChange={handleMonthChange}
                                    data={meetings}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <AddUpdateMileStone
                open={open}
                id={selectedID}
                onClose={handleClose} />
            <UpdateStatus
                open={openStatus}
                id={selectedID}
                onClose={handleCloseStatus}
            />
            <Meetings open={openMeetings}
                onClose={handleCloseMeetings}
                filterForm={filterForm} />
            <AddReminder
                open={openReminder}
                id={selectedID}
                onClose={handleCloseReminder}
            />
        </div>
    )
}
export default MileStones