import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import CustomDataTable from 'src/components/Custom/CustomDataTable'
import FilterSVG from 'src/images/filter_svg.svg'
import SearchSVG from 'src/images/search_svg.svg'
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import AddUpdateEmployee from './AddUpdateEmployee';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import EditSVG from 'src/images/edit_svg.svg'
import Swal from 'sweetalert2';
import DeleteSVG from 'src/images/carbon_delete.svg'
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import { toast } from 'react-toastify';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import CustomToast from 'src/components/Custom/CustomToast';
import { useTranslation } from 'react-i18next';
import EmployeeCard from './EmployeeCard';
import EmployeeDetails from './EmployeeDetails/EmployeeDetails';

const Employees = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [selectedID, setSelectedID] = useState(-1);
    const [show, setShow] = useState('');
    const [show2, setShow2] = useState('none');
    const [data, setData] = useState([])
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'company_employees')

    const previousController = useRef();
    const previousController2 = useRef();
    const [filterForm, setFilterForm] = useState({
        name: ''
    });
    const getData = async (searchTerm, source) => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            let chats = [];
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/companyEmployees/get`, {
                params: {
                    company_id: id,
                    searchTerm: searchTerm,
                },
                cancelToken: source?.token,
            })
                .then(res => {
                    setData(res.data);
                    setIsLoading(false);
                    resolve();
                }).catch(err => {
                    handelErrors(err);
                    setIsLoading(false);
                })
        });
    }
    useEffect(() => {
        if (filterForm.name == '') {
            getData();
        }
    }, [filterForm.name]);
    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        setFilterForm({
            ...filterForm,
            [id]: value,
        });
        if (value) {
            let source = previousController.current;
            source?.cancel("cancelled_cleanup");
            source = previousController.current = axios.CancelToken.source();
            if (previousController2.current) {
                clearTimeout(previousController2.current)
            }
            previousController2.current = setTimeout(() => {
                getData(value, source);
            }, 500)
        }
    }
    const addForm = () => {
        setSelectedID(-1);
        setOpen(true);
    }

    const handleEdit = id => {
        setSelectedID(id);
        setOpen(true);
    }

    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }

    const deleteForm = meta => {
        const id = meta.rowData[0];
        Swal.fire({
            title: t('delete_mssg'),
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyEmployees/delete`,
                    data: { id: id },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        getData();
                    })
                    .catch(error => {
                        handelErrors(error);
                    });
            }
        });
    };
    const handleOpen = (id) => {
        setSelectedID(id);
        setShow('none');
        setShow2('');
    }

    const handleCloseDetails = () => {
        setShow('');
        setShow2('none');
    }
    return (
        <div style={{ marginTop: '10px' }}>
            <Grid container >
                <Grid xs={5} md={7} style={{ display: `${show}` }}>
                    <CustomSearch
                        id={'name'}
                        value={filterForm.name}
                        onChange={handleInputChange}
                        placeholder={t('Search') + '...'}
                        withBorder={false}
                        endAdornment={
                            <img src={SearchSVG} />
                        }
                    />
                </Grid>
                <Grid xs={1} style={{ display: `${show}` }}>
                    <div style={{
                        height: '96%'
                        , display: 'flex'
                        , justifyContent: 'center'
                        , alignItems: 'center'
                        , cursor: 'pointer'
                    }}>
                        <img src={FilterSVG} width={40} />
                    </div>
                </Grid>
                {
                    targetPage?.can_add === 1 ?
                        <Grid item xs={4} md={2} style={{ display: `${show}` }}>
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <CustomAddButton onClick={addForm} title={t('New')+' '+t('Employee')} />
                            </div>
                        </Grid>
                        : null
                }

            </Grid>
            <Grid container style={{ marginTop: '10px', overflow: 'auto', height: show == '' ? '400px' : '450px' }} spacing={1}>
                {
                    data?.map((e, key) => (
                        <Grid item xs={12} sm={6} md={4} style={{ display: `${show}` }} key={key}>
                            <EmployeeCard onClick={() => handleOpen(e.id)} data={e} handleEdit={()=>handleEdit(e.company_employee_id)} />
                        </Grid>
                    ))
                }
                <Grid item xs={12} style={{ display: `${show2}`, height: '100%' }}>
                    <EmployeeDetails
                        id={selectedID}
                        show={show2}
                        onClick={handleCloseDetails}
                    />
                </Grid>
            </Grid>
            <AddUpdateEmployee
                open={open}
                id={selectedID}
                onClose={handleClose} />
        </div>
    )
}
export default Employees