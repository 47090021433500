import React, { useState } from 'react'
import { Grid } from '@mui/material';
import CustomDialog from '../../components/Custom/CustomDialog';
import styles from '../../styles/login.module.css';
import CustomSignup from '../../components/Custom/CustomSignup';
import MapClient from '../../images/map_client.svg';
import MapLawyer from '../../images/map_lawyer.svg';
import { useNavigate } from 'react-router-dom'

const SignupForm = (props) => {
    const navigate = useNavigate();

    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={'xs'}
        >
            <div >
                <p className={styles.text}>I'am a:</p>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <CustomSignup src={MapLawyer} title={'Lawyer'}  onClick={()=>navigate('/LawyerSignup')}/>
                    </Grid>
                    <Grid item xs={12} md={6} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <CustomSignup src={MapClient} title={'Client'}  onClick={()=>navigate('/ClientSignup')}/>
                    </Grid>
                </Grid>
            </div>
        </CustomDialog>
    )
}
export default SignupForm