import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomDateTimePicker, CustomInput, SingleDate, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';


const UpdateReschedule = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID > 0 ? caseID : null,
        reason: '',
        hearing_id: null,
        date: new Date(),
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID > 0 ? caseID : null,
            reason: '',
            hearing_id: props.id,
            date: new Date(),
        });
    }

    useEffect(() => {
        if (props.open) {
            resetForm();
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/rescheduleDate`,
            data: formData,
        })
            .then(response => {
                CustomToast({type:'success',message : t('insert_mssg')});
                setSaveIsLoading(false);
                props.onClose(true);
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    };

    return (
        <Dialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-2' style={{ color: '#650E3D' }}>{t('reschedule')+' '+t('Hearing')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomDateTimePicker
                            id="date"
                            label={t('Datetime')}
                            value={formData.date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'reason'}
                            value={formData.reason}
                            onChange={handleInputChange}
                            placeholder={t('Reason')}
                            withBorder={false}
                            multiline={true}
                            minRows={4}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"} marginBottom={'2%'}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default UpdateReschedule