import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { CustomInput } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import SearchSVG from 'src/images/search_svg.svg'
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import AddIcon from '@mui/icons-material/Add'
import EditSVG from 'src/images/edit_svg.svg'
import DeleteSVG from 'src/images/carbon_delete.svg'
import Swal from 'sweetalert2';
import AddUpdateSubTasks from './AddUpdateSubTasks';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import CustomToast from 'src/components/Custom/CustomToast';

const SubTasksForm = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [selectedID, setSelectedID] = useState('');
    const [open, setOpen] = useState(false);
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPageSubtasks = presetPages.find(e => e.page_code === 'subtasks')
    const targetPageCase = presetPages.find(e => e.page_code === 'case_subtasks')


    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/getSubTasks-${props.id}`, {
            params: {
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })

    }

    useEffect(() => {
        if (props.open) {
            getData();
        }
    }, [props.open]);

    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: t('name'),
        },
        {
            name: 'description',
            label: t('Description'),
        },
        {
            name: 'clients_full_name',
            label: t('assign_to_'),
        },
        {
            name: 'status_name',
            label: t('Status'),
        },
        {
            name: "actions",
            label: t('Actions'),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            {
                                (targetPageSubtasks?.can_edit === 1 && targetPageSubtasks?.can_view === 1)
                                    ||
                                    (targetPageCase?.can_edit === 1 && targetPageCase?.can_view === 1)
                                    ?
                                    <Tooltip title={t('Edit')}>
                                        <IconButton onClick={() => handleEdit(meta)}>
                                            <img src={EditSVG} />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                            }
                            {
                                (targetPageSubtasks?.can_delete === 1 && targetPageSubtasks?.can_view === 1)
                                    ||
                                    (targetPageCase?.can_delete === 1 && targetPageCase?.can_view === 1)
                                    ?
                                    <Tooltip title={t('Delete')}>
                                        <IconButton onClick={() => deleteForm(meta)}>
                                            <img src={DeleteSVG} />
                                        </IconButton>
                                    </Tooltip> : null
                            }

                        </>
                    )
                },
            },
        },
    ];

    const options = {
        filter: false,
        print: false,
        download: true,
        viewColumns: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (<span>
                {
                    (targetPageSubtasks?.can_add === 1 && targetPageSubtasks?.can_view === 1)
                    ||
                    (targetPageCase?.can_add === 1 && targetPageCase?.can_view === 1)
                    ?
                        <span style={{ marginLeft: '10px' }}>
                            <CustomAddButton onClick={addForm} title={t('New')+' '+t('SubTask')} />
                        </span>
                        : null
                }

            </span>);
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const addForm = () => {
        setSelectedID('');
        setOpen(true);
    }

    const handleEdit = meta => {
        setSelectedID(meta.rowData[0]);
        setOpen(true);
    }
    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }

    const deleteForm = meta => {
        const id = meta.rowData[0];
        Swal.fire({
            title: t('delete_mssg'),
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/deleteSubTasks`,
                    data: { id: id },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        getData();
                    })
                    .catch(error => {
                        handelErrors(error);
                    });
            }
        });
    };
    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{t('Subtask')}</div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomDataTable
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                    <AddUpdateSubTasks
                        open={open}
                        id={selectedID}
                        onClose={handleClose}
                        taskID={props.id} />

                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default SubTasksForm