import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import routes from '../route'
import { CircularProgress } from '@mui/material'
import CustomHeader from './Header/CustomHeader'
import Home from 'src/views/Home/Home'
import Dashboard from 'src/views/Dashboard/Dashboard'
const AppContent = () => {
    const presetPagesCodes = JSON.parse(localStorage.getItem("preset_pages_codes"));
    console.log("presetPagesCodes");
    console.log(presetPagesCodes);
    return (
        <Suspense fallback={<CircularProgress color="primary" />}>
            <div style={{ backgroundColor: '#F4F4F4', minHeight: '100vh' }}>
                <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                    <CustomHeader />
                    <Routes>
                        {routes.map((route, idx) => {
                            if (!presetPagesCodes.includes(route.code)){
                                return null;
                            }
                            return (
                                route.component && (
                                    <>
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            element={route.component}
                                        /></>
                                )
                            )
                        })
                        }
                    </Routes>
                </div>
            </div>

        </Suspense>
    )
}
export default AppContent