import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomDateTimePicker, CustomInput, SingleDate } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CustomAutocompleteServerSide from 'src/components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { statuses } from 'src/components/helpers/FixedData';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';
import { getDescription } from 'src/components/helpers/Functions';


const AddUpdateTasks = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [taskStatuses, setTasksStatuses] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [cases, setCases] = useState([]);

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID > 0 ? caseID : null,
        name: '',
        description: '',
        assign_to: [],
        due_date: null,
        status: null,
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID > 0 ? caseID : null,
            name: '',
            description: '',
            assign_to: [],
            due_date: null,
            status: null,
        });
    }

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: response.data.case_id,
                    name: response.data.name,
                    status: response.data.status,
                    description: response.data.description,
                    status: response.data.status,
                    assign_to: response.data.assign_to ? response.data.assign_to.split(',') : [],
                    due_date: response.data.due_date ? new Date(response.data.due_date) : null,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open && taskStatuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/TaskStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setTasksStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }

        if (props.open && cases.length == 0) {
            const getCases = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
                    params: {
                        company_id: id
                    },
                })
                    .then(function (response) {
                        setCases(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCases();
        }
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const saveForm = () => {
        const formDataObj = new FormData();
        // for (const [key, value] of Object.entries(formData)) {
        //     if (value && value !== "" && value !== null) {
        //         if (Array.isArray(value)) {
        //             let arr = [];
        //             value.forEach(element => {
        //                 arr.push(element);
        //             });
        //             formDataObj.append(`${key}`, JSON.stringify(arr));
        //         }
        //         else {
        //             // if (key == "due_date") {
        //             //     formDataObj.append(key, moment(value).format('YYYY-MM-DD'));
        //             // } else {
        //                 formDataObj.append(key, value);
        //             // }
        //         }
        //     }
        // }
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/update`,
                data: formData,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('updated_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/add`,
                data: formData,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('insert_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };
    return (
        <CustomNewDialog
            maxWidth={'sm'}
            open={props.open}
            onClose={props.onClose}>
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('Task')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={false}
                        />
                    </Grid>
                    {
                        caseID > 0 ?
                            null
                            :
                            <Grid item xs={12} md={6}>
                                <SingleCustomAutocomplete
                                    filedName="case_id"
                                    label={t('Case')}
                                    list={cases}
                                    value={formData.case_id}
                                    listKey="id"
                                    description="name"
                                    customOnChange={customOnChange}
                                />
                            </Grid>
                    }
                    <Grid item xs={12}>
                        <CustomAutocompleteServerSide
                            filedName="assign_to"
                            label={t('assign_to')}
                            values={formData.assign_to}
                            listKey="user_id"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={formData.case_id > 0 ? "/masterData/lawyers/getDataBySearchQuery" : "/masterData/companyEmployees/getDataBySearchQuery"}
                            inTeam={true}
                            companyID={id}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleCustomAutocomplete
                            filedName="status"
                            label={t('Status')}
                            list={taskStatuses}
                            value={formData.status}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomDateTimePicker
                            id="due_date"
                            label={t('due_date')}
                            value={formData.due_date}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'description'}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={t('Description')}
                            withBorder={false}
                            multiline={true}
                            minRows={2}
                        />
                    </Grid>
                    <Grid container style={{ marginBottom: '2%' }}>
                        <Grid item xs={0} md={6}></Grid>
                        <Grid item xs={12} md={6} marginTop={"20px"}>
                            <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </CustomNewDialog>
    )
}
export default AddUpdateTasks