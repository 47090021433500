import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteSVG from 'src/images/carbon_delete.svg'
import UserSettingsSVG from 'src/images/user_settings_svg.svg'
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import PermissionTable from './PermissionTable';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import AddUpdatePerset from './AddUpdatePerset';


const CustomPreset = props => {
    return (
        <div style={{
            height: '60px'
            , backgroundColor: 'rgba(248, 248, 248, 1)'
            , width: '100%'
            , marginLeft: 'auto'
            , marginRight: 'auto'
            , marginTop: '10px'
            , borderRadius: '5px'
            , cursor: 'pointer'
        }} onClick={()=>props.onClick(props.data?.id,props.data?.name)}>
            <div style={{
                width: '88%'
                , marginLeft: 'auto'
                , marginRight: 'auto'
                , display: 'flex'
                , alignItems: 'center'
                , height: '100%'
            }}>
                <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <span style={{ fontWeight: '500', fontSize: '16px', color: '#650E3D', textTransform: 'uppercase' }}>{props.data?.name}</span>
                </div>
                <div style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={DeleteSVG} />
                </div>
            </div>
        </div>
    )

}

const AccountsAndPermissions = () => {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const [selectedID, setSelectedID] = useState({
        id:-1,
        name:''
    });
    const [open, setOpen] = useState(false)
    const [openAction, setOpenAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/presets/get`, {
            params: {
                company_id: id,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        getData();
    }, []);

    const handlePages=(id,name)=>{
        setSelectedID({
            id:id,
            name:name
        });
        setOpen(true);
    }
    const addForm=()=>{
        setOpenAction(true);
    }
    const handleCloseAction=(refresh)=>{
        setOpenAction(false);
        if(refresh == true){
            getData();
        }
    }

    return (
        <div style={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div style={{
                        height: '62vh'
                        , backgroundColor: 'rgba(255, 255, 255, 1)'
                        , borderRadius: '10px'
                    }}>
                        <div style={{ padding: '20px', height: '78%' }}>
                            <p style={{ fontSize: '24px', color: '#650E3D' }}>{t('Presets')}</p>
                            <div style={{ height: '100%', overflow: 'auto' }}>
                                {
                                    isLoading ? <CircularProgress />
                                        :
                                        data.map((e,key) => (
                                            <CustomPreset data={e} onClick={handlePages} key={key}/>
                                        ))
                                }
                            </div>
                        </div>
                        <div style={{
                            height: '17%'
                            , padding: '20px'
                            , marginTop: '25px'
                        }}>
                            <CustomAddButton variant="outlined" title={t('Add')+' '+t('Preset')} width={'100%'} onClick={addForm} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div style={{
                        height: '62vh'
                        , backgroundColor: 'rgba(255, 255, 255, 1)'
                        , borderRadius: '10px'
                    }}>
                        {
                            open ? 
                            <PermissionTable open={open} selected={selectedID}/>
                            : 
                            <div style={{display:'flex'
                                        ,justifyContent:'center'
                                        ,alignItems:'center'
                                        ,height:'100%'}}>
                                <img src={UserSettingsSVG}/>
                                <p style={{marginLeft:'10px'
                                            ,marginTop:'15px'
                                            ,width:'200px'
                                            ,fontSize:'36px'
                                            ,color:'#650E3D'}}>{t('preset_mssg')}</p>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
            <AddUpdatePerset open={openAction} onClose={handleCloseAction}/>
        </div>
    )
}
export default AccountsAndPermissions