import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, IconButton, LinearProgress, List, Popover, Tooltip, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import ArchiveLogo from 'src/images/archive_svg.svg';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import DeleteSVG from 'src/images/carbon_delete.svg'
import EditSVG from 'src/images/edit_svg.svg'
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SearchSVG from 'src/images/search_svg.svg'
import * as moment from 'moment'
import CustomToolbarFilter from 'src/components/Custom/CustomToolbarFilter';
import CustomButton from 'src/components/Custom/CustomButton';
import { useTranslation } from 'react-i18next';
import CustomToast from 'src/components/Custom/CustomToast';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import AddForm from '../Cases/AddForm';
import UpdateStatus from '../Cases/UpdateStatus';
import ReportFilter from '../Cases/ReportFilter';

const Archives = (props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [selectedID, setSelectedID] = useState(-1);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    let { id } = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [caseStatuses, setCaseStatuses] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    let openPop = Boolean(anchorEl);
    const id_pop = openPop ? 'simple-popover' : undefined;

    const handleOpenPop = (event, id) => {
        setSelectedID(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClosePop = () => {
        setAnchorEl(null);
    };


    const [casesFilterForm, setCasesFilterForm] = useState({
        fromDate: null,
        toDate: null,
    });
    const handleclear = () => {
        setCasesFilterForm({
            fromDate: null,
            toDate: null,

        })
    };
    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
            params: {
                fromDate: casesFilterForm.fromDate != null ? `${moment(casesFilterForm.fromDate).format('YYYY-MM-DD')}` : null,
                toDate: casesFilterForm.toDate != null ? `${moment(casesFilterForm.toDate).format('YYYY-MM-DD')}` : null,
                company_id: id,
                is_archive: true,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        if (caseStatuses.length == 0) {
            const getStatuses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/CaseStatuses/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCaseStatuses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getStatuses();
        }
        getData();
    }, []);

    const openStatusForm = meta => {
        setSelectedID(meta.rowData[0]);
        setOpenStatus(true);
    }
    const handleCloseStatus = (refresh) => {
        setOpenStatus(false);
        if (refresh) {
            getData();
        }
    }
    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'color_code',
            label: 'color',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: t('case_name'),
        },
        {
            name: 'clients_full_name',
            label: t('Client'),
            options: {
                filter: false,
                // sort: false,
                customBodyRenderLite: (dataIndex) => {
                    console.log(data[dataIndex].serial);
                    return (
                        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%' }}>
                            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <img
                                        alt=""
                                        //   src={`${process.env.REACT_APP_API_BASE_URL_FILE}/customersLogo/${data[dataIndex].logo}`}
                                        src={EmployeeLogo}
                                        width="25"
                                        height="25"
                                        style={{ borderRadius: '50%' }}
                                    /> */}
                                    <AccountCircleOutlinedIcon />
                                    <span style={{ marginLeft: '5px', fontSize: '14px' }}>{data[dataIndex].clients_full_name}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            name: "status_name",
            label: t('Status'),
            options: {
                filter: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            <div aria-describedby={id_pop}>
                                <CustomButton variant="outlined"
                                    style={{
                                        height: '40px'
                                        , minwidth: '150px'
                                        , border: `2px solid #650E3D`
                                        , color: `#650E3D`
                                        , borderRadius: '30px'
                                        , fontSize: '12px'
                                        , fontWeight: '700'
                                        , textAlign: 'left'
                                        , justifyContent: 'left'
                                    }}
                                    title={
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                                                <span style={{
                                                    fontWeight: '700'
                                                    , fontSize: '7px'
                                                    , textTransform: 'capitalize'
                                                    , lineHeight: '3px'
                                                    , marginTop: '7px'
                                                }}>
                                                    {t('Status')}
                                                </span>
                                                <span style={{ color: `${meta.rowData[1]}` }}>
                                                    {value}
                                                </span>
                                            </div>
                                        </>
                                    }
                                    onClick={(e) => handleOpenPop(e, meta.rowData[0])}
                                    endIcon={<KeyboardArrowDownIcon style={{ color: `#650E3D`, marginTop: '5px' }} />}
                                />
                            </div>
                            <Popover
                                id={id_pop}
                                open={openPop}
                                anchorEl={anchorEl}
                                onClose={handleClosePop}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <List xs={{ pt: 0 }}>
                                    {
                                        saveIsLoading ?
                                            <CircularProgress />
                                            :
                                            caseStatuses.map((e, key) => (
                                                <Typography key={key} onClick={() => changeStatus(e.id)}
                                                    sx={{ p: 2, cursor: 'pointer', color: `${e.color_code}` }}>{e.name}</Typography>
                                            ))
                                    }
                                </List>
                            </Popover>
                        </>
                    )
                },
            },
        },
        {
            name: 'lawyers_full_name',
            label: t('lawyers')
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            <Tooltip title={t('Edit')}>
                                <IconButton onClick={() => handleEdit(meta)}>
                                    <img src={EditSVG} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Delete')}>
                                <IconButton onClick={() => deleteForm(meta)}>
                                    <img src={DeleteSVG} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('UnArchive')}>
                                <IconButton onClick={() => archiveCase(meta)}>
                                    <img src={ArchiveLogo} />
                                </IconButton>
                            </Tooltip>
                            <CustomButton
                                style={{
                                    color: '#650E3D',
                                    width: '80px',
                                    paddingTop: '8px',
                                    border: '2px solid #650E3D',
                                    borderRadius: '30px',
                                    height: '25px',
                                    fontWeight: 'bold'
                                }}
                                title={t('Open')}
                                onClick={() => navigate(`/${id}/Case/${meta.rowData[0]}`)} />
                        </>
                    )
                },
            },
        },
    ];
    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: true,
        rowsPerPage: 100,
        // onRowClick: handleRowClick,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (<span>
                <CustomToolbarFilter filterListener={filterListener} />
            </span>);
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                //   <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const archiveCase = (meta) => {
        const id = meta.rowData[0];

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/archiveCase`,
            data: { id: id ,type:'2'},
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('unarchived_mssg') });
                getData();
            })
            .catch(error => {
                console.log(error);
                handelErrors(error);
            });
    }
    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const addForm = () => {
        setSelectedID(-1);
        setOpen(true);
    }
    const handleEdit = meta => {
        setSelectedID(meta.rowData[0]);
        setOpen(true);
    }
    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }

    const handleSave = () => {
        getData();
        handleCloseFilter();
    };

    const handleChangeInputFilter = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setCasesFilterForm({
                ...casesFilterForm,
                [id]: checked,
            });
        } else {
            setCasesFilterForm({
                ...casesFilterForm,
                [id]: value,
            });
        }
    };
    const customOnChangeFilter = (newValue, name) => {
        setCasesFilterForm({
            ...casesFilterForm,
            [name]: newValue,
        });
    }

    const filterListener = () => {
        setOpenDialog(true);
    };
    const handleCloseFilter = () => {
        setOpenDialog(false);
    };

    const deleteForm = meta => {
        const id = meta.rowData[0];
        Swal.fire({
            title: t('delete_mssg'),
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/delete`,
                    data: { id: id },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        getData();
                    })
                    .catch(error => {
                        console.log(error);
                        handelErrors(error);
                    });
            }
        });
    };
    const changeStatus = (status) => {
        setSaveIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/updateStatus`,
            data: { id: selectedID, status: status },
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('updated_mssg') });
                setSaveIsLoading(false);
                handleClosePop();
                getData();
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    };

    return (
        <div>
            <CustomDataTable
                data={data}
                columns={columns}
                options={options}
            />
            <AddForm open={open} id={selectedID} onClose={handleClose} />
            <ReportFilter
                filterForm={casesFilterForm}
                handleChangeInputFilter={handleChangeInputFilter}
                customOnChange={customOnChangeFilter}
                handleclear={handleclear}
                openDialog={openDialog}
                handleClose={handleCloseFilter}
                handleSave={handleSave}
            />
            <UpdateStatus
                open={openStatus}
                id={selectedID}
                onClose={handleCloseStatus}
            />

        </div>
    )
}
export default Archives