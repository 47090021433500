import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomTabs from 'src/components/Custom/CustomTabs';
import Employees from './Employees';
import AccountsAndPermissions from './AccountsAndPermissions';
import TimeSheet from './TimeSheet/TimeSheet';

const EmployeePage = () => {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const [tabSelected, setTabSelected] = useState(0);
    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
    }
    const presetPagesCodes = JSON.parse(localStorage.getItem("preset_pages_codes"));

    return (
        <div>
            <CustomTabs
                tabs={[
                    {
                        key: 0, label: t('Members'), display: presetPagesCodes.includes('company_employees') ? true : false
                    },
                    {
                        // key: 1, label: t('Accounts&Permissions'),display:presetPagesCodes.includes('company_employee_permissions') ? true : false
                        key: 1, label: t('TimeSheet'), display: presetPagesCodes.includes('timesheet') ? true : false,
                    },
                    {
                        // key: 1, label: t('Accounts&Permissions'),display:presetPagesCodes.includes('company_employee_permissions') ? true : false
                        key: 2, label: t('AccountsPermissions'), display: presetPagesCodes.includes('company_employee_permissions') ? true : false,
                    },
                ]} tabValue={tabSelected} onTabChange={onTabChange} />
            {
                tabSelected === 0 ?
                    <Employees />
                    :
                    tabSelected === 2 ?
                        <AccountsAndPermissions />
                        : tabSelected === 1 ?
                            <TimeSheet />
                            : null
            }
        </div>
    )
}
export default EmployeePage