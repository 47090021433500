import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import CustomDialog from '../../components/Custom/CustomDialog';
import styles from '../../styles/login.module.css';
import { CustomCheckbox, CustomInput, SingleDate } from '../../components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SingleCustomAutocomplete from '../../components/Custom/SingleCustomAutocomplete';
import CustomAutocompleteServerSide from '../../components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleCustomAutocompleteServerSide from 'src/components/Custom/SingleCustomAutocompleteServerSide';
import CustomToast from 'src/components/Custom/CustomToast';


const AddUpdateWorkGroup = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [courtsList, setCourtsList] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        id: -1,
        user_id: null,
        case_id: caseID,
        edit_case: false,
        delete_case: false,
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            user_id: null,
            case_id: caseID,
            edit_case: false,
            delete_case: false,
        });
    }


    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/getWorkGroupById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    user_id: response.data.user_id,
                    case_id: caseID,
                    edit_case: response.data.edit_case == 1 ?true : false ,
                    delete_case: response.data.delete_case == 1 ?true : false ,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    
    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/UpdateWorkGroup`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('updated_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/mileStones/addWorkGroup`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('insert_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };

    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-3' style={{ color: '#650E3D' }}>{t('add_to_team')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SingleCustomAutocompleteServerSide
                            filedName="user_id"
                            label={t('lawyers')}
                            value={formData.user_id}
                            listKey="user_id"
                            description="concat_name"
                            customOnChange={customOnChange}
                            apiEndPoint={`/masterData/lawyers/getDataBySearchQuery`}
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={4}>
                        <CustomCheckbox
                            label={t('edit_case')}
                            checked={formData.edit_case}
                            id="edit_case"
                            onClick={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomCheckbox
                            label={t('delete_case')}
                            checked={formData.delete_case}
                            id="delete_case"
                            onClick={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"} marginBottom={'2%'}>
                        <CustomButton variant="contained" title={t('Add')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddUpdateWorkGroup