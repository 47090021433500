import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomInput, SingleDate } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditSVG from 'src/images/edit_svg.svg'
import DeleteSVG from 'src/images/carbon_delete.svg'
import CustomAutocompleteServerSide from 'src/components/Custom/CustomAutocompleteServerSide';
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import SearchSVG from 'src/images/search_svg.svg'
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import CustomToast from 'src/components/Custom/CustomToast';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';

const vat = JSON.parse(localStorage.getItem('vat'));

const AddUpdateInvoice = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalLoading, setTotalLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [saveIsLoadingDetails, setSaveIsLoadingDetails] = useState(false);
    const [rateFlag, setRateFlag] = useState(false);
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [clients, setClients] = useState([]);
    const [expenses, setExpenses] = useState([]);
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [RowID, setRowID] = useState(-1);
    const [cases, setCases] = useState([]);

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        case_id: caseID > 0 ? caseID : null,
        name: '',
        description: '',
        invoice_type_id: null,
        recipient: null,
        lawyer_fees: '',
        currency: null,
        vat: vat,
        total: '',
        ttc: '',
        vat_value: '',
        total_details: '',
        with_vat: false,
        date: new Date(),
        details: []
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            case_id: caseID > 0 ? caseID : null,
            name: '',
            description: '',
            invoice_type_id: null,
            recipient: null,
            currency: null,
            lawyer_fees: '',
            vat: vat,
            total: '',
            ttc: '',
            vat_value: '',
            total_details: '',
            date: new Date(),
            with_vat: false,
            details: [],
        });
    }
    const [SubmitDetails, setSubmitDetails] = useState({
        id: 0,
        expense_id: null,
        expense_name: null,
        expense_value: '',
        expense_total: '',
        expense_currency: '',
        expense_rate: 1,
    });

    useEffect(() => {
        if (props.open) {
            if (currencies.length == 0) {
                const getCurrencies = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/currencies/get`, {
                        params: {},
                    })
                        .then(function (response) {
                            setCurrencies(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getCurrencies();
            }
            if (invoiceTypes.length == 0) {
                const getInvoiceTypes = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/invoiceTypes/get`, {
                        params: {},
                    })
                        .then(function (response) {
                            setInvoiceTypes(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getInvoiceTypes();
            }
            // if (expenses.length == 0) {
            const getExpenses = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/expenses/get`, {
                    params: {
                        company_id: id,
                        case_id: caseID,
                        type: 'not_invoiced'
                    },
                })
                    .then(function (response) {
                        setExpenses(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getExpenses();
            // }
            if (clients.length == 0) {
                const getClients = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/users/getClientsByCase`, {
                        params: {
                            case_id: caseID
                        },
                    })
                        .then(function (response) {
                            setClients(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getClients();
            }
            if (props.open && cases.length == 0) {
                const getCases = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
                        params: {
                            company_id: id
                        },
                    })
                        .then(function (response) {
                            setCases(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getCases();
            }
        }
    }, [props.open]);

    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/invoices/getById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    company_id: id,
                    case_id: response.data.case_id,
                    name: response.data.name,
                    description: response.data.description,
                    invoice_type_id: response.data.invoice_type_id,
                    recipient: response.data.recipient_id,
                    lawyer_fees: response.data.lawyer_fees,
                    currency: response.data.currency,
                    vat: response.data.vat,
                    total: response.data.total,
                    with_vat: response.data.with_vat == 1 ? true : false,
                    date: response.data.date ? new Date(response.data.date) : null,
                    details: response.data.details == null ? [] : response.data.details,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if (props.open) {
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }

    const customOnChangeDetails = (newValue, name) => {
        if (name == "expense_id") {
            const targetElement = expenses.find(e => e.id === newValue)
            if (targetElement.currency != formData.currency) {
                setRateFlag(true);
            } else {
                setRateFlag(false);
            }
        }
        setSubmitDetails({
            ...SubmitDetails,
            [name]: newValue,
        });
    }

    const handleInputChangeDetails = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setSubmitDetails({
                ...SubmitDetails,
                [id]: checked,
            });
        } else {
            setSubmitDetails({
                ...SubmitDetails,
                [id]: value,
            });
        }
    }

    const saveDetails = () => {
        setSaveIsLoadingDetails(true);
        if (RowID == "-1") {
            let items2 = [...formData.details];
            const targetElement = expenses.find(e => e.id === SubmitDetails.expense_id)


            let converted_value = parseFloat(targetElement.value) * eval(SubmitDetails.expense_rate);
            let converted_total = parseFloat(targetElement.total) * eval(SubmitDetails.expense_rate);

            items2.push({
                id: 0,
                expense_id: SubmitDetails.expense_id,
                expense_name: targetElement.name,
                expense_value: parseFloat(converted_value).toFixed(2),
                expense_total: parseFloat(converted_total).toFixed(2),
                expense_currency: formData.currency,
                expense_rate: SubmitDetails.expense_rate,
            })
            setFormData({
                ...formData,
                details: items2,
            });
        }
        const newArray = expenses.filter(item => item.id !== SubmitDetails.expense_id);
        setExpenses(newArray);

        setSaveIsLoadingDetails(false);
        setOpen(false)
    };

    useEffect(() => {
        let total = 0;
        if (formData.details.length > 0) {
            formData.details.map((e) => {
                total += parseFloat(e.expense_total);
            });
        }
        setFormData({
            ...formData,
            total_details: total,
        });
    }, [formData.details]);

    useEffect(() => {
        if (formData.total_details >= 0) {
            setTotalLoading(true);
            let total_fees = 0;
            let total_expenses = formData.total_details > 0 ? formData.total_details : 0;
            if (formData.with_vat == true) {
                total_fees = Number(total_expenses) + Number(parseFloat((total_expenses * formData.vat) / 100).toFixed(2));
            } else {
                total_fees = total_expenses
            }
            let vat_value = total_fees - formData.total_details;
            setFormData({
                ...formData,
                vat_value: parseFloat(vat_value).toFixed(2),
                total: parseFloat(total_fees).toFixed(2),
            });
            setTotalLoading(false);
        }

    }, [formData.total_details, formData.with_vat]);

    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/invoices/update`,
                data: formData,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('updated_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        } else {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/invoices/add`,
                data: formData,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('insert_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }
    };
    const addForm = () => {
        setRowID(-1);
        setOpen(true);
        setSubmitDetails({
            id: -1,
            expense_id: '',
            expense_name: '',
            expense_value: '',
            expense_total: '',
            expense_currency: '',
            expense_rate: 1,
        });
    }

    const onClose = () => {
        setOpen(false);
    }
    const onChangeTotal = (dataIndex, e) => {
        const value = e.target.value;
        let items = [...formData.details];
        const target = items[dataIndex];
        items[dataIndex] = {
            id: target.id,
            expense_id: target.expense_id,
            expense_name: target.expense_name,
            expense_value: target.expense_value,
            expense_total: value,
            expense_currency: target.expense_currency,
            expense_rate: target.expense_rate,
        };
        setFormData({
            ...formData,
            details: items,
        });
    }
    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'expense_id',
            label: 'exp #',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'expense_name',
            label: t('Expense'),
        },
        {
            name: 'expense_value',
            label: t('Value'),
        },
        {
            name: 'expense_total',
            label: t('Total'),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <input onChange={(e) => onChangeTotal(dataIndex, e)} value={formData.details[dataIndex].expense_total} style={{ width: '100px' }} />
                    )
                }
            }
        },
        {
            name: "actions",
            label: t('Actions'),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <>
                            <Tooltip title={t('Edit')}>
                                <IconButton onClick={() => {
                                    setOpen(true)
                                    setRowID(dataIndex)
                                    setSubmitDetails({
                                        id: formData.details[dataIndex].id,
                                        expense_id: formData.details[dataIndex].expense_id,
                                        expense_name: formData.details[dataIndex].expense_name,
                                        expense_value: formData.details[dataIndex].expense_value,
                                        expense_total: formData.details[dataIndex].expense_total,
                                    });
                                }}>
                                    <img src={EditSVG} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Delete')}>
                                <IconButton onClick={() => {
                                    let myItems = [...formData.details];
                                    let index = myItems.indexOf(formData.details[dataIndex])
                                    myItems.splice(index, 1)
                                    setFormData({
                                        ...formData,
                                        details: myItems
                                    });
                                }}>
                                    <img src={DeleteSVG} />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                },
            },
        },
    ];

    const options = {
        filter: false,
        print: false,
        download: true,
        viewColumns: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (
                formData.currency != null ?
                    // <span>
                    //     <Tooltip title={"Add"}>
                    //         <IconButton onClick={addForm} >
                    //             <AddIcon style={{ color: '#650E3D' }} />
                    //         </IconButton>
                    //     </Tooltip>
                    // </span>
                    <span style={{ marginLeft: '10px' }}><CustomAddButton onClick={addForm} title={t('New')+' '+t('Expense')} /></span>
                    : null
            );
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };
    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    return (
        <>
            <Dialog
                maxWidth={'xs'}
                open={open}
                onClose={onClose}
            >
                <DialogTitle style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Tooltip title={"Close"}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => onClose(false)}
                            aria-label="Close"
                            style={
                                i18n.language =="ar" ?
                                { position: 'absolute', left: '20px' }
                                :
                                { position: 'absolute', right: '20px' }
                            }
                        >
                            <CloseIcon style={{ color: '#650E3D' }} />
                        </IconButton>
                    </Tooltip>
                    <div className='fs-1' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('Expense')}</div>
                </DialogTitle>
                <DialogContent style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="expense_id"
                            label={t('Expenses')}
                            list={expenses}
                            value={SubmitDetails.expense_id}
                            listKey="id"
                            description="name"
                            customOnChange={customOnChangeDetails}
                        />
                    </Grid>
                    {
                        rateFlag == true ?
                            <Grid item xs={12}>
                                <CustomInput
                                    id={'expense_rate'}
                                    fieldLabel={t('Rate')}
                                    value={SubmitDetails.expense_rate}
                                    onChange={handleInputChangeDetails}
                                    // placeholder={t('Expense') + t('Rate')}
                                    withBorder={false}
                                />
                            </Grid>
                            : null
                    }

                    <Grid item xs={12} marginTop={"50px"}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveDetails} isLoading={saveIsLoadingDetails} />
                    </Grid>
                </DialogContent>
            </Dialog>
            <CustomNewDialog
                maxWidth={'md'}
                open={props.open}
                onClose={props.onClose}
                scroll={'body'}
            >
                <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className='fs-1' style={{ color: '#650E3D'}}>{props.id > 0 ? t('Update') : t('Add')} {t('Invoice')}</div>
                </DialogTitle>
                <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <CustomInput
                                id={'name'}
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder={t('name')}
                                withBorder={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleCustomAutocomplete
                                filedName="recipient"
                                label={t('Recipient')}
                                list={clients}
                                value={formData.recipient}
                                listKey="id"
                                description="concat_name"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                        {
                            caseID > 0 ?
                                null
                                :
                                <Grid item xs={12} md={6}>
                                    <SingleCustomAutocomplete
                                        filedName="case_id"
                                        label={t('Case')}
                                        list={cases}
                                        value={formData.case_id}
                                        listKey="id"
                                        description="name"
                                        customOnChange={customOnChange}
                                    />
                                </Grid>
                        }
                        <Grid item xs={6} md={6}>
                            <SingleCustomAutocomplete
                                filedName="invoice_type_id"
                                label={t('Invoice') + t('Type')}
                                list={invoiceTypes}
                                value={formData.invoice_type_id}
                                listKey="id"
                                description="name"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SingleDate
                                id="date"
                                label={t('Date')}
                                value={formData.date}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SingleCustomAutocomplete
                                filedName="currency"
                                label={t('Currency')}
                                list={currencies}
                                value={formData.currency}
                                listKey="code"
                                description="code"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomInput
                                id={'description'}
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder={t('Description')}
                                withBorder={false}
                                multiline={true}
                                minRows={2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomDataTable
                                data={formData.details}
                                columns={columns}
                                options={options}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <CustomCheckbox
                                        label={t('Taxable')}
                                        checked={formData.with_vat}
                                        id="with_vat"
                                        onClick={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ float:i18n.language =="ar" ? 'right':'left',color: '#650E3D', fontSize: '24px', fontWeight: '400' }}>{t('VAT')}</span>
                            <span style={{ float:i18n.language =="ar" ? 'left':'right', color: '#650E3D', fontSize: '24px', fontWeight: '700' }}>
                                {
                                    totalLoading ? <CircularProgress />
                                        :
                                        formData.vat_value > 0 ? formData.vat_value : 0
                                }
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ float:i18n.language =="ar" ? 'right':'left',color: '#650E3D', fontSize: '24px', fontWeight: '400',textTransform:'uppercase' }}>{t('Total')}</span>
                            <span style={{float:i18n.language =="ar" ? 'left':'right', color: '#650E3D', fontSize: '24px', fontWeight: '700' }}>
                                {
                                    totalLoading ? <CircularProgress />
                                        :
                                        formData.total_details > 0 ? formData.total_details : 0
                                }
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{float:i18n.language =="ar" ? 'right':'left', color: '#650E3D', fontSize: '24px', fontWeight: '400',textTransform:'uppercase' }}>
                                {t('TTC')}
                            </span>
                            <span style={{ float:i18n.language =="ar" ? 'left':'right', color: '#650E3D', fontSize: '24px', fontWeight: '700' }}>
                                {
                                    totalLoading ? <CircularProgress />
                                        :
                                        formData.total > 0 ? formData.total : 0
                                }
                            </span>
                        </Grid>
                        <Grid item xs={12} md={6} marginTop={"50px"} marginBottom={'2%'} marginLeft={"auto"} marginRight={"auto"}>
                            <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </CustomNewDialog>
        </>
    )
}
export default AddUpdateInvoice