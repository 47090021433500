import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomInput, SingleDate, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomFileButton from 'src/components/Custom/CustomFileButton';
import { convertBytesToMB, getFileSize } from 'src/components/helpers/Functions';
import CustomToast from 'src/components/Custom/CustomToast';


const AddFile = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        hearing_id: -1,
        files: [],
    });
    const fileInputRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState({
        count: 0,
        size: 0,
    });
    const resetForm = () => {
        setFormData({
            hearing_id: props.id,
            files: [],
        });
        setSelectedFileName({
            count: 0,
            size: 0,
        })
    }

    useEffect(() => {
        resetForm();
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.hearing_id > 0) {
            const formDataObj = new FormData();
            for (const [key, value] of Object.entries(formData)) {
                if (value && value !== "" && value !== null) {
                    if (key == "files") {
                        formData.files.map((e) => {
                            formDataObj.append('files[]', e);
                        });
                    } else {
                        formDataObj.append(key, value);
                    }
                }
            }
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/hearings/addFiles`,
                data: formDataObj,
            })
                .then(response => {
                    CustomToast({ type: 'success', message: t('insert_mssg') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }

    };
    const handleChooseFile = () => {
        fileInputRef.current.click();
    };
    const handleChangeFile = (e) => {
        e.preventDefault()
        let uploadedFiles = e.target.files;
        console.log(uploadedFiles);
        if (uploadedFiles) {
            setFormData({
                ...formData,
                files: [...uploadedFiles]
            });
            let uploadedFilesCount = 0;
            let uploadedFilesSize = 0;
            Object.keys(uploadedFiles).forEach(key => {
                uploadedFilesCount++;
                uploadedFilesSize += (uploadedFiles[key].size / 1000);
            });
            setSelectedFileName({
                count: uploadedFilesCount,
                size: uploadedFilesSize,
            })
        }
        else{
            setSelectedFileName({
                count: 0,
                size: 0,
            })
        }

        return
        // e.preventDefault()
        // const reader = new FileReader()
        // const file = e.target.files[0]
        // if (file) {
        //     setSelectedFileName({
        //         name: file.name,
        //         size: file.size
        //     });
        //     reader.readAsDataURL(file)
        //     reader.onloadend = () => {
        //         setFormData({
        //             ...formData,
        //             file: file
        //         });
        //     }
        // } else {
        //     setFormData({
        //         ...formData,
        //         file: null
        //     });
        // }
    }
    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-4' style={{ color: '#650E3D' }}>{t('Add')} {t('Files')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid item xs={12}>
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} multiple name={t('attachement')} id="attachement" onChange={(e) => handleChangeFile(e)} />
                    <div className='d-flex'>
                        <CustomFileButton onClick={handleChooseFile} />
                        <div className='d-flex flex-column'>
                            <span style={{ marginLeft: '10px', color: '#650E3D' }}>{selectedFileName.count > 0 ? selectedFileName.count : 'Upload Files'}</span>
                            <span style={{ marginLeft: '10px', fontWeight: '700', color: '#650E3D' }}>{selectedFileName.size > 0 ? getFileSize(selectedFileName.size): ''}</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} marginTop={"50px"}>
                    <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddFile