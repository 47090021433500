import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import handelErrors from 'src/components/helpers/handelErrors';
import CustomButton from 'src/components/Custom/CustomButton';
import SingleCustomAutocompleteServerSide from 'src/components/Custom/SingleCustomAutocompleteServerSide';
import { useTranslation } from 'react-i18next';
import { CustomCheckbox, CustomInput } from 'src/components/Custom/CustomInputForms';
import { useParams } from 'react-router-dom';
import CustomToast from 'src/components/Custom/CustomToast';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import { getDescription } from 'src/components/helpers/Functions';

const AddUpdateEmployee = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [presetList, setPresetList] = useState([]);
    const { t, i18n } = useTranslation();
    let { id } = useParams();

    const [formData, setFormData] = useState({
        id: -1,
        // lawyer_id: '',
        email: '',
        active: true,
        role: null,
        preset_id: null,
        company_id: id,
        rate: '',
    });
    const resetForm = () => {
        setFormData({
            id: -1,
            // lawyer_id: '',
            email: '',
            active: true,
            role: null,
            preset_id: null,
            company_id: id,
            rate: '',
        });
    }


    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyEmployees/getEmployeeById-${props.id}`,
        })
            .then(response => {
                setFormData({
                    id: props.id,
                    email: response.data.email,
                    company_id: response.data.company_id,
                    active: response.data.active == 1 ? true : false,
                    role: response.data.role,
                    preset_id: response.data.preset,
                    rate: response.data.rate,
                });
            })
            .catch(error => {
                handelErrors(error);
            });
    };



    useEffect(() => {
        if (props.open) {
            if(presetList.length == 0){
                const getPresets=()=>{
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/presets/get`, {
                        params:{
                            company_id:id
                        }
                    })
                        .then(res => {
                            setPresetList(res.data);
                        }).catch(err => {
                            handelErrors(err);
                        })
                }
                getPresets();
            }
            if (props.id > 0) {
                getFormData();
            } else {
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }
    const saveForm = () => {
        setSaveIsLoading(true)
        if (formData.id > 0) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyEmployees/update`,
                data: formData,
            })
                .then((response) => {
                    CustomToast({ type: 'success', message: t('Success') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        } else {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyEmployees/add`,
                data: formData,
            })
                .then((response) => {
                    CustomToast({ type: 'success', message: t('Success') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        }
    }

    return (
        <Dialog
            maxWidth={'xs'}
            // fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-2' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Add')} {t('Employee')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                <Grid container spacing={"2"}>
                    <p>{props.title}</p>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        {
                            props.id > 0
                                ?
                                null
                                :
                                // <SingleCustomAutocompleteServerSide
                                //     filedName="lawyer_id"
                                //     label={t('lawyers')}
                                //     value={formData.lawyer_id}
                                //     listKey="user_id"
                                //     description="concat_name"
                                //     customOnChange={customOnChange}
                                //     apiEndPoint={`/masterData/lawyers/getDataBySearchQuery`}
                                // />
                                <Grid item xs={12}>
                                    <CustomInput
                                        id={'email'}
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder={t('email')}
                                        withBorder={false}
                                    />
                                </Grid>
                        }

                    </Grid>
                    <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="preset_id"
                            label={t('Presets')}
                            list={presetList}
                            value={formData.preset_id}
                            listKey="id"
                            description={getDescription('name',i18n.language)}
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleCustomAutocomplete
                            filedName="role"
                            label={t('Role')}
                            list={[
                                {
                                    id:'owner',
                                    name:t('Owner'),
                                },
                                {
                                    id:'lawyer',
                                    name:t('Lawyer'),
                                },
                                {
                                    id:'employee',
                                    name:t('Employee'),
                                },
                            ]}
                            value={formData.role}
                            listKey="id"
                            description="name"
                            customOnChange={customOnChange}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <CustomInput
                            id={'rate'}
                            value={formData.rate}
                            onChange={handleInputChange}
                            placeholder={t('Rate')+'/'+t('Hour')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <CustomCheckbox
                            label={t('active')}
                            checked={formData.active}
                            id="active"
                            onClick={handleInputChange}
                        />
                    </Grid>
                    {/* <Grid item xs={4}>
                        <CustomCheckbox
                            label={t('owner')}
                            checked={formData.role}
                            id="role"
                            onClick={handleInputChange}
                        />
                    </Grid> */}
                    <Grid item xs={12} marginTop={"50px"} marginBottom={'2%'}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
AddUpdateEmployee.propTypes = {
    open: PropTypes.any.isRequired,
    id: PropTypes.any,
    onClose: PropTypes.func.isRequired,
};
export default AddUpdateEmployee