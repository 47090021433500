import { Grid } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";

const CustomCardPlan = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div style={{backgroundColor:'#ffffff'}} className="d-flex justify-content-center align-items-center">
            <div style={{width:'90%',marginLeft:'auto',marginRight:'auto',marginTop:'40px',marginBottom:'40px'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div className="colored-text size-36">{props.data.name}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <ul style={{marginTop:'20px'}}>
                            <li className="colored-text weight-500">Feature Set</li>
                            <li className="colored-text weight-500">Feature Set</li>
                            <li className="colored-text weight-500">Feature Set</li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div style={{display:'flex',flexDirection:'column',lineHeight:'30px',marginTop:'20px'}}>
                            <span className="colored-text weight-700 size-36">{props.type =="monthly" ?  props.data.price : props.data.yearly_price} {t('USD')}</span>
                            <span className="colored-text weight-700 size-20" style={{marginLeft:'5px'}}>per {props.type == "monthly" ? t('month') : t('year')}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6} style={{marginTop:'20px'}}>
                        <CustomButton variant="outlined" title={t('Choose_a_plan')} onClick={()=>props.onClick(props.data.id)} />
                    </Grid>
                </Grid>
            </div>
        </div>   
    )
}

export default CustomCardPlan
