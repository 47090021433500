import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import AddIcon from '@mui/icons-material/Add'
import DeleteSVG from 'src/images/carbon_delete.svg'
import EditSVG from 'src/images/edit_svg.svg'
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SearchSVG from 'src/images/search_svg.svg'
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import AddUpdateExpenses from './AddUpdateExpenses';
import CustomToolbarFilter from 'src/components/Custom/CustomToolbarFilter';
import ReportFilter from './ReportFilter';
import CustomAddButton from 'src/components/Custom/CustomAddButton';
import CustomToast from 'src/components/Custom/CustomToast';

const Expenses = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'firm_expenses')
    const targetPageCase = presetPages.find(e => e.page_code === 'case_financial_expenses')

    const [filterForm, setFilterForm] = useState({
        fromDate: null,
        toDate: null,
    });
    const handleclear = () => {
        setFilterForm({
            fromDate: null,
            toDate: null,

        })
    };
    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/expenses/get`, {
            params: {
                company_id: id,
                case_id: caseID,
                fromDate: filterForm.fromDate ? `${moment(filterForm.fromDate).format('YYYY-MM-DD')}` : null,
                toDate: filterForm.toDate ? `${moment(filterForm.toDate).format('YYYY-MM-DD')}` : null,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        getData();
    }, []);

    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: t('name'),
        },
        {
            name: 'description',
            label: t('Description'),
        },
        {
            name: 'value',
            label: t('Value'),
        },
        {
            name: 'quantity',
            label: t('Quantity'),
        },
        {
            name: 'vat_value',
            label: t('VAT'),
        },
        {
            name: 'total',
            label: t('Total'),
        },
        {
            name: 'status',
            label: t('Status'),
        },
        {
            name: 'date',
            label: t('Date'),
        },
        {
            name: "actions",
            label: t('Actions'),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            {
                                (targetPage?.can_view === 1 && targetPage?.can_edit === 1)
                                    ||
                                    (targetPageCase?.can_edit === 1 && targetPageCase?.can_view === 1) ?
                                    <Tooltip title={t('Edit')}>
                                        <IconButton onClick={() => handleEdit(meta)}>
                                            <img src={EditSVG} />
                                        </IconButton>
                                    </Tooltip>
                                    : null

                            }
                            {
                                (targetPage?.can_view === 1 && targetPage?.can_delete === 1)
                                    ||
                                    (targetPageCase?.can_delete === 1 && targetPageCase?.can_view === 1) ?
                                    <Tooltip title={t('Delete')}>
                                        <IconButton onClick={() => deleteForm(meta)}>
                                            <img src={DeleteSVG} />
                                        </IconButton>
                                    </Tooltip>
                                    : null

                            }
                        </>
                    )
                },
            },
        },
    ];

    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (
                <>
                    <CustomToolbarFilter filterListener={filterListener} />
                    {
                        (targetPage?.can_view === 1 && targetPage?.can_add === 1)
                            ||
                            (targetPageCase?.can_add === 1 && targetPageCase?.can_view === 1) ?
                            <span style={{ marginLeft: '10px' }}><CustomAddButton onClick={addForm} title={t('New')+' '+t('Expense')} /></span>
                            : null

                    }
                </>
            );
        },
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const addForm = () => {
        setSelectedID('');
        setOpen(true);
    }
    const handleEdit = meta => {
        setSelectedID(meta.rowData[0]);
        setOpen(true);
    }
    const handleClose = (refresh) => {
        setOpen(false);
        if (refresh) {
            getData();
        }
    }

    const filterListener = () => {
        setOpenDialog(true);
    };
    const handleCloseFilter = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        getData();
        handleCloseFilter();
    };

    const handleChangeInputFilter = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFilterForm({
                ...filterForm,
                [id]: checked,
            });
        } else {
            setFilterForm({
                ...filterForm,
                [id]: value,
            });
        }
    };
    const customOnChangeFilter = (newValue, name) => {
        setFilterForm({
            ...filterForm,
            [name]: newValue,
        });
    }

    const deleteForm = meta => {
        const id = meta.rowData[0];
        Swal.fire({
            title: t('delete_mssg'),
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/masterData/expenses/delete`,
                    data: { id: id },
                })
                    .then(response => {
                        CustomToast({ type: 'success', message: t('Success') });
                        getData();
                    })
                    .catch(error => {
                        handelErrors(error);
                    });
            }
        });
    };

    return (
        <div>
            <CustomDataTable
                data={data}
                columns={columns}
                options={options}
            />
            <AddUpdateExpenses
                open={open}
                id={selectedID}
                onClose={handleClose} />

            <ReportFilter
                filterForm={filterForm}
                handleChangeInputFilter={handleChangeInputFilter}
                customOnChange={customOnChangeFilter}
                handleclear={handleclear}
                openDialog={openDialog}
                handleClose={handleCloseFilter}
                handleSave={handleSave}
            />
        </div>
    )
}
export default Expenses