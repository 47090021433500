import React, { useEffect, useState } from 'react'
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import SingleCustomAutocomplete from 'src/components/Custom/SingleCustomAutocomplete';
import { getYears } from 'src/components/helpers/Functions';
import { CustomToggle } from 'src/components/Custom/CustomInputForms';
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';
import { yearMonthsList } from 'src/components/helpers/FixedData';
import moment from 'moment-timezone';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = moment(currentDate).format("MM");
console.log(currentMonth);

const CustomButtonGroupToggle = (props) => {
    return (
        <ButtonGroup style={{ borderRadius: "20px", border: "1px solid #650E3D" }} variant="outlined" aria-label="outlined button group">
            {props.options.map((v, i) => {
                return (
                    <Button className={`btn-group-filter ${props.value == v.id ? "btn-group-filter-active" : ""}`} onClick={() => {
                        props.onChange(v.id)
                    }}>{v.label}</Button>
                )
            })}
        </ButtonGroup>
    )
}

const CustomTableExpandedRow = (props) => {
    const navigate = useNavigate();
    const [isExpaned, setIsExpaned] = useState(false);
    return (
        <>
            <TableRow
                key={props.index}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{
                    backgroundColor: isExpaned === true ? "rgba(101, 14, 61, 0.06)" : props.index % 2 === 0 ? "#F8F8F8" : "#FFFFFF",
                }}
            >
                <TableCell style={{ border: 0, padding: "3px", fontWeight: "bold", paddingLeft: props.titlePaddingLeft ? props.titlePaddingLeft : "0px" }} align={props.titleAlign ? props.titleAlign : "left"}>
                    <span >
                        {!isExpaned && <ArrowDownward onClick={() => { setIsExpaned(!isExpaned) }} fontSize={"0.5em"} />}
                        {isExpaned && <ArrowUpward onClick={() => { setIsExpaned(!isExpaned) }} fontSize={"0.5em"} />}
                    </span>
                    <span style={{ cursor: props.titleGoTo ? "pointer" : "default" }}
                        onClick={() => {
                            if (props.titleGoTo) {
                                window.open(`${window.location.hostname}/#${props.titleGoTo}`);
                                // navigate(`${props.titleGoTo}`)
                            }
                        }}
                    >
                        {props.title}
                    </span>

                </TableCell>
                {props.cells.map((v, i) => {
                    return <TableCell key={i} style={{ border: 0, padding: "3px", fontWeight: "bold" }} align="right">{v}</TableCell>
                })}
            </TableRow>
            {isExpaned === true && props.children}
        </>
    )
}

const TimeSheetReport = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [usersData, setUsersData] = useState([]);
    const [filterForm, setFilterForm] = useState({
        year: currentYear,
        month: currentMonth,
        quarter: 1,
        filterBy: "yearly",
    });
    const [totalByCurrency, setTotalByCurrency] = useState([]);
    let { id } = useParams();
    const { t, i18n } = useTranslation();

    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/Reports/FirmReports/TimeSheetReport/getReport`, {
            params: {
                company_id: id,
                year: filterForm.year,
                month: filterForm.month,
                quarter: filterForm.quarter,
                filterBy: filterForm.filterBy,
            }
        })
            .then(res => {
                setIsLoading(false);
                setUsersData(res.data.users_data);
                setTotalByCurrency(res.data.total_by_currency)
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        getData();
    }, [filterForm.year, filterForm.month, filterForm.quarter, filterForm.filterBy]);



    const customOnChange = (newValue, name) => {
        setFilterForm({
            ...filterForm,
            [name]: newValue,
        });
    }

    const handleSelect = (newValue) => {
        setFilterForm({
            ...filterForm,
            filterBy: newValue,
        });
    }

    return (
        <div>
            <div className="w-100 d-flex justify-content-end mt-2">
                <select
                    className="custom-small-select"
                    value={filterForm.year}
                    onChange={(e) => {
                        const { value } = e.target
                        setFilterForm({
                            ...filterForm,
                            year: value
                        })
                    }}
                >
                    {getYears().map((v, i) => {
                        return (
                            <option key={i} value={v.id}>{v.label}</option>
                        )
                    })}
                </select>
                {filterForm.filterBy === "monthly" &&
                    <select
                        className="custom-small-select"
                        value={filterForm.month}
                        onChange={(e) => {
                            const { value } = e.target
                            setFilterForm({
                                ...filterForm,
                                month: value
                            })
                        }}
                    >
                        {yearMonthsList.map((v, i) => {
                            return (
                                <option key={i} value={v.id}>{v.label}</option>
                            )
                        })}
                    </select>
                }
                {filterForm.filterBy === "quarterly" &&
                    <select
                        className="custom-small-select"
                        value={filterForm.quarter}
                        onChange={(e) => {
                            const { value } = e.target
                            setFilterForm({
                                ...filterForm,
                                quarter: value
                            })
                        }}
                    >
                        <option value={1}>Q1</option>
                        <option value={2}>Q2</option>
                        <option value={3}>Q3</option>
                        <option value={4}>Q4</option>
                    </select>
                }
                <CustomButtonGroupToggle
                    onChange={handleSelect}
                    value={filterForm.filterBy}
                    options={
                        [
                            {
                                id: 'yearly', label: `${t('yearly')}`
                            },
                            {
                                id: 'quarterly', label: `${t('quarterly')}`
                            },
                            {
                                id: 'monthly', label: `${t('monthly')}`
                            },
                        ]
                    }
                />
            </div>
            <div className="mt-2" style={{ backgroundColor: "#FFF", padding: "10px", borderRadius: "16px" }}>
                <Table sx={{ minWidth: 650 }} className={"mt-3"} aria-label="simple table" style={{
                    backgroundColor: "#FFF",
                    padding: "2px",
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: "2px", border: 0, }} component="th" align="center"></TableCell>
                            <TableCell style={{ padding: "2px", color: "#650E3D", fontWeight: "bold", border: 0, }} component="th" align="right">Rate</TableCell>
                            <TableCell style={{ padding: "2px", color: "#650E3D", fontWeight: "bold", border: 0, }} component="th" align="right">Hours</TableCell>
                            <TableCell style={{ padding: "2px", color: "#650E3D", fontWeight: "bold", border: 0, }} component="th" align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={"clients"}>
                            <TableCell align="left" colSpan="4" style={{ color: "#650E3D", padding: "2px", border: 0, textTransform: 'uppercase' }}>legal team</TableCell>
                        </TableRow>
                        {Object.keys(usersData).map((clientRow, i) => (
                            <CustomTableExpandedRow
                                key={i}
                                index={i}
                                title={clientRow}
                                cells={[
                                    `${usersData[clientRow]['rate']} ${usersData[clientRow]['currency']}/Hr`,
                                    `${usersData[clientRow]['total_hours']} `,
                                    `${usersData[clientRow]['total']} ${usersData[clientRow]['currency']}`,
                                ]}>
                                    {
                                        Object.keys(usersData[clientRow]["cases"]).map((clientCurrencyCaseRow, i3) => (
                                            // console.log(usersData[clientRow]["cases"])
                                            <>
                                                <CustomTableExpandedRow
                                                    key={i3}
                                                    index={i3}
                                                    title={usersData[clientRow]["cases"][clientCurrencyCaseRow]["case_name"]}
                                                    titleGoTo={`/${id}/Case/${usersData[clientRow]["cases"][clientCurrencyCaseRow]["case_id"]}`}
                                                    titleAlign="left"
                                                    titlePaddingLeft="20px"
                                                    cells={[
                                                        `${usersData[clientRow]["cases"][clientCurrencyCaseRow].rate} ${usersData[clientRow]['currency']}/Hr`,
                                                        `${usersData[clientRow]["cases"][clientCurrencyCaseRow].total_hours}`,
                                                        `${usersData[clientRow]["cases"][clientCurrencyCaseRow].total}${usersData[clientRow]['currency']}`,
                                                    ]}>
                                                    {Object.keys(usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"]).map((clientCurrencyCaseExpenseTypesRow, i4) => (
                                                        <>
                                                            <CustomTableExpandedRow
                                                                key={i4}
                                                                index={i4}
                                                                title={usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow]["task_name"]}
                                                                titleAlign="left"
                                                                titlePaddingLeft="30px"
                                                                cells={[
                                                                    `${usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow].rate} ${usersData[clientRow]['currency']}/Hr`,
                                                                    `${usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow].total_hours}`,
                                                                    `${usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow].total} ${usersData[clientRow]['currency']}`,
                                                                ]}>
                                                                {/* {
                                                                    Object.keys(usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow]["expenses"]).map((clientCurrencyCaseExpenseTypesExpenseRow, i5) => (
                                                                        <TableRow key={i5}>
                                                                            <TableCell style={{ border: 0, padding: "2px", backgroundColor: i5 % 2 === 0 ? "#F8F8F8" : "#FFFFFF" }} align="center">{usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow]["expenses"][clientCurrencyCaseExpenseTypesExpenseRow].expense_name}</TableCell>
                                                                            <TableCell style={{ border: 0, padding: "2px", backgroundColor: i5 % 2 === 0 ? "#F8F8F8" : "#FFFFFF" }} align="right">{usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow]["expenses"][clientCurrencyCaseExpenseTypesExpenseRow].rate} {clientCurrencyRow}</TableCell>
                                                                            <TableCell style={{ border: 0, padding: "2px", backgroundColor: i5 % 2 === 0 ? "#F8F8F8" : "#FFFFFF" }} align="right">{usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow]["expenses"][clientCurrencyCaseExpenseTypesExpenseRow].total_hours} {clientCurrencyRow}</TableCell>
                                                                            <TableCell style={{ border: 0, padding: "2px", backgroundColor: i5 % 2 === 0 ? "#F8F8F8" : "#FFFFFF" }} align="right">{usersData[clientRow]["cases"][clientCurrencyCaseRow]["tasks"][clientCurrencyCaseExpenseTypesRow]["expenses"][clientCurrencyCaseExpenseTypesExpenseRow].total} {clientCurrencyRow}</TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                } */}
                                                            </CustomTableExpandedRow>
                                                        </>
                                                    ))}
                                                </CustomTableExpandedRow>
                                            </>
                                        ))
                                    }
                            </CustomTableExpandedRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        {totalByCurrency.map((v, i) => {
                            return (
                                <TableRow key={i} style={{ backgroundColor: "#650E3D" }}>
                                    <TableCell style={{ border: 0, padding: "2px", color: "#FFFFFF", fontWeight: "bold" }} component="th" align="center">Total {v.currency}</TableCell>
                                    <TableCell style={{ border: 0, padding: "2px", color: "#FFFFFF", fontWeight: "bold" }} component="th" align="right">{v.rate} {v.currency}/Hr</TableCell>
                                    <TableCell style={{ border: 0, padding: "2px", color: "#FFFFFF", fontWeight: "bold" }} component="th" align="right">{v.total_hours}</TableCell>
                                    <TableCell style={{ border: 0, padding: "2px", color: "#FFFFFF", fontWeight: "bold" }} component="th" align="right">{v.total} {v.currency}</TableCell>
                                </TableRow>
                            )
                        })
                        }

                    </TableFooter>
                </Table>
            </div >

        </div>
    )
}
export default TimeSheetReport