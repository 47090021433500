import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip, List, Popover, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchSVG from 'src/images/search_svg.svg'
import CustomTabs from 'src/components/Custom/CustomTabs';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import CustomButton from 'src/components/Custom/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CustomSearch } from 'src/components/Custom/CustomInputForms';
import CustomDataTable from 'src/components/Custom/CustomDataTable';

const CasesTable = props => {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [caseStatuses, setCaseStatuses] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedID, setSelectedID] = useState(-1);

    let openPop = Boolean(anchorEl);
    const id_pop = openPop ? 'simple-popover' : undefined;

    const handleOpenPop = (event, id) => {
        setSelectedID(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClosePop = () => {
        setAnchorEl(null);
    };

    const [formData, setFormData] = useState({
        id: -1,
        status: '',
    });

    const resetForm = () => {
        setFormData({
            id: -1,
            status: '',
        });
    }

    const getData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
            params: {
                company_id: id,
                user_id: props.id,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    useEffect(() => {
        if (props.id > 0) {
            if (caseStatuses.length == 0) {
                const getStatuses = () => {
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/CaseStatuses/get`, {
                        params: {},
                    })
                        .then(function (response) {
                            setCaseStatuses(response.data);
                        })
                        .catch(function (error) {
                            handelErrors(error)
                        })
                }
                getStatuses();
            }
            getData();
            resetForm();
        }
    }, [props.id]);

    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'color_code',
            label: 'color',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: t('case_name'),
        },
        {
            name: "status_name",
            label: t('Status'),
            options: {
                filter: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            <div aria-describedby={id_pop}>

                                <CustomButton variant="outlined"
                                    style={{
                                        height: '40px'
                                        , minwidth: '150px'
                                        , border: `2px solid #650E3D`
                                        , color: `#650E3D`
                                        , borderRadius: '30px'
                                        , fontSize: '12px'
                                        , fontWeight: '700'
                                        , textAlign: 'left'
                                        , justifyContent: 'left'
                                    }}
                                    title={
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                                                <span style={{
                                                    fontWeight: '700'
                                                    , fontSize: '7px'
                                                    , textTransform: 'capitalize'
                                                    , lineHeight: '3px'
                                                    , marginTop: '7px'
                                                }}>
                                                    status
                                                </span>
                                                <span style={{ color: `${meta.rowData[1]}` }}>
                                                    {value}
                                                </span>
                                            </div>
                                        </>
                                    }
                                    onClick={(e) => handleOpenPop(e, meta.rowData[0])}
                                    endIcon={<KeyboardArrowDownIcon style={{ color: `#650E3D`, marginTop: '5px' }} />}
                                />
                            </div>
                            <Popover
                                id={id_pop}
                                open={openPop}
                                anchorEl={anchorEl}
                                onClose={handleClosePop}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <List xs={{ pt: 0 }}>
                                    {
                                        saveIsLoading ?
                                            <CircularProgress />
                                            :
                                            caseStatuses.map((e, key) => (
                                                <Typography key={key} onClick={() => changeStatus(e.id)}
                                                    sx={{ p: 2, cursor: 'pointer', color: `${e.color_code}` }}>{e.name}</Typography>
                                            ))
                                    }
                                </List>
                            </Popover>
                        </>
                    )
                },
            },
        },
        {
            name: 'lawyers_full_name',
            label: t('lawyers')
        },
    ];
    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
        searchOpen: true,
        searchAlwaysOpen: true,
        customSearchRender: (searchText, handleSearch) => {
            return (
                <CustomSearch
                    id={'name'}
                    value={searchText}
                    onChange={(e) => handleSearchBar(e, handleSearch)}
                    placeholder={t('Search') + '...'}
                    withBorder={false}
                    endAdornment={
                        <img src={SearchSVG} />
                    }
                />
            );
        },
    };

    const handleSearchBar = (event, onSearch) => {
        onSearch(event.target.value)
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }
    const changeStatus = (status) => {
        setSaveIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/cases/updateStatus`,
            data: { id: selectedID, status: status },
        })
            .then(response => {
                CustomToast({ type: 'success', message: t('updated_mssg') });
                setSaveIsLoading(false);
                handleClosePop();
                getData();
            })
            .catch(error => {
                setSaveIsLoading(false);
                handelErrors(error);
            });
    };

    return (
        <div style={{position:'absolute',width:'100%'}}>
           <CustomDataTable
                data={data}
                columns={columns}
                options={options}
                height={'300px'}
            />
        </div>
    )
}
export default CasesTable