// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDq_2mWRzX2Kz55XODOc1dwZU8sBMKi6X8",
  authDomain: "haqq-5b443.firebaseapp.com",
  projectId: "haqq-5b443",
  storageBucket: "haqq-5b443.appspot.com",
  messagingSenderId: "1009631876459",
  appId: "1:1009631876459:web:43dce3709d41387d0ba82e",
  measurementId: "G-XWR6JEJXQ5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()

export default {app};