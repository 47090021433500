import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, IconButton, LinearProgress, Paper } from '@mui/material';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ArcElement,
    PointElement,
    LineElement,
    Tooltip
} from 'chart.js';
import axios from 'axios';
import handelErrors from 'src/components/helpers/handelErrors';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton from 'src/components/Custom/CustomButton';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CustomDataTable from 'src/components/Custom/CustomDataTable';
import { statuses } from 'src/components/helpers/FixedData';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Legend,
    ArcElement,
    Tooltip
);
const currentDate = new Date();
let date = new Date(Date.now()); date.setDate(date.getDate() - 7);

const Dashboard = (props) => {
    const { t, i18n } = useTranslation();
    let { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const [labels, setLabels] = useState([]);
    const [totalCases, setTotalCases] = useState([]);
    const [totalClients, setTotalClients] = useState([]);

    const [doughnutLabels, setDoughnutLabels] = useState([]);
    const [totalDoughnut, setTotalDoughnut] = useState([]);

    const [tasksLabels, setTasksLabels] = useState([]);
    const [totalTasks, setTotalTasks] = useState([]);

    useEffect(() => {
        getData();
        getDougnutData();
        getTotalTasks();
        getDataTable();
    }, []);

    const getData = () => {
        // setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/getDashboardData`, {
            params: {
                company_id: id,
                // fromDate: `${moment(props.filterForm.fromDate).format('YYYY-MM-DD')} 00:00`,
                // toDate: `${moment(props.filterForm.toDate).format('YYYY-MM-DD')} 23:59`,
            }
        })
            .then(res => {
                let months = [];
                let total_cases = [];
                let total_clients = [];
                let data = res.data;
                data.forEach(element => {
                    months.push(element.month)
                    total_cases.push(element.total_cases)
                    total_clients.push(element.total_clients)
                });
                setLabels(months);
                setTotalCases(total_cases);
                setTotalClients(total_clients);
                // setIsLoading(false);
            }).catch(err => {
                // setIsLoading(false);
                handelErrors(err);
            })
    }

    const getDougnutData = () => {
        // setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/getCasesByType`, {
            params: {
                company_id: id,
                // fromDate: `${moment(props.filterForm.fromDate).format('YYYY-MM-DD')} 00:00`,
                // toDate: `${moment(props.filterForm.toDate).format('YYYY-MM-DD')} 23:59`,
            }
        })
            .then(res => {
                let labels = [];
                let total = [];
                let data = res.data;
                data.forEach(element => {
                    labels.push(element.type)
                    total.push(element.total)
                });
                setDoughnutLabels(labels);
                setTotalDoughnut(total);
                // setIsLoading(false);
            }).catch(err => {
                // setIsLoading(false);
                handelErrors(err);
            })
    }

    const getTotalTasks = () => {
        // setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/Tasks/getTotal`, {
            params: {
                company_id: id,
                fromDate: `${moment(date).format('YYYY-MM-DD')} 00:00`,
                toDate: `${moment(currentDate).format('YYYY-MM-DD')} 23:59`,
            }
        })
            .then(res => {
                let labels = [];
                let total = [];
                let data = res.data;
                data.forEach(element => {
                    labels.push(element.date)
                    total.push(element.total)
                });
                setTasksLabels(labels);
                setTotalTasks(total);
                // setIsLoading(false);
            }).catch(err => {
                // setIsLoading(false);
                handelErrors(err);
            })
    }

    const getDataTable = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/cases/get`, {
            params: {
                fromDate: `${moment(date).format('YYYY-MM-DD')}`,
                toDate: `${moment(currentDate).format('YYYY-MM-DD')}`,
                limit: 10,
                company_id: id,
            }
        })
            .then(res => {
                setIsLoading(false);
                setData(res.data);
            }).catch(err => {
                setIsLoading(false);
                handelErrors(err);
            })
    }
    const columns = [
        {
            name: 'id',
            label: '#',
            options: {
                display: 'excluded'
            }
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <>
                            <div style={{ width: '25px' }}>
                                <CustomButton
                                    style={{
                                        color: '#650E3D',
                                        width: '100%',
                                        paddingTop: '8px',
                                        border: '2px solid #650E3D',
                                        borderRadius: '30px',
                                        height: '25px',
                                        fontWeight: 'bold'
                                    }}
                                    title={t('Open')}
                                    onClick={() => navigate(`/${id}/Case/${meta.rowData[0]}`)} />
                            </div>
                        </>
                    )
                },
            },
        },
        {
            name: 'name',
            label: t('case_name'),
        },
        {
            name: 'clients_full_name',
            label: t('Client'),
            options: {
                filter: false,
                // sort: false,
                customBodyRenderLite: (dataIndex) => {
                    console.log(data[dataIndex].serial);
                    return (
                        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%' }}>
                            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <img
                                        alt=""
                                        //   src={`${process.env.REACT_APP_API_BASE_URL_FILE}/customersLogo/${data[dataIndex].logo}`}
                                        src={EmployeeLogo}
                                        width="25"
                                        height="25"
                                        style={{ borderRadius: '50%' }}
                                    /> */}
                                    <AccountCircleOutlinedIcon />
                                    <span style={{ marginLeft: '5px', fontSize: '14px' }}>{data[dataIndex].clients_full_name}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            name: "status_name",
            label: t('Status'),
        },
    ];
    const options = {
        filter: false,
        viewColumns: false,
        print: false,
        download: false,
        search: false,
        rowsPerPage: 100,
        // onRowClick: handleRowClick,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LinearProgress /> :
                    t('no_data_table'),
            },
        },
    };

    return (
        <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <div style={{ backgroundColor: 'white', height: '250px', padding: '20px' }}>
                            <Bar
                                style={{ height: '40vh' }}
                                options={
                                    {
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: t('total_case_clients'),
                                            },
                                        },
                                        responsive: true,
                                        tooltips: {
                                            enabled: true,
                                        },
                                        scales: {
                                            x: {
                                                stacked: true,
                                            },
                                            y: {
                                                stacked: true,
                                            },
                                        },
                                    }
                                }
                                data={
                                    {
                                        labels: labels,
                                        datasets: [
                                            {
                                                label: `${t('Total')} ${t('Cases')}`,
                                                data: totalCases,
                                                backgroundColor: '#650E3D',
                                            },
                                            {
                                                label: `${t('Total')} ${t('Clients')}`,
                                                data: totalClients,
                                                backgroundColor: '#A42F6E',
                                            },
                                        ],
                                    }
                                } />
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{
                            backgroundColor: 'white', height: '250px'
                            , display: 'flex'
                            , justifyContent: 'center'
                            , alignItems: 'center', padding: '20px'
                        }}>
                            <Doughnut data={
                                {
                                    labels: doughnutLabels,
                                    datasets: [
                                        {
                                            label: t('nbr_cases'),
                                            data: totalDoughnut,
                                            backgroundColor: [
                                                '#650E3D',
                                                '#A42F6E',
                                            ],
                                            borderColor: [
                                                '#650E3D',
                                                '#A42F6E',
                                            ],
                                            borderWidth: 1,
                                        },
                                    ],
                                }
                            } />
                        </div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div style={{
                            backgroundColor: 'white', height: '250px'
                            , display: 'flex'
                            , justifyContent: 'center'
                            , alignItems: 'center', padding: '20px'
                        }}>
                            <Line
                                options={
                                    {
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: t('nbr_tasks'),
                                            },
                                        },
                                    }
                                }
                                data={
                                    {
                                        labels: tasksLabels,
                                        datasets: [
                                            {
                                                label: t('nbr_tasks'),
                                                data: totalTasks,
                                                borderColor: '#650E3D',
                                                backgroundColor: '#650E3D',
                                            },
                                        ],
                                    }
                                } />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <div style={{
                            backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px'
                        }}>
                            <CustomDataTable
                                data={data}
                                columns={columns}
                                options={options}
                                rowPadding={'5px'}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Dashboard