import React, { useEffect, useRef, useState } from "react";
import Frame from '../../images/logo_svg.svg';
import styles from '../../styles/login.module.css';
import { Grid } from "@mui/material";
import SingleCustomAutocomplete from "../../components/Custom/SingleCustomAutocomplete";
import { CustomCheckbox, CustomInput } from "../../components/Custom/CustomInputForms";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomButton from "../../components/Custom/CustomButton";
import axios from "axios";
import handelErrors from "../../components/helpers/handelErrors";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "src/FireBase/App";
import CustomToast from "src/components/Custom/CustomToast";
import CustomFileButton from "src/components/Custom/CustomFileButton";
import { useTranslation } from "react-i18next";

const titles = [
    {
        id: 'Mr', label: 'Mr'
    },
    {
        id: 'Ms', label: 'Ms'
    },
];

const ClientSignup = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [inputType, setInputType] = useState(true);
    const [inputType2, setInputType2] = useState(true);
    const [countriesList, setCountriesList] = useState([]);
    const [prefixList, setPrefixList] = useState([]);
    const { t, i18n } = useTranslation();

    const google_email = localStorage.getItem('google_email') ? localStorage.getItem('google_email') : '';
    const google_password = localStorage.getItem('google_password') ? localStorage.getItem('google_password') : '';
    const fileInputRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState({
        name: '',
        size: 0,
    });
    const [formData, setFormData] = useState({
        serial: -1,
        first_name: "",
        middle_name: "",
        last_name: "",
        title: null,
        phone_prefix: "",
        phone: "",
        nationality: null,
        location: null,
        email: google_email != '' ? google_email : '',
        password: google_password != '' ? google_password : '',
        password_confirmation: google_password != '' ? google_password : '',
        is_valid: false,
        type: 'client',
        image: null,
    })

    const resetForm = () => {
        setFormData({
            serial: -1,
            first_name: "",
            middle_name: "",
            last_name: "",
            title: null,
            phone_prefix: "",
            phone: "",
            nationality: null,
            location: null,
            email: '',
            password: '',
            password_confirmation: '',
            is_valid: false,
            type: 'client',
            image: null,
        });
        setSelectedFileName({
            name: '',
            size: 0,
        })
    }
    const handleChooseFile = () => {
        fileInputRef.current.click();
    };

    const handleChangeFile = (e) => {
        e.preventDefault()
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            setSelectedFileName({
                name: file.name,
                size: file.size
            });
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                setFormData({
                    ...formData,
                    image: file
                });
            }
        } else {
            setFormData({
                ...formData,
                image: null
            });
            setSelectedFileName({
                name: '',
                size: 0,
            });
        }
        return
    }
    useEffect(() => {
        if (countriesList.length == 0) {
            const getCountries = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/countries/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setCountriesList(response.data.data);
                        setPrefixList(response.data.prefix);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getCountries();
        }
    }, []);

    const saveForm = () => {
        setSaveIsLoading(true)
        if (formData.is_valid == true) {
            const formDataObj = new FormData();
            for (const [key, value] of Object.entries(formData)) {
                if (value && value !== "" && value !== null) {
                    formDataObj.append(key, value);
                }
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/auth/signup`,
                data: formDataObj,
            })
                .then((response) => {
                    createUserWithEmailAndPassword(auth, formData.email, formData.password)
                        .then((result) => {
                            const email = formData.email;
                            setDoc(doc(db, "users", result.user.uid), {
                                uid: result.user.uid,
                                email,
                                photoURL: null,
                            });
                            // setDoc(doc(db, "userChats", result.user.uid), {});
                            axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/users/updateUID`,
                                data: {
                                    uid:result.user.uid,
                                    id:response.data.user.id,
                                },
                            })
                                .then((response) => {
                                    CustomToast({type:'success',message : 'Sign up successfully'});
                                    resetForm();
                                    if (google_email != '') {
                                        localStorage.removeItem('google_email');
                                    }
                                    if (google_password != '') {
                                        localStorage.removeItem('google_password');
                                    }
                                    setSaveIsLoading(false);
                                    navigate('/');
                                })
                                .catch((error) => {
                                    setSaveIsLoading(false)
                                    handelErrors(error);
                                });
                        }).catch((error) => {
                            // handelErrors(error);
                            CustomToast({type:'error',message : 'FireBase Error'});
                            console.log(error);
                            setSaveIsLoading(false);
                        });
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        } else {
            CustomToast({type:'warning',message : 'Please confirm that you have read and Agreed to the terms & service'});
            setSaveIsLoading(false)
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }
    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }

    useEffect(() => {
        countriesList.forEach(e => {
            if (e.id == formData.location) {
                setFormData({
                    ...formData,
                    phone_prefix: e.phone_prefix,
                });
            }
        });

    }, [formData.location]);
    return (
        <div style={{
            minHeight: '100vh'
            , backgroundColor: '#F4F4F4'
            , display: 'flex'
            , justifyContent: 'center'
            , alignItems: 'center'
        }}>
            <div style={{
                height: '90%'
                , width: '85%'
                , backgroundColor: 'white'
                , display: 'flex'
                , justifyContent: 'center'
                , alignItems: 'center'
            }}>
                <div style={{
                    height: '95%'
                    , width: '85%'
                    , marginTop: '20px'
                    , marginBottom: '20px'
                }}>
                    <div className="d-flex justify-content-center align-items-center">
                        <img src={Frame} width={70} />
                    </div>
                    <div className={styles.text} style={{ marginTop: '10px' }}>
                        Complete your profile
                    </div>
                    <Grid container spacing={3} style={{ marginTop: '10px' }}>
                        <Grid item xs={12} md={2}>
                            <SingleCustomAutocomplete
                                filedName="title"
                                label="Title"
                                list={titles}
                                value={formData.title}
                                listKey="id"
                                description="label"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInput
                                id={'first_name'}
                                value={formData.first_name}
                                onChange={handleInputChange}
                                placeholder='First Name'
                                withBorder={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CustomInput
                                id={'middle_name'}
                                value={formData.middle_name}
                                onChange={handleInputChange}
                                placeholder='Middle Name'
                                withBorder={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CustomInput
                                id={'last_name'}
                                value={formData.last_name}
                                onChange={handleInputChange}
                                placeholder='Last Name'
                                withBorder={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomInput
                                id={'email'}
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder='Email Address'
                                withBorder={false}
                                disabled={google_email != '' ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleCustomAutocomplete
                                filedName="nationality"
                                label="Nationality"
                                list={countriesList}
                                value={formData.nationality}
                                listKey="id"
                                description="name"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SingleCustomAutocomplete
                                filedName="location"
                                label="Location"
                                list={countriesList}
                                value={formData.location}
                                listKey="id"
                                description="name"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <SingleCustomAutocomplete
                                filedName="phone_prefix"
                                label="Prefix"
                                list={prefixList}
                                value={formData.phone_prefix}
                                listKey="phone_prefix"
                                description="phone_prefix"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <CustomInput
                                id={'phone'}
                                value={formData.phone}
                                onChange={handleInputChange}
                                placeholder='Phone Number'
                                withBorder={false}
                            // type={'number'}
                            />
                        </Grid>
                        {
                            google_password == '' ?
                                <>
                                    <Grid item xs={12} md={5}>
                                        <CustomInput
                                            id={'password'}
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            placeholder='Password'
                                            withBorder={false}
                                            type={inputType == true ? 'password' : 'text'}
                                            endAdornment={
                                                <VisibilityIcon style={{ cursor: 'pointer', color: '#650E3D' }}
                                                    onClick={() => setInputType(!inputType)} />
                                            }
                                            disabled={google_password != '' ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={8} md={5}>
                                        <CustomInput
                                            id={'password_confirmation'}
                                            value={formData.password_confirmation}
                                            onChange={handleInputChange}
                                            placeholder='Confirm Password'
                                            withBorder={false}
                                            type={inputType2 == true ? 'password' : 'text'}
                                            endAdornment={
                                                <VisibilityIcon style={{ cursor: 'pointer', color: '#650E3D' }}
                                                    onClick={() => setInputType2(!inputType2)} />
                                            }
                                            disabled={google_password != '' ? true : false}
                                        />
                                    </Grid>
                                </>
                                : null
                        }
                        <Grid item xs={4} md={2}>
                            <div className={styles.small_text}>
                                Please Use a combination of letters, numbers, and special characters
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} name={t('attachement')} id="attachement" onChange={(e) => handleChangeFile(e)} />
                            <div className='d-flex'>
                                <CustomFileButton onClick={handleChooseFile} />
                                <div className='d-flex justify-content-center align-items-center'>
                                    <span style={{ marginLeft: '10px', color: '#650E3D' }}>{selectedFileName.name != '' ? selectedFileName.name : 'Upload Profile Picture'}</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <CustomCheckbox
                                label={null}
                                checked={formData.is_valid}
                                id="is_valid"
                                onClick={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={10} md={7}>
                            <div className={styles.medium_text}>
                                I have read and Agreed to <a href="#" style={{ color: '#650E3D', marginLeft: '5px' }}> the terms,privacy policy & Service</a>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomButton variant="contained" title="Sign Up" onClick={saveForm} isLoading={saveIsLoading} />
                        </Grid>
                    </Grid>
                </div>

            </div>
        </div>
    )
}
export default ClientSignup