import React from 'react';
import { toast } from 'react-toastify';
import CustomToast from 'src/components/Custom/CustomToast';

const handelErrors = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      CustomToast({ type: 'error', message: error.response.data[0] });
    }
    else if (error.response.status === 413) {
      CustomToast({ type: 'error', message: 'Too Large' });
    }
    else if (error.response.status === 404) {
      CustomToast({ type: 'error', message: 'API not found 404' });
    }
    else if (error.response.status === 422) {
      CustomToast({
        type: 'error'
        , message: <div><span className={"error-list-title"}>Validation Error</span>
          <ul className={"error-list"}>
            {Object.values(error.response.data).map((val, index) => { return (<li className={"error-list-item"} key={index}>{val}</li>) })}
          </ul>
        </div>
      });

    }
    else if (error.response.status === 401) {
      CustomToast({ type: 'error', message: 'Expired Please Login Again' });
    }
    else if (error.response.status === 405) {
      CustomToast({ type: 'error', message: 'HTTP Method Not Allowed' });
    }
    else if (error.response.status === 500) {
      CustomToast({ type: 'error', message: 'Internal Server Error' });
    }
    else if (error.response.status === 429) {
      toast.error('Too Many Requests');
    }
    else {
      CustomToast({ type: 'error', message: 'Unexpected Error 🙁' });
    }
    // Request made and server responded
  } else if (error.request) {
    // The request was made but no response was received
    CustomToast({ type: 'error', message: 'No Response From Our Server (We\'ll Fix It Soon) 🙁' });
    console.log(error.request);
  } else {
    if (error.message !== "cancelled_cleanup") {
      // Something happened in setting up the request that triggered an Error
      CustomToast({ type: 'error', message: 'Could Not Esablish Request' });
      console.log('Error', error.message);
    }
  }

}
export default handelErrors;