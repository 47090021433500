import React, { Suspense, useEffect } from 'react'
import logo from './logo.svg';
import './App.css';
import Login from './views/login/login';
import ClientSignup from './views/ClientSignup/ClientSignup';
import { Navigate, HashRouter, Route, Routes } from 'react-router-dom'
import routes from './route';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import cookie from 'js-cookie'
import allActions from './actions/allActions'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import DefaultLayout from './components/pages/DefaultLayout';
import { useTranslation } from "react-i18next";
import "./i18n";
import LawyerSignup from './views/LawyerSignup/LawyerSignup';
import PlansPage from './components/pages/PlansPage';
import PlansFormPage from './components/pages/PlansFormPage';
import { auth } from './FireBase/App';
import CustomToast from './components/Custom/CustomToast';

import SuccessNotification from 'src/images/success_notification.svg'
import errorNotification from 'src/images/error_notification.svg'
import WarningNotification from 'src/images/warning_notification.svg'

function App() {
  const token = cookie.get('token')
  const currentUser = useSelector((state) => state.currentUser)
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
  }, [i18n.language]);

  useEffect(() => {
    console.log(token);
    const vaildateUser = () => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/auth/user-profile`)
        .then((res) => {
          const googleEmail = auth.currentUser.email;
          const googleUid = auth.currentUser.uid;
          dispatch(allActions.userActions.setUser(res.data))
          localStorage.setItem('statuses', JSON.stringify(res.data.statuses));
          localStorage.setItem('google_email', googleEmail);
          localStorage.setItem('google_password', googleUid);
          localStorage.setItem('google_uid', googleUid);
        })
        .catch((err) => {
          axios.defaults.headers.common['Authorization'] = ``
          localStorage.clear()
          cookie.remove('token')
          dispatch(allActions.userActions.logOut())
        })
    }
    if (token) {
      vaildateUser()
    } else {
      console.log("token not valid");
      localStorage.clear()
    }
  }, [token])



  return (
    <div className="App">
      <HashRouter>
        <Suspense fallback={<CircularProgress color="primary" />}>
          <ToastContainer />
          {/* <Route
              path="*"
              name="Main"
              element={
                currentUser?.loggedIn === true ? <DefaultLayout /> : token ? <CircularProgress color='primary' /> : <Login />
              }
            /> */}

          {
            currentUser?.loggedIn === true
              ?
              <DefaultLayout />
              : token ?
                <CircularProgress color='primary' /> :
                <>
                  <Routes>
                    <Route
                      path="/CompleteSetup"
                      name="CompleteSetup"
                      element={
                        <PlansFormPage />
                      }
                    />
                    <Route
                      path="/ClientSignup"
                      name="ClientSignup"
                      element={
                        <ClientSignup />
                      }
                    />
                    <Route
                      path="/LawyerSignup"
                      name="LawyerSignup"
                      element={
                        <LawyerSignup />
                      }
                    />
                    <Route
                      path="/*"
                      name="Login"
                      element={
                        <Login />
                      }
                    />
                  </Routes>
                </>
          }
          {/* </Routes> */}
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
