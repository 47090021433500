import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add'
import handelErrors from 'src/components/helpers/handelErrors';
import CustomButton from 'src/components/Custom/CustomButton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomToast from 'src/components/Custom/CustomToast';
import { CustomInput } from 'src/components/Custom/CustomInputForms';
import CustomNewDialog from 'src/components/Custom/CustomNewDialog';

const CreateClient = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    let { id } = useParams();

    const [formData, setFormData] = useState({
        id: -1,
        company_id: id,
        email: '',
        first_name: '',
        last_name: '',
    });
    const resetForm = () => {
        setFormData({
            id: -1,
            company_id: id,
            email: '',
            first_name: '',
            last_name: '',
        });
    }


    // const getFormData = () => {
    //     axios({
    //         method: 'get',
    //         url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyClients/getById-${props.id}`,
    //     })
    //         .then(response => {
    //             setFormData({
    //                 id: props.id,
    //                 email: response.data.email,
    //                 company_id: response.data.company_id,
    //             });
    //         })
    //         .catch(error => {
    //             handelErrors(error);
    //         });
    // };

    useEffect(() => {
        if (props.open) {
            // if (props.id > 0) {
            //     getFormData();
            // } else {
            resetForm();
            // }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        });
    }
    const saveForm = () => {
        setSaveIsLoading(true)
        if (formData.id > 0) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyClients/updateClient`,
                data: formData,
            })
                .then((response) => {
                    CustomToast({ type: 'success', message: 'Success' });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        } else {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/companyClients/createClient`,
                data: formData,
            })
                .then((response) => {
                    CustomToast({ type: 'success', message: 'Success' });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        }
    }

    return (
        <CustomNewDialog
            maxWidth={'xs'}
            // fullWidth
            open={props.open}
            onClose={props.onClose}>
            <DialogTitle >
                <div className='fs-2' style={{ color: '#650E3D' }}>{props.id > 0 ? t('Update') : t('Create')} {t('Client')}</div>
            </DialogTitle>
            <DialogContent >
                <Grid container spacing={"1"}>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'first_name'}
                            value={formData.first_name}
                            onChange={handleInputChange}
                            placeholder={t('first_name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'last_name'}
                            value={formData.last_name}
                            onChange={handleInputChange}
                            placeholder={t('last_name')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'email'}
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder={t('email')}
                            withBorder={false}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"} marginBottom={'2%'}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </CustomNewDialog>
    )
}
CreateClient.propTypes = {
    open: PropTypes.any.isRequired,
    id: PropTypes.any,
    onClose: PropTypes.func.isRequired,
};
export default CreateClient