import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomCheckbox, CustomDateTimePicker, CustomInput, SingleDate, SingleDateTime } from 'src/components/Custom/CustomInputForms';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from 'src/components/Custom/CustomButton';
import handelErrors from 'src/components/helpers/handelErrors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomToast from 'src/components/Custom/CustomToast';


const AddReminder = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        creation_source_id: props.id,
        creation_source: 'tasks',
        datetime: new Date(),
        note:''
    });
    const resetForm = () => {
        setFormData({
            creation_source_id: props.id,
            creation_source: 'tasks',
            datetime: new Date(),
            note:''
        });
    }
    
    const getFormData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Reminders/get`,
            params:{
                creation_source: 'tasks',
                creation_source_id: props.id,
            }
        })
            .then(response => {
                const data = response.data;
                if(data.length > 0){
                    console.log(data[0].note);
                    setFormData({
                        creation_source_id: props.id,
                        note:data[0].note,
                        creation_source: 'tasks',
                        datetime: data[0].datetime ? new Date(data[0].datetime) : null,
                    });
                }else{
                    resetForm();
                }
            })
            .catch(error => {
                handelErrors(error);
            });
    };

    useEffect(() => {
        if(props.open){
            if(props.id > 0){
                getFormData();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.creation_source_id > 0) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/Reminders/add`,
                data: formData,
            })
                .then(response => {
                    CustomToast({type:'success',message : t('updated_mssg')});
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch(error => {
                    setSaveIsLoading(false);
                    handelErrors(error);
                });
        }else{
            setSaveIsLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-4' style={{ color: '#650E3D' }}>{t('Add')} {t('Reminder')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <CustomDateTimePicker
                                id="datetime"
                                label={t('Date')}
                                value={formData.datetime}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'note'}
                            value={formData.note}
                            onChange={handleInputChange}
                            placeholder={t('Reminder')+' '+t('Note')}
                            withBorder={false}
                            multiline={true}
                            minRows={4}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"50px"}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default AddReminder