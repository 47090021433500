import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material';
import ClientsLogo from '../../images/contact_svg.svg';
import EditSVG from 'src/images/edit_svg.svg'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CustomMessage = props => {
    const firebase_id = localStorage.getItem('google_uid');
    const { t, i18n } = useTranslation();
    const ref = useRef();

    useEffect(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [props.data]);

    return (
        <Grid  container style={{ marginTop: '10px' }} 
            direction={
                i18n.language =="ar" ? 
                props.data.user_id == firebase_id ?'row' : 'row-reverse'
                :
                props.data.user_id == firebase_id ?'row-reverse' : 'row'
            }
        >
            <Grid item xs={1}>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={ClientsLogo} />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{
                    backgroundColor: props.data.user_id == firebase_id ? 'rgba(102, 2, 60, 0.03)':'white' 
                    , paddingLeft: '10px'
                    , paddingRight: '10px', paddingBottom: '10px',borderRadius:'5px'
                }}
                    ref={ref}
                    className='d-flex flex-column'>
                    <div className='d-flex flex-column'>
                        <div style={{ fontSize: '12px', color: '#650E3D' }}>
                            {props.data.user_name}
                        </div>
                        <div style={{ fontSize: '12px', color: '#650E3D', fontWeight: '500', lineHeight: '10px' }}>
                            {/* {moment(props.data.date.seconds).format('YYY-MM-DD')} */}
                            {moment(props.data.date.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    </div>
                    <div style={{marginTop:'10px',lineHeight:'18px',fontSize:'14px'}}>
                        {props.data.message}
                    </div>
                </div>
            </Grid>
        </Grid>
    )

}
export default CustomMessage