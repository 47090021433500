import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";


const CustomNewDialog = (props) => {
    return (
        <Dialog
            maxWidth={props.maxWidth ? props.maxWidth : 'md'}
            fullWidth
            open={props.open}
            onClose={props.onClose}
            scroll={props.scroll}
        >
            {props.children}
        </Dialog>
    )
}
export default CustomNewDialog