import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import handelErrors from 'src/components/helpers/handelErrors';
import CustomButton from 'src/components/Custom/CustomButton';
import CustomToast from 'src/components/Custom/CustomToast';
import { CustomInput } from 'src/components/Custom/CustomInputForms';

const RenameFolder = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        id: -1,
        name: "",
    });
    const resetForm = () => {
        setFormData({
            id: -1,
            name: "",
        });
    }

    useEffect(() => {
        if (props.open) {
            if (props.selectedData.id > 0) {
                setFormData({
                    id: props.selectedData.id,
                    name: props.selectedData.name,
                });
            }
            else{
                resetForm();
            }
        }
    }, [props.open]);

    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }

    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/masterData/files/renameFolder`,
                data: formData,
            })
                .then((response) => {
                    CustomToast({ type: 'success', message: t('Success') });
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false)
                    handelErrors(error)
                });
        }
    }

    return (
        <Dialog
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className='fs-2' style={{ color: '#650E3D' }}>{t('Rename')} {t('Folder')}</div>
            </DialogTitle>
            <DialogContent style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                <Grid container spacing={"2"}>
                    <Grid item xs={12}>
                        <CustomInput
                            id={'name'}
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder={t('name')}
                            withBorder={true}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={"10px"}>
                        <CustomButton variant="contained" title={t('Save')} onClick={saveForm} isLoading={saveIsLoading} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
RenameFolder.propTypes = {
    open: PropTypes.any.isRequired,
    id: PropTypes.any,
    onClose: PropTypes.func.isRequired,
};
export default RenameFolder