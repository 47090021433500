import React, { useState } from 'react'
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomTabs from '../../components/Custom/CustomTabs';
import Expenses from './Expenses/Expenses';
import FinancialSummaryReport from './Reports/FirmReports/FinancialSummary/FinancialSummaryReport';
import Invoices from './Invoices/Invoices';
import Payments from './Payments/Payments';

const FirmFinancial = () => {
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [tabSelected, setTabSelected] = useState(0);
    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
    }
    const presetPages = JSON.parse(localStorage.getItem("preset_pages"));
    const targetPage = presetPages.find(e => e.page_code === 'financials')
    const targetPageExpenses = presetPages.find(e => e.page_code === 'firm_expenses')
    const targetPagePayments = presetPages.find(e => e.page_code === 'firm_payments')
    const targetPageInvoices = presetPages.find(e => e.page_code === 'firm_invoices')
    const targetPageSummary = presetPages.find(e => e.page_code === 'firm_financials_summary')
    return (
        <Grid container>
            {
                targetPage?.can_view === 1 && (targetPageExpenses?.can_view === 1 ||
                    targetPagePayments?.can_view === 1 ||
                    targetPageInvoices?.can_view === 1 ||
                    targetPageSummary?.can_view === 1
                ) ?
                    <Grid item xs={12}>
                        <CustomTabs
                            tabs={[
                                {
                                    key: 0, label: t('Expenses'), display: targetPageExpenses?.can_view === 1 ? true : false
                                },
                                {
                                    key: 1, label: t('Invoices'), display: targetPagePayments?.can_view === 1 ? true : false
                                },
                                {
                                    key: 2, label: t('Payments'), display: targetPageInvoices?.can_view === 1 ? true : false
                                },
                                {
                                    key: 3, label: t('Summary'), display: targetPageSummary?.can_view === 1 ? true : false
                                },
                            ]} tabValue={tabSelected} onTabChange={onTabChange} />
                        {
                            tabSelected === 0
                                ?
                                <Expenses />
                                : tabSelected === 1 ?
                                    <Invoices />
                                    : tabSelected === 2 ?
                                        <Payments />
                                        : tabSelected === 3 ?
                                            <FinancialSummaryReport />
                                            : null
                        }
                    </Grid>
                    :
                    null
            }

        </Grid>
    )

}
export default FirmFinancial