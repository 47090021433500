import React, { useEffect, Suspense } from 'react'
import AppContent from '../AppContent'
import { useDispatch, useSelector } from 'react-redux'
import WaitingPage from './WaitingPage'
import PlansPage from './PlansPage'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import PlansFormPage from './PlansFormPage'

const DefaultLayout = () => {
    const navigate = useNavigate();

    const currentUser = useSelector((state) => state.currentUser)
    console.log(currentUser.user.type);
    console.log("type");
    console.log(currentUser);
    if (currentUser.user.type === "lawyer") {
        console.log("inside default layout");
        if (currentUser.user.current_companies.length > 0) {
        console.log("inside companies");

            let current_companies = currentUser.user.current_companies;
            let id = current_companies[0].id;
            const targetElement = current_companies.find(e => e.id === id)
            localStorage.setItem('vat', targetElement.vat);
            // navigate(`/${id}`);
            localStorage.setItem("permittedCompanies", JSON.stringify(current_companies));
        } else {
            localStorage.setItem("permittedCompanies", []);
        }
    }
    return (
        <div>
            {
                currentUser.user.type === "lawyer" ?
                    currentUser.user.current_companies.length == 0
                        ?
                        <PlansFormPage />
                        :
                        <AppContent />
                    :
                    <AppContent />
            }
        </div>
    )
}

export default DefaultLayout
