import React, { useEffect, useState } from 'react'
import { Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomTabs from '../../components/Custom/CustomTabs';
import Expenses from './Expenses/Expenses';
import Invoices from './Invoices/Invoices';
import Payments from './Payments/Payments';

const Financial = () => {
    let { id, caseID } = useParams();
    const { t, i18n } = useTranslation();
    const [tabSelected, setTabSelected] = useState(0);
    const onTabChange = (e, newValue) => {
        setTabSelected(newValue);
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <CustomTabs
                    tabs={[
                        {
                            key: 0, label: t('Expenses'),display:true
                        },
                        {
                            key: 1, label: t('Invoices'),display:true
                        },
                        {
                            key: 2, label: t('Payments'),display:true
                        },
                    ]} tabValue={tabSelected} onTabChange={onTabChange} />
                    {
                                    tabSelected === 0
                                        ?
                                        <Expenses />
                                        : tabSelected === 1 ?
                                            <Invoices />
                                            : tabSelected === 2 ?
                                                <Payments />
                                                : null
                                }
            </Grid>
        </Grid>
    )

}
export default Financial