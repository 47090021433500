import React from 'react';
import Login from './views/login/login';
import MileStones from './views/MileStones/MileStones';
import UserSettings from './views/Settings/UserSettings/UserSettings';
import ClientHome from './views/ClientHome/ClientHome';
import Home from './views/Home/Home';
import CompanyClients from './views/CompanyClients/CompanyClients';
import Dashboard from './views/Dashboard/Dashboard';
import Tasks from './views/Tasks/Tasks';
import Folders from './views/Folders/Folders';
import Chats from './views/Chats/Chats';
import Employees from './views/Employees/Employees';
import FirmFinancial from './views/Financial/FirmFinancial';
import Archives from './views/Archives/Archives';
import Cases from './views/Cases/Cases';
import EmployeePage from './views/Employees/EmployeePage';

// const ClientSignup = React.lazy(() => import('./views/ClientSignup/ClientSignup'));
// const LawyerSignup = React.lazy(() => import('./views/LawyerSignup/LawyerSignup'));
// const Login = React.lazy(() => import('./views/login/login'));
// const Home = React.lazy(() => import('./views/Home/Home'));
// const ClientHome = React.lazy(() => import('./views/ClientHome/ClientHome'));
// const MileStones = React.lazy(() => import('./views/MileStones/MileStones'));
// const UserSettings = React.lazy(() => import('./views/Settings/UserSettings/UserSettings'));
// const CompanyClients = React.lazy(() => import('./views/CompanyClients/CompanyClients'));
// const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));

const routes = [
    { path: '/Login', exact: true, name: 'Login', component: <Login /> },
    // { path: '/ClientSignup', exact: true, name: 'Client Signup', component: ClientSignup },
    // { path: '/LawyerSignup', exact: true, name: 'Lawyer Signup', component: LawyerSignup },
    // { path: '/:id', exact: true, name: 'Home', component: Home },
    { path: '/:id', exact: true, name: 'Home',code:'home', component: <Home><Dashboard /></Home> },
    { path: '/:id/Landing/Clients', exact: true, name: 'Firm Clients',code:'company_clients', component: <Home><CompanyClients /></Home> },
    { path: '/:id/Landing/Tasks', exact: true, name: 'Tasks', code:'tasks',component: <Home><Tasks /></Home> },
    { path: '/:id/Landing/Files', exact: true, name: 'Files', code:'cases',component: <Home><Folders /></Home> },
    { path: '/:id/Landing/Chats', exact: true, name: 'Chats', code:'chats',component: <Home><Chats /></Home> },
    { path: '/:id/Landing/Team', exact: true, name: 'Team', code:'company_employees',component: <Home><EmployeePage /></Home> },
    { path: '/:id/Landing/Financials', exact: true, name: 'Financials',code:'financials', component: <Home><FirmFinancial /></Home> },
    { path: '/:id/Landing/Archives', exact: true, name: 'Archives',code:'archives', component: <Home><Archives /></Home> },
    { path: '/:id/Landing/Cases', exact: true, name: 'Cases',code:'cases', component: <Home><Cases /></Home> },
    { path: '/:id/Case/:caseID', exact: true, name: 'Case',code:'cases', component: <MileStones /> },
    { path: '/:id/Profile', exact: true, name: 'Profile',code:'profile', component: <UserSettings /> },
    { path: '/CHome', exact: true, name: 'CHome',code:'CHome', component: <ClientHome /> },

];

export default routes;
