import { Dialog, DialogContent } from "@mui/material";
import React from "react";


const CustomSignup = (props)=>{
    return(
        <div style={{
            height:'160px',
            width:'190px',
            backgroundColor:'#F4F4F4',
            borderRadius:'16px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            cursor:'pointer'
        }} onClick={props.onClick}>
            <div>
                <img src={props.src} />
            </div>
            <div>
                <div style={{color:'#650E3D',fontSize:'24px'}}>{props.title}</div>
            </div>
        </div>
    )
}
export default CustomSignup