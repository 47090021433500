import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import ClientsLogo from '../../images/contact_svg.svg';

const CustomChat = props => {
    return (
        <div style={{
            height: '60px'
            , backgroundColor: props.chat.id == props.data.id ? 'rgba(101, 14, 61, 0.17)' :'white'
            , width: '90%'
            , marginLeft: 'auto'
            , marginRight: 'auto'
            , marginTop: '10px'
            , borderRadius: '5px'
            , cursor: 'pointer'
        }} onClick={props.onClick}>
            <div style={{
                width: '88%'
                , marginLeft: 'auto'
                , marginRight: 'auto'
                , display: 'flex'
                , alignItems: 'center'
                , height: '100%'
            }}>
                <div style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={ClientsLogo} width={35} />
                </div>
                <div style={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {props.data ? props.data.name : 'chat'}
                </div>
                {/* <div style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    2
                </div> */}
            </div>
        </div>
    )

}
export default CustomChat