import React from 'react'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { FilterAltSharp } from '@mui/icons-material'
import FilterSVG from 'src/images/filter_svg.svg'

const CustomToolbarFilter = (props) => {
  return (
    <Tooltip title={'Filter Table'}>
      <IconButton onClick={props.filterListener}>
        {/* <FilterAltSharp style={{color:'#650E3D'}}/> */}
        <img src={FilterSVG}/>
      </IconButton>
    </Tooltip>
  )
}
CustomToolbarFilter.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    filterListener: PropTypes.node,
  }).isRequired,
}).isRequired

export default CustomToolbarFilter
